import {
    type Event,
    type KambiEvent,
    type KambiPath,
    type LiveData,
    type MatchClock,
    type KambiMatchClock,
    type Team,
    type EventScore,
    type FetchedKambiData,
    EVENT_SCORE_TYPE_MATCH,
    SCORE_SET_STATUS_DONE,
    SCORE_SET_STATUS_STARTED,
    SCORE_SET_STATUS_PENDING,
    EVENT_SCORE_TYPE_SET,
} from "@atg-sport-shared/sportsbook-types-event";

const intVal = (val: string) => parseInt(val, 10);
const isValidInt = (val: string) => !Number.isNaN(intVal(val));

const getMatchClock = (
    mc: KambiMatchClock | MatchClock | null | undefined,
    path: Array<KambiPath> | undefined,
    now: number,
): MatchClock | null | undefined => {
    if (!mc) return undefined;

    const {minute, second, running} = mc;

    const hasMatchClock = Boolean(
        isValidInt(minute as string) &&
            isValidInt(second as string) &&
            path?.[0]?.termKey !== "cricket",
    );

    if (!hasMatchClock) return undefined;

    const toReturn = {
        ...mc,
        running: Boolean(running),
        seconds: intVal(minute as string) * 60 + intVal(second as string),
        ts: now,
    };

    return toReturn;
};

const getTeamStatistics = (
    team: "home" | "away",
    liveData: LiveData,
): Team | undefined => {
    if (!liveData.statistics || !liveData.statistics.football) return undefined;
    // @ts-ignore : not seeing the check above
    const teamStatistics: Team = liveData.statistics.football[team];

    // Fetch corner stats from liveStatistics since it does not seem entirely reliable in statistics
    if (liveData.liveStatistics) {
        const occurrenceTypeId = `CORNERS_${team.toUpperCase()}`;

        const correctStat =
            liveData.liveStatistics &&
            liveData.liveStatistics.find(
                (stat) => stat.occurrenceTypeId === occurrenceTypeId,
            );

        if (correctStat && correctStat.count) {
            teamStatistics.corners = correctStat.count;
        }
    }
    return teamStatistics;
};

const tranformScore = (
    sport: string | null | undefined,
    liveData?: LiveData,
): EventScore => {
    const score = liveData?.score;
    const sets = liveData?.statistics?.sets;
    const setBasedSets = [];
    sets?.home?.forEach((homeScore, index) => {
        const awayScore = sets?.away && sets?.away[index];
        if (awayScore && homeScore !== -1 && awayScore !== -1) {
            setBasedSets.push({
                status: SCORE_SET_STATUS_DONE,
                home: homeScore.toString(),
                away: awayScore.toString(),
            });
        } else {
            setBasedSets.push({
                status: SCORE_SET_STATUS_PENDING,
                home: "0",
                away: "0",
            });
        }
    });

    setBasedSets.push({
        status: SCORE_SET_STATUS_STARTED,
        home: score?.home || "",
        away: score?.away || "",
    });

    const displayScore =
        sport && sport.toUpperCase() === "TENNIS"
            ? {
                  type: EVENT_SCORE_TYPE_SET,
                  sets: setBasedSets,
                  info: score?.info,
              }
            : {
                  type: EVENT_SCORE_TYPE_MATCH,
                  home: score?.home || "",
                  away: score?.away || "",
                  info: score?.info,
              };

    return displayScore as EventScore;
};

export const parseEvent = ({
    event,
    liveData,
    now,
}: {
    event: Partial<KambiEvent & Event>;
    liveData?: LiveData;
    now: number;
}): Event => {
    const {
        id,
        name,
        homeName,
        awayName,
        sport,
        start,
        path,
        tags,
        nonLiveBoCount,
        liveBoCount,
    } = event;
    let pathIds = path && path.map((p) => p.id);
    if (!pathIds) pathIds = event.pathIds || undefined;

    let startTimestamp = start && new Date(start).getTime();
    if (!startTimestamp) startTimestamp = event.startTimestamp || undefined;

    const matchClockToParse = liveData?.matchClock || event.matchClock || undefined;
    const matchClock = getMatchClock(matchClockToParse, path, now) || event.matchClock;

    const nrOfBetOffers =
        nonLiveBoCount || 0 + (liveBoCount || 0) || 0 + (event.nrOfBetOffers || 0) || 0;

    const sportName = sport || event.sportName;

    const homeStatistics = liveData
        ? getTeamStatistics("home", liveData)
        : event.homeStatistics || undefined;
    const awayStatistics = liveData
        ? getTeamStatistics("away", liveData)
        : event.awayStatistics || undefined;
    const score = (liveData?.score && tranformScore(sportName, liveData)) || event.score;

    return {
        id: id || 0,
        name,
        homeName,
        awayName,
        sportName,
        pathIds,
        startTimestamp,
        matchClock,
        tags,
        score,
        homeStatistics,
        awayStatistics,
        nonLiveBoCount,
        liveBoCount,
        nrOfBetOffers,
    };
};

export const kambiEventsParser = (events: FetchedKambiData[]): Event[] => {
    const now = Date.now();

    return events.reduce((acc, data) => {
        if (!data.event) return acc;
        const {event, liveData} = data;
        return [...acc, parseEvent({event, liveData, now})];
    }, [] as Event[]);
};
