export const SET_SELECTED_FALLBACKEVENT = "SET_SELECTED_FALLBACKEVENT";

export type SetSelectedFallbackEventAction = {
    type: typeof SET_SELECTED_FALLBACKEVENT;
    eventId: number;
};

export const setSelectedFallbackEvent = (
    eventId: number,
): SetSelectedFallbackEventAction => ({
    type: SET_SELECTED_FALLBACKEVENT,
    eventId,
});
