import type {Reducer} from "redux";
import {combineReducers} from "redux";
import type {FetchReducer, FetchState} from "@atg-shared/fetch-types";
import {createFetchReducer} from "@atg-shared/fetch-redux";
import type {ShopShare} from "@atg-shop-shared/types/shopShare";
import * as ShareSportCouponActions from "./shareSportCouponActions";
import {type ShareSportCouponError} from "./shareCouponTypes";

export type ShareSportCouponRestoreSyncState = string | null;
export type ShareSportCouponErrorState = ShareSportCouponError | null;

const restoreCouponSync: Reducer<ShareSportCouponRestoreSyncState> = (
    state = null,
    action,
) => {
    switch (action.type) {
        case ShareSportCouponActions.SHARE_SPORT_RESTORE_COUPON_SYNC:
            return action.payload.restoreCouponSync;
        default:
            return state;
    }
};

const couponError: Reducer<ShareSportCouponErrorState> = (state = null, action) => {
    switch (action.type) {
        case ShareSportCouponActions.SHARE_SPORT_COUPON_ERROR:
            return action.payload.couponError;
        default:
            return state;
    }
};

export type ShareSportCouponSharesState = {items: ShopShare[]};

const INITIAL_COUPONS_STATE: ShareSportCouponSharesState = {items: []};

const coupons: FetchReducer<
    ShareSportCouponSharesState,
    ShareSportCouponActions.ShareSportCouponFetchAllSharesAction
> = createFetchReducer(
    ShareSportCouponActions.SHARE_SPORT_COUPON_FETCH_ALL_SHARES_REQUEST,
    ShareSportCouponActions.SHARE_SPORT_COUPON_FETCH_ALL_SHARES_RESPONSE,
    null,
    (
        state: ShareSportCouponSharesState,
        action: ShareSportCouponActions.ShareSportCouponAction,
    ) => {
        switch (action.type) {
            case ShareSportCouponActions.SHARE_SPORT_COUPON_FETCH_ALL_SHARES_RESPONSE:
                return action.error ? state : {items: action.payload.shares};
            default:
                return state;
        }
    },
    INITIAL_COUPONS_STATE,
);

export type ShareSportCouponReducer = {
    coupons: FetchState<ShareSportCouponSharesState>;
    restoreCouponSync: ShareSportCouponRestoreSyncState;
    couponError: ShareSportCouponErrorState;
};

export type GlobalShareSportCouponState = {
    shareSportCoupon: ShareSportCouponReducer;
};

export default combineReducers<ShareSportCouponReducer>({
    coupons,
    restoreCouponSync,
    couponError,
});
