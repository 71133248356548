import dayjs from "dayjs";
import {orderBy} from "lodash";
import type {Match} from "@atg-sport-shared/big9-types/matchTypes";
import {MatchStatus} from "@atg-sport-shared/big9-types/matchTypes";
import type {
    Offering,
    OfferingWithMatchRefs,
    OfferingWithMatches,
    OfferingsById,
} from "@atg-sport-shared/big9-types/offeringTypes";
import {OfferingStatus} from "@atg-sport-shared/big9-types/offeringTypes";
import {
    liveOfferingStatuses,
    preLiveOfferingStatuses,
} from "@atg-sport-shared/big9-types";

export const getSportradarMatchId = ({sportradar}: {sportradar: Match["sportradar"]}) => {
    const eventIdParts = sportradar.eventId?.split(":") ?? [];

    return eventIdParts.length === 3 ? eventIdParts[2] ?? null : null;
};

export const getIsOfferingUpcomingOrDecided = (
    offering: OfferingWithMatchRefs | null,
): boolean =>
    offering?.status === OfferingStatus.UPCOMING ||
    offering?.status === OfferingStatus.DECIDED;

export const getIsSellOpenClosedOrStarted = (
    offering: OfferingWithMatchRefs | null,
): boolean =>
    offering?.status === OfferingStatus.SELL_OPEN ||
    offering?.status === OfferingStatus.SELL_CLOSED ||
    offering?.status === OfferingStatus.UNDER_REVIEW;

export const getIsOfferingLive = (offering: OfferingWithMatchRefs | null): boolean =>
    offering?.status === OfferingStatus.SELL_CLOSED;

export const getHasOfferingPassed = (
    offering: Offering | OfferingWithMatchRefs | null,
): boolean =>
    !getIsSellOpenClosedOrStarted(offering) &&
    offering?.status !== OfferingStatus.UPCOMING;

export const getIsSellOpen = (offering: OfferingWithMatchRefs | null): boolean =>
    offering?.status === OfferingStatus.SELL_OPEN;

export const getIsOfferingCanceled = (offering: OfferingWithMatchRefs | null): boolean =>
    offering?.status === OfferingStatus.CANCELED;

export const getShowGradingResults = (offering: OfferingWithMatchRefs | null): boolean =>
    offering?.status === OfferingStatus.FINALIZED;

export const getIsFinalizedOrCanceledOffering = (
    offering: OfferingWithMatchRefs | null,
): boolean =>
    offering?.status === OfferingStatus.FINALIZED ||
    offering?.status === OfferingStatus.CANCELED;

export const getReceiptHasStartingHeaderLabel = (
    offering: OfferingWithMatchRefs | null,
): boolean =>
    offering?.status === OfferingStatus.UPCOMING ||
    offering?.status === OfferingStatus.SELL_OPEN;

export const getOfferingCancelledMatchNumbers = (
    offering: OfferingWithMatches,
): string[] =>
    offering.matches.reduce((acc, match) => {
        if (match.status === MatchStatus.CANCELED) {
            acc.push(match.number);
        }
        return acc;
    }, [] as string[]);

export const shouldShowCurrentWinnings = (offering: OfferingWithMatches): boolean =>
    offering?.status === OfferingStatus.SELL_CLOSED ||
    offering?.status === OfferingStatus.DECIDED ||
    offering?.status === OfferingStatus.GRADING ||
    offering?.status === OfferingStatus.GRADED;

export const getClosedOfferingString = (
    offeringStatus: OfferingStatus,
    offeringStart: number | null,
): string => {
    if (offeringStatus === OfferingStatus.CANCELED)
        return "Omgången ställdes in och insatserna återbetalades";
    if (offeringStatus === OfferingStatus.UNDER_REVIEW)
        return "Spelet är tillfälligt stängt";
    if (offeringStart) {
        const date = dayjs(offeringStart);
        return `Big 9 öppnar för köp ${date.format("dddd")}ar kl ${date.format("HH:mm")}`;
    }
    return "Big 9 öppnar för köp onsdagar kl 10:00";
};

export const showJackpot = (offering: OfferingWithMatchRefs | null) => {
    if (!offering?.pool?.expectedJackpot) return false;

    const isLiveOffering = liveOfferingStatuses.includes(offering?.status);
    const isPreLiveOffering = preLiveOfferingStatuses.includes(offering?.status);

    return isLiveOffering || isPreLiveOffering;
};

export const findClosestSellOpenOffering = (offerings: OfferingWithMatchRefs[]) => {
    const sellOpenOfferings = offerings.filter((offering) =>
        preLiveOfferingStatuses.includes(offering.status),
    );

    if (sellOpenOfferings.length > 0) {
        return sellOpenOfferings[sellOpenOfferings.length - 1];
    }

    return null;
};

export const findLatestSellClosedOffering = (offerings: OfferingWithMatchRefs[]) => {
    const latestSellClosedOffering = offerings.find((offering) =>
        liveOfferingStatuses.includes(offering.status),
    );
    return latestSellClosedOffering ?? null;
};

export const findLatestFinalizedOffering = (offerings: OfferingWithMatchRefs[]) => {
    const latestFinalizedOffering = offerings.find(
        (offering) => offering.status === OfferingStatus.FINALIZED,
    );
    return latestFinalizedOffering ?? null;
};

export const sortOfferings = (allIds?: string[], byId?: OfferingsById) => {
    if (!allIds || !byId) return null;

    return orderBy(
        allIds.map((id) => byId[id]),
        ["startTime", "serialNumber"],
        ["desc", "desc"],
    ).filter((offering) => offering.status !== OfferingStatus.UPCOMING);
};
