import "@atg-shared/micro-frontend/setPublicPath";
import log, {serverLogPlugin} from "@atg-shared/log";
import {configureStoreForAuthentication} from "@atg-shared/auth";
import {initialiseLanguage} from "@atg-shared/language";
import {configureEmotionCache} from "atg-emotion-cache";
import {store as sportsbookStore} from "./store";

serverLogPlugin(log, "sportsbook");
log.setLevel("warn");
configureStoreForAuthentication(sportsbookStore);
configureEmotionCache("sportsbook");

initialiseLanguage(async (locale) => {
    const {messages} = await import(
        /* webpackChunkName: "i18n" */ `../locales/${locale}/messages.po`
    );
    return messages;
});

export const App = () => import(/* webpackChunkName: "App" */ "./components/App/App");

export const SideMenu = () =>
    import(/* webpackChunkName: "SideMenu" */ "./components/SideMenu/SideMenu");
