import {
    type KambiWapiAction,
    SET_IS_KAMBI_LOADING,
    REMOVE_KAMBI,
    SET_IS_KAMBI_DISPOSING,
    KAMBI_WAPI_READY,
} from "../actions/kambi.client.actions";

export type KamibClientState = {
    isLoading: boolean;
    isDisposing: boolean;
    isWapiReady: boolean;
};

export const initialState: KamibClientState = {
    isLoading: false,
    isDisposing: false,
    isWapiReady: false,
};

export const kambiClientReducer = (
    state: KamibClientState = initialState,
    action: KambiWapiAction,
) => {
    switch (action.type) {
        case SET_IS_KAMBI_LOADING:
            return {...state, isLoading: action.isLoading};

        case REMOVE_KAMBI:
            return {...state, isDisposing: true};

        case SET_IS_KAMBI_DISPOSING:
            return {...state, isDisposing: action.isDisposing};

        case KAMBI_WAPI_READY:
            return {...state, isWapiReady: action.isWapiReady};

        default:
            return state;
    }
};
