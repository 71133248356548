import type {Coupon} from "@atg-sport-shared/big9-types/couponTypes";
import type {FetchAction} from "@atg-shared/fetch-types";
import {call, FETCH} from "@atg-shared/fetch-redux";
import type {ShopShare} from "@atg-shop-shared/types/shopShare";
import * as ShareSportCouponApi from "./shareSportCouponApi";
import {type ShareSportCouponError as ShareSportCouponErrorType} from "./shareCouponTypes";

export const SHARE_SPORT_COUPON_CREATE_REQUEST = "shop/SHARE_SPORT_COUPON_CREATE_REQUEST";
export const SHARE_SPORT_COUPON_CREATE_RESPONSE =
    "shop/SHARE_SPORT_COUPON_CREATE_RESPONSE";

export const SHARE_SPORT_COUPON_FETCH_ALL_SHARES_REQUEST =
    "shop/SHARE_SPORT_COUPON_FETCH_ALL_SHARES_REQUEST" as const;
export const SHARE_SPORT_COUPON_FETCH_ALL_SHARES_RESPONSE =
    "shop/SHARE_SPORT_COUPON_FETCH_ALL_SHARES_RESPONSE" as const;

export const SHARE_SPORT_COUPON_DELETE_REQUEST =
    "shop/SHARE_SPORT_COUPON_DELETE_REQUEST" as const;
export const SHARE_SPORT_COUPON_DELETE_RESPONSE =
    "shop/SHARE_SPORT_COUPON_DELETE_RESPONSE" as const;

export const SHARE_SPORT_COUPON_FETCH_OFFERING =
    "shop/SHARE_SPORT_COUPON_FETCH_OFFERING" as const;

export const SHARE_SPORT_RESTORE_COUPON_SYNC = "shop/SHARE_SPORT_RESTORE_COUPON_SYNC";

export const SHARE_SPORT_COUPON_ERROR = "shop/SHARE_SPORT_COUPON_ERROR";

export type ShareSportCouponError = {
    type: typeof SHARE_SPORT_COUPON_ERROR;
    payload: {
        couponError: ShareSportCouponErrorType | null;
    };
};

export type ShareSportRestoreCouponSync = {
    type: typeof SHARE_SPORT_RESTORE_COUPON_SYNC;
    payload: {
        restoreCouponSync: string | null;
    };
};

export type ShareSportCouponFetchOfferingAction = {
    type: typeof SHARE_SPORT_COUPON_FETCH_OFFERING;
    payload: string;
};

export type ShareSportCouponCreateAction = FetchAction<
    typeof SHARE_SPORT_COUPON_CREATE_REQUEST,
    typeof SHARE_SPORT_COUPON_CREATE_RESPONSE,
    ShareSportCouponApi.ShareSportCouponResponse
>;

export type ShareSportCouponDeleteAction = FetchAction<
    typeof SHARE_SPORT_COUPON_DELETE_REQUEST,
    typeof SHARE_SPORT_COUPON_DELETE_RESPONSE,
    ShareSportCouponApi.ShareSportCouponResponse
>;

export type ShareSportCouponFetchAllSharesAction = FetchAction<
    typeof SHARE_SPORT_COUPON_FETCH_ALL_SHARES_REQUEST,
    typeof SHARE_SPORT_COUPON_FETCH_ALL_SHARES_RESPONSE,
    ShareSportCouponApi.AllCouponsResponse,
    {shares: ShopShare[]}
>;

export type ShareSportCouponAction =
    | ShareSportCouponCreateAction
    | ShareSportCouponFetchAllSharesAction
    | ShareSportCouponDeleteAction
    | ShareSportCouponFetchOfferingAction
    | ShareSportRestoreCouponSync
    | ShareSportCouponError;

export const createCoupon = (coupon: Coupon): ShareSportCouponCreateAction => ({
    type: FETCH,
    payload: {
        requestAction: SHARE_SPORT_COUPON_CREATE_REQUEST,
        receiveAction: SHARE_SPORT_COUPON_CREATE_RESPONSE,
        callApi: call(ShareSportCouponApi.createCoupon, coupon),
    },
});

export const setRestoreCouponSync = (
    restoreCouponSync: string | null,
): ShareSportRestoreCouponSync => ({
    type: SHARE_SPORT_RESTORE_COUPON_SYNC,
    payload: {
        restoreCouponSync,
    },
});

export const setCouponError = (
    couponError: ShareSportCouponErrorType | null,
): ShareSportCouponError => ({
    type: SHARE_SPORT_COUPON_ERROR,
    payload: {
        couponError,
    },
});

export const deleteCoupon = (couponId: string): ShareSportCouponDeleteAction => ({
    type: FETCH,
    payload: {
        requestAction: SHARE_SPORT_COUPON_DELETE_REQUEST,
        receiveAction: SHARE_SPORT_COUPON_DELETE_RESPONSE,
        callApi: call(ShareSportCouponApi.deleteCoupon, couponId),
    },
});

export const fetchAllShares = (
    shopId: string,
    gameId: string,
): ShareSportCouponFetchAllSharesAction => ({
    type: FETCH,
    payload: {
        requestAction: SHARE_SPORT_COUPON_FETCH_ALL_SHARES_REQUEST,
        receiveAction: SHARE_SPORT_COUPON_FETCH_ALL_SHARES_RESPONSE,
        callApi: call(ShareSportCouponApi.fetchAllShares, {shopId, gameId}),
    },
});

export const fetchSportOffering = (
    gameId: string,
): ShareSportCouponFetchOfferingAction => ({
    type: SHARE_SPORT_COUPON_FETCH_OFFERING,
    payload: gameId,
});
