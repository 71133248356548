import type {FetchReducer} from "@atg-shared/fetch-types";
import {createFetchReducer} from "@atg-shared/fetch-redux";
import type {CrossPromosState} from "@atg-sport-shared/sportsbook-types-root";
import {
    type FetchCrossPromotionsAction,
    REQUEST_CROSS_PROMOTIONS,
    RECEIVE_CROSS_PROMOTIONS,
} from "./cross.promotions.actions";

const initialState: CrossPromosState = {
    items: [],
};

export const crossPromotionsReducer: FetchReducer<
    CrossPromosState,
    FetchCrossPromotionsAction
> = createFetchReducer(
    REQUEST_CROSS_PROMOTIONS,
    RECEIVE_CROSS_PROMOTIONS,
    "",
    (state: CrossPromosState, action: FetchCrossPromotionsAction) =>
        action.type === RECEIVE_CROSS_PROMOTIONS && !action.error
            ? {
                  items: action.payload,
              }
            : state,
    initialState,
);
