import type {BetFailureReason} from "@atg-sport-shared/sportsbook-types-kambi";

export const KAMBI_BET_FAILURE_ERROR = "sport/KAMBI_BET_FAILURE_ERROR";

export type KambiBetFailureErrorAction = {
    type: typeof KAMBI_BET_FAILURE_ERROR;
    payload: {reason: BetFailureReason};
};

export const kambiBetFailureError = ({reason}: {reason: BetFailureReason}) => ({
    type: KAMBI_BET_FAILURE_ERROR,
    payload: {reason},
});

export const KAMBI_RESET_ERRORS = "sport/KAMBI_RESET_ERRORS";

export type KambiResetErrorsAction = {
    type: typeof KAMBI_RESET_ERRORS;
};

export const kambiResetErrors = () => ({
    type: KAMBI_RESET_ERRORS,
});
