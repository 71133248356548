import type {KambiSocketResponseState} from "@atg-sport-shared/sportsbook-types-event";
import type {
    ReceiveSocketResponseAction,
    TakingSocketResponesAction,
} from "../actions/kambi.socket.response.actions";
import {
    RECEIVE_SOCKET_REPONSE,
    TAKING_SOCKET_RESPONSES,
} from "../actions/kambi.socket.response.actions";

const initialState: KambiSocketResponseState = {
    responses: [],
};

export const kambiSocketResponseReducer = (
    state: KambiSocketResponseState = initialState,
    action: ReceiveSocketResponseAction | TakingSocketResponesAction,
) => {
    switch (action.type) {
        case RECEIVE_SOCKET_REPONSE:
            return {responses: [...state.responses, ...action.payload]};
        case TAKING_SOCKET_RESPONSES:
            return initialState;
        default:
            return state;
    }
};
