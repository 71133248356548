export const BET_OFFER_TYPE_MATCH = "BET_OFFER_TYPE_MATCH";
export const BET_OFFER_TYPE_YES_NO = "BET_OFFER_TYPE_YES_NO";
export const BET_OFFER_TYPE_POSITION = "BET_OFFER_TYPE_POSITION";
export const BET_OFFER_TYPE_WINNER = "BET_OFFER_TYPE_WINNER";
export const BET_OFFER_TYPE_UNKNOWN = "BET_OFFER_TYPE_UNKNOWN";

export type BetOfferType =
    | typeof BET_OFFER_TYPE_MATCH
    | typeof BET_OFFER_TYPE_YES_NO
    | typeof BET_OFFER_TYPE_WINNER
    | typeof BET_OFFER_TYPE_POSITION
    | typeof BET_OFFER_TYPE_UNKNOWN;

type FormattedOdds = {
    [key: string]: string;
};

export type Outcome = {
    id: number;
    betOfferId?: number;
    label: string;
    odds: number;
    formattedOdds: FormattedOdds;
    suspended: boolean;
    participant?: string;
    status?: string;
};

export type FormattedOutcome = {
    selected: boolean;
    oddsString: string;
} & Outcome;

export type BetOffer = {
    id: string | number;
    eventId: number;
    label: string;
    type: BetOfferType;
    suspended: boolean;
    outcomes: Array<Outcome>;
    tags?: Array<string>;
};

export type BetOfferState = {
    [key: string]: BetOffer;
};

export type FormattedBetOffer = {
    id: number | string;
    eventId: number;
    label: string;
    type: BetOfferType;
    suspended: boolean;
    outcomes: Array<FormattedOutcome>;
    numOfOffers?: number;
};

export enum OutcomeTypes {
    OT_ONE = "OT_ONE",
    OT_CROSS = "OT_CROSS",
    OT_TWO = "OT_TWO",
}
