import type {SelectedFallbackEventsState} from "@atg-sport-shared/sportsbook-types-fallback-hero";
import {
    type SetSelectedFallbackEventAction,
    SET_SELECTED_FALLBACKEVENT,
} from "./fallback.hero.actions";

const initialState: SelectedFallbackEventsState = {
    eventId: 0,
};

export const fallbackHeroReducer = (
    state: SelectedFallbackEventsState = initialState,
    action: SetSelectedFallbackEventAction,
) => (action.type === SET_SELECTED_FALLBACKEVENT ? {eventId: action.eventId} : state);
