import {type FetchAction, LOADING_STATUS} from "@atg-shared/fetch-types";
import {FETCH, call, fetchSelectors} from "@atg-shared/fetch-redux";
import * as api from "@atg-sport-shared/sportsbook-utils-cms-api";
import type {HeroesResponse} from "@atg-sport-shared/sportsbook-types-hero";

export const REQUEST_HEROES = "sport/REQUEST_HEROES";
export const RECEIVE_HEROES = "sport/RECEIVE_HEROES";

export type FetchHeroesAction = FetchAction<
    typeof REQUEST_HEROES,
    typeof RECEIVE_HEROES,
    HeroesResponse
>;

export const fetchHeroes = (
    {
        heroesContentUrl,
    }: {
        heroesContentUrl: string | null | undefined;
    } = {heroesContentUrl: null},
): FetchHeroesAction => ({
    type: FETCH,
    payload: {
        requestAction: REQUEST_HEROES,
        receiveAction: RECEIVE_HEROES,
        shouldCallApi: (state) =>
            fetchSelectors.getLoadingStatus(state.sportsbook.hero).status !==
            LOADING_STATUS.OK,
        callApi: call(api.fetchHeroes, {heroesContentUrl}),
    },
});

export type HeroActions = FetchHeroesAction;
