import {values} from "lodash";
import type {GroupMap} from "@atg-sport-shared/sportsbook-types-group";
import type {KambiEvent} from "@atg-sport-shared/sportsbook-types-event";
import {DEFAULT_CUSTOMER_SETTINGS} from "@atg-sport-shared/sportsbook-utils-kambi";
import {getPathGroups} from "./basegroup.parser";

type KambiEventsItem = {
    event: KambiEvent;
};

const merge = (groups: GroupMap, pathGroups: GroupMap) =>
    values(pathGroups).reduce(
        (acc, group) => ({
            ...acc,
            [group.id]: {
                ...acc[group.id],
                ...group,
            },
        }),
        groups,
    );

export const kambiPathParser = (events: Array<KambiEventsItem>): GroupMap => {
    const now = Date.now();
    // We create a starting soon timestamp with the configured startingSoonInterval from
    // our customer settings so we can compare event start time and set a startingSoon
    // boolean on every event.
    const soon = now + 60 * 60 * 1000 * DEFAULT_CUSTOMER_SETTINGS.startingSoonInterval;

    return events.reduce((acc, eventData) => {
        const {path, start} = eventData.event;
        const startTimestamp = start ? new Date(start).getTime() : undefined;
        const hasStartingSoon = Boolean(startTimestamp && startTimestamp < soon);

        let params: Partial<{
            hasEvents: boolean;
            hasStartingSoon: boolean;
            hasLive: boolean;
            hasPrematch: boolean;
        }> = {hasEvents: true, hasStartingSoon};

        if (startTimestamp) {
            params =
                startTimestamp < now
                    ? {...params, hasLive: true}
                    : {...params, hasPrematch: true};
        }

        return merge(acc, getPathGroups(path, params));
    }, {});
};
