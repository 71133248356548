export const toggleOutcomeValue = <T>(
    outcomes: ReadonlyArray<T>,
    value: T,
): ReadonlyArray<T> =>
    outcomes.includes(value) ? outcomes.filter((v) => v !== value) : [...outcomes, value];

export const generateOutcomeId = ({
    couponId,
    matchId,
}: {
    couponId: string;
    matchId: string | null;
}) => `${couponId}-${matchId}`;
