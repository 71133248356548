import type {FetchReducer, FetchState} from "@atg-shared/fetch-types";
import {createFetchReducer} from "@atg-shared/fetch-redux";
import type {NonRegionalSportsType} from "@atg-sport-shared/sportsbook-types-non-regional-sport";
import {
    type GetNonRegionalSports,
    GET_NON_REGIONAL_SPORTS,
    SET_NON_REGIONAL_SPORTS,
} from "./non.regional.sport.actions";

type State = {
    data: NonRegionalSportsType;
};

export type NonRegionalSportsState = FetchState<State>;

const initialState: State = {
    data: [],
};

export const nonRegionalSportsReducer: FetchReducer<State, GetNonRegionalSports> =
    createFetchReducer(
        GET_NON_REGIONAL_SPORTS,
        SET_NON_REGIONAL_SPORTS,
        "",
        (state: State, action) =>
            action.type === SET_NON_REGIONAL_SPORTS && !action.error
                ? {
                      data: action.payload,
                  }
                : state,
        initialState,
    );
