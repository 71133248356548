import type {PrePacksResponse} from "./prePacks.types";

export const FETCH_PRE_PACKS = "FETCH_PRE_PACKS";
export const FETCH_PRE_PACKS_SUCCESS = "FETCH_PRE_PACKS_SUCCESS";
export const FETCH_PRE_PACKS_ERROR = "FETCH_PRE_PACKS_ERROR";
export const STOP_POLLING_PRE_PACKS = "STOP_POLLING_PRE_PACKS";

export const FETCH_PRE_PACKS_BY_LEAGUE_ID = "FETCH_PRE_PACKS_BY_LEAGUE_ID";
export const FETCH_SPECIAL_BY_EVENT_ID = "FETCH_SPECIAL_BY_EVENT_ID";

export type FetchPrePacksAction = {
    type: typeof FETCH_PRE_PACKS;
};

export type StopPollingPrePacksAction = {
    type: typeof STOP_POLLING_PRE_PACKS;
};

export type FetchPrePacksSuccessAction = {
    type: typeof FETCH_PRE_PACKS_SUCCESS;
    payload: PrePacksResponse;
};

export type FetchPrePacksErrorAction = {
    type: typeof FETCH_PRE_PACKS_ERROR;
    payload: string;
};

export type FetchPrePacksByLeagueIdAction = {
    type: typeof FETCH_PRE_PACKS_BY_LEAGUE_ID;
    payload: string;
};

export type FetchSpecialByEventIdAction = {
    type: typeof FETCH_SPECIAL_BY_EVENT_ID;
    payload: string;
};

export type PrePacksActions =
    | FetchPrePacksAction
    | FetchPrePacksSuccessAction
    | FetchPrePacksErrorAction
    | FetchPrePacksByLeagueIdAction
    | FetchSpecialByEventIdAction;

// // actions for setting PRE_PACKS in store
// export const fetchPrePacksSuccess = (payload: PrePacksResponse) => ({
//     type: FETCH_PRE_PACKS_SUCCESS,
//     payload,
// });

// export const fetchPrePacksError = (payload: unknown) => ({
//     type: FETCH_PRE_PACKS_ERROR,
//     payload,
// });

// actions for fetching PRE_PACKS
export const fetchPrePacks = (): FetchPrePacksAction => ({
    type: FETCH_PRE_PACKS,
});

export const stopPollingPrePacks = (): StopPollingPrePacksAction => ({
    type: STOP_POLLING_PRE_PACKS,
});

export const fetchPrePacksByLeagueId = (
    leagueId: string,
): FetchPrePacksByLeagueIdAction => ({
    type: FETCH_PRE_PACKS_BY_LEAGUE_ID,
    payload: leagueId,
});

export const fetchPrePackByEventId = (eventId: string): FetchSpecialByEventIdAction => ({
    type: FETCH_SPECIAL_BY_EVENT_ID,
    payload: eventId,
});
