import type {KambiEventInfo} from "@atg-sport-shared/sportsbook-types-event";
import type {
    LOGIN_FAILED,
    NOTIFICATION_TYPE,
    ODDS_FORMAT_AMERICAN,
    ODDS_FORMAT_DECIMAL,
    ODDS_FORMAT_FRACTIONAL,
    WAPI,
    WOULD_EXCEED_WEEKLY_LOSS_LIMIT,
} from "./kambi.constants";

export type KambiClientState = {
    isLoading: boolean;
    isDisposing: boolean;
    isWapiReady: boolean;
};

export type BetFailureReason = typeof WOULD_EXCEED_WEEKLY_LOSS_LIMIT | null;

export type KambiOutcome = {
    id: number;
    betofferId: number;
    eventId: number | null;
    odds?: number;
    decimalOdds: string | null;
    description?: Array<string>;
    label: string;
    event: string;
};

export type KambiBetslip = {
    type: string;
    combinedOdds: {decimalOdds: string} | null | undefined;
    outcomes: Array<KambiOutcome>;
};

export type KambiBetslipMessage = {
    type: typeof WAPI.BETSLIP_OUTCOMES;
    data: KambiBetslip;
};

export type KambiEventInfoMessage = {
    type: typeof WAPI.EVENT_INFO;
    data: KambiEventInfo;
};

export type KambiPlaceBetStateMessage = {
    type: typeof WAPI.PLACE_BET_STATE;
    data: {currentState: string};
};

type KambiHeartbeatMessage = {
    type: typeof WAPI.HEARTBEAT;
};

export type NotificationLogin = {
    name: typeof NOTIFICATION_TYPE.LOGIN;
    data: {
        status: boolean;
    };
};

type NotificationLogout = {
    name: typeof NOTIFICATION_TYPE.LOGOUT;
};

type Bet = {
    eachWay: boolean;
    stake: number;
};

export type NotificationCustomizedFeedback = {
    name: typeof NOTIFICATION_TYPE.CUSTOMIZED_FEEDBACK;
    data: {
        feedback: {
            type: string;
        };
        customerErrors: Array<string>;
        coupon: {
            bets?: Array<Bet> | null | undefined;
            stakes?: Array<number> | null | undefined;
        };
    };
};

type NotificationRequestLogin = {
    name: typeof NOTIFICATION_TYPE.REQUEST_LOGIN;
    data: void | null | undefined;
};

type NotificationPageRendered = {
    name: typeof NOTIFICATION_TYPE.PAGE_RENDERED;
    data: void;
};

type GeneralError = {
    arguments: Array<string>;
    id: string;
    type: string;
    values: [];
};

type CouponRowType = {
    index: number;
    odds: number;
    outcomeId: number;
    type: string;
};

type CouponTrackingDataType = {
    hasTeaser?: boolean;
    isBetBuilderCombination?: boolean;
    isMultiBuilder?: boolean;
    isPrePackCombination?: boolean;
    reward?: {type: any; value: any};
    selectedOutcomes: Array<KambiOutcome>;
    stakeInputOption?: any;
};

export type RequestCouponType = {
    allowOddsChange: "YES" | "NO";
    bets: Array<Bet>;
    channel: string;
    couponRows: Array<CouponRowType>;
    requestId: string;
    trackingData: CouponTrackingDataType;
};

export type NotificationPlacingBetFailed = {
    name: typeof NOTIFICATION_TYPE.PLACING_BET_FAILED;
    data: {
        response: {
            betErrors: unknown[];
            couponRowErrors: unknown[];
            generalErrors?: Array<GeneralError>;
            pbaOptions: unknown[];
            requestCoupon?: RequestCouponType;
            status: "FAIL";
        };
    };
};

export type NotificationPlacedbet = {
    name: typeof NOTIFICATION_TYPE.PLACED_BET;
    data: number;
};

export type KambiNotificationEvent =
    | NotificationLogin
    | NotificationLogout
    | NotificationCustomizedFeedback
    | NotificationRequestLogin
    | NotificationPageRendered
    | NotificationPlacingBetFailed
    | NotificationPlacedbet;

export type KambiNotificationMessage = {
    type: typeof WAPI.NOTIFICATION;
    event: KambiNotificationEvent;
};

export type KambiShowEventStatisticsMessage = {
    type: typeof WAPI.EVENT_STATS;
    eventId: number;
};

export type KambiPathNotFound = {
    type: typeof WAPI.NOT_FOUND;
    url: string;
};

export type OddsFormat =
    | typeof ODDS_FORMAT_DECIMAL
    | typeof ODDS_FORMAT_FRACTIONAL
    | typeof ODDS_FORMAT_AMERICAN;

export type KambiOddsFormatChangeMessage = {
    type: typeof WAPI.CLIENT_ODDS_FORMAT;
    data: OddsFormat;
};

export type KambiBetslipMaximizedMessage = {
    type: typeof WAPI.BETSLIP_MAXIMIZED_CHANGE;
    data: {maximized: boolean};
};

type KambiLogoutReasons =
    | typeof WAPI.LOGOUT_REASONS.LOGIN_FAILED
    | typeof WAPI.LOGOUT_REASONS.LOGOUT_REQUESTED
    | typeof WAPI.LOGOUT_REASONS.SESSION_TIMED_OUT;

export type KambiUserSessionChange = {
    type: typeof WAPI.USER_SESSION_CHANGE;
    data: {isLoggedIn: boolean; logoutReason?: KambiLogoutReasons};
};

export type KambiPageInfo = {
    type: typeof WAPI.PAGE_INFO;
    data: {pageTrackingPath: string; pageParam: string[]; leaguePaths: string[]};
};

export type KambiMessage =
    | KambiBetslipMessage
    | KambiBetslipMaximizedMessage
    | KambiPlaceBetStateMessage
    | KambiHeartbeatMessage
    | KambiEventInfoMessage
    | KambiNotificationMessage
    | KambiOddsFormatChangeMessage
    | KambiShowEventStatisticsMessage
    | KambiPathNotFound
    | KambiUserSessionChange
    | KambiPageInfo;

export type LoginFailedReason =
    | typeof LOGIN_FAILED.KAMBI_ERROR
    | typeof LOGIN_FAILED.ATG_ERROR;

export type KambiState = {
    isLoggedIn: boolean;
    loginFailedReason: LoginFailedReason | null | undefined;
    kambiLoaded: boolean;
    isHighlightsLoaded: boolean;
    oddsFormat: OddsFormat;
    isLoginPending: boolean;
    betCost: number;
};

export type BetFailure = "BetFailure";

export enum ErrorTypes {
    BetFailure,
}

export type KambiErrorsState = {
    hasError: boolean;
    errorTypes: Array<ErrorTypes>;
    betFailureReason?: BetFailureReason;
};
