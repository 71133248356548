import type * as React from "react";
import root from "window-or-global";
import type {Dayjs} from "dayjs";
import dayjs from "dayjs";
import {orderBy, upperFirst, values, sortBy, get} from "lodash";
import type {CombinedGroup} from "@atg-sport-shared/sportsbook-types-group";
import {
    RACING_SPORTS,
    SPORT_CLOUDFRONT,
} from "@atg-sport-shared/sportsbook-utils-constants";
import * as device from "@atg/utils/device";
import Features, {sportUseProdCloudfront} from "@atg-shared/client-features";

export const loadScript = (src: string) =>
    new Promise((resolve, reject) => {
        const scriptEl = root.document.createElement("script");

        scriptEl.src = src;
        scriptEl.async = true;
        scriptEl.onload = resolve;
        scriptEl.onerror = reject;

        root.document.head.appendChild(scriptEl);
    });

export const getComponentDisplayName = (WrappedComponent: React.ComponentType): string =>
    WrappedComponent.displayName || WrappedComponent.name || "Component";

type FindGroup = {
    parent: CombinedGroup | null;
    grandparent: CombinedGroup | null;
};

export const findGroup = (
    groups: {[key: string]: CombinedGroup},
    comparison: CombinedGroup,
): FindGroup =>
    values(groups).reduce(
        (
            foundGroup: FindGroup,
            group: CombinedGroup,
            i: number,
            groupsArr: Array<CombinedGroup>,
        ) => {
            if (group.id === comparison.parentId) {
                const newFoundGroup = {...foundGroup};
                newFoundGroup.parent = group;

                if (newFoundGroup.parent.parentId === null) return newFoundGroup;

                const grandparent = groupsArr.find(
                    (g: CombinedGroup) =>
                        newFoundGroup.parent && g.id === newFoundGroup.parent.parentId,
                );

                newFoundGroup.grandparent = grandparent || null;
                return newFoundGroup;
            }
            return foundGroup;
        },
        {
            parent: null,
            grandparent: null,
        },
    );

type Scheduled = {
    startTime: number;
    endTime?: number;
};

const defaultPredicate = () => true;

export const getScheduledItem = (
    items: Array<Scheduled>,
    now: number,
    predicate: (item: Scheduled) => boolean = defaultPredicate,
): number | undefined => {
    const currentItem = orderBy(
        items
            .filter(predicate)
            .filter(
                (item) => item.startTime <= now && (!item.endTime || item.endTime > now),
            ),
        ["startTime"],
        ["desc"],
    )[0];

    const currentIndex = items.indexOf(currentItem);

    return currentIndex !== -1 ? currentIndex : undefined;
};

const KAMBI_DATE_FORMAT = "YYYYMMDD[T]HHmmssZZ";

export const getKambiFormattedDate = (date: dayjs.Dayjs): string =>
    date.format(KAMBI_DATE_FORMAT);

export const convertDateToDay = (date: Date | string): string => {
    const tomorrow = dayjs().add(1, "day").endOf("day");

    if (dayjs(date).isBefore(tomorrow)) return "Imorgon";
    return dayjs(date).format("dddd");
};

export const convertDateToDayOfMonth = (date: Date | string): string =>
    dayjs(date).format("D MMM");

export const sortDatesAscending = (a: Date | string, b: Date | string): number => {
    if (dayjs(a).isBefore(dayjs(b))) return -1;
    if (dayjs(a).isAfter(dayjs(b))) return 1;
    return 0;
};

export const checkSportFilter = (termKey: string): string =>
    RACING_SPORTS.indexOf(termKey) === -1 ? "filter" : "racing";

const getDayDiff = (
    startTimestamp: Dayjs | number | string | undefined,
    endTimestamp: Dayjs | number | string | undefined,
): number => {
    const startDate = dayjs(startTimestamp).startOf("day");
    const endDate = dayjs(endTimestamp).startOf("day");
    return endDate.diff(startDate, "days");
};

export const getStartTimePrefix = (
    timestamp: Dayjs | number | string | undefined,
    nowTimestamp: Dayjs | number | undefined | string = Date.now(),
): string => {
    const diff = getDayDiff(nowTimestamp, timestamp);
    const startDate = dayjs(timestamp);

    const startHour = parseInt(startDate.format("H"), 10);
    const nowHour = parseInt(dayjs(nowTimestamp).format("H"), 10);
    const hourDiff = startHour - nowHour;

    if (diff === 0) {
        if (startHour >= 3 && startHour < 18) {
            return "Idag";
        }
        if (startHour >= 18 && startHour < 23) {
            return "Ikväll";
        }
        return hourDiff <= 3 ? "Inatt" : "Idag";
    }
    if (diff === 1) {
        return nowHour >= 18 && startHour <= 3 ? "Inatt" : "Imorgon";
    }
    if (diff <= 7) {
        return upperFirst(startDate.format("dddd"));
    }

    return `${startDate.date()} ${startDate.format("MMMM")}`;
};

export const isEventLive = (startTimestamp?: number | null | void) =>
    Boolean(startTimestamp && startTimestamp < Date.now());

export const isSportsbookPath = (pathname: string): boolean =>
    pathname.startsWith("/sport") ||
    pathname.startsWith("/konto/mina-spel/sport") || // remove when verticals are using new urls
    pathname.startsWith("/konto/mina-spel/sportbok");

export const isEventSoon = (startTimestamp?: number) => {
    const now = Date.now();
    return Boolean(
        startTimestamp &&
            startTimestamp > now &&
            startTimestamp < now + 24 * 60 * 60 * 1000,
    );
};

export const addExtraBottomMargin = (): boolean => {
    if (device.isiPhone()) {
        const ratio = root.devicePixelRatio || 1;
        const screen = {
            width: root.screen.width * ratio,
            height: root.screen.height * ratio,
        };
        return screen.width >= 1125 && screen.height >= 2436;
    }
    return false;
};

/**
 * This function will take the hightlights and construct a string to fit kambi's filter url.
 * The string will have a structure with the sports (comma separated) followed by arrays of regions (comma separated)
 * which will be followed by an array of arrays with leagues (comma separated)
 *
 * e.g. "sport1,sport2/[sport1region1,sport1region2],[sport2region1]/[[sport1region1league1,sport1region1league2],[sport1region2league1]],[[all]]"
 */
export const combinePopularSportsUrl = (highlights: Array<CombinedGroup>): string => {
    const popular = sortBy(highlights, "leagueSortOrder")
        .map((group) => (group.path ? group.path.split("/") : []))
        .reduce((allGroups, group) => {
            const sport = group[1];
            const region = group[2];
            const league = group[3];
            const newAllGroups = {...allGroups};
            if (sport in allGroups) {
                if (region in newAllGroups[sport]) {
                    newAllGroups[sport][region].push(league || "all");
                } else {
                    newAllGroups[sport][region] = [league || "all"];
                }
            } else {
                newAllGroups[sport] = {};
                newAllGroups[sport][region] = [league || "all"];
            }
            return newAllGroups;
        }, {} as {[key: string]: {[key: string]: string[]}});

    return Object.keys(popular).reduce((urlString, sport) => {
        const stringArray = urlString.split("/");

        const regionUrl = Object.keys(popular[sport]).reduce((regionString, region) => {
            if (regionString) {
                return `${regionString},${region}`;
            }
            return `${region}`;
        }, "");

        const leaguesUrl = regionUrl.split(",").reduce((leagues, region) => {
            if (leagues) {
                return `${leagues},[${popular[sport][region].join(",")}]`;
            }
            return `[${popular[sport][region].join(",")}]`;
        }, "");

        if (urlString) {
            return `${stringArray[0]},${sport}/${stringArray[1]},[${regionUrl}]/${stringArray[2]},[${leaguesUrl}]`;
        }
        return `${sport}/[${regionUrl}]/[${leaguesUrl}]`;
    }, "");
};

/**
 * This function will take an array of groups (mainly used with popular leagues), and check if any are highlighted and have live events
 */
export const checkPopularLiveGroups = (groups: Array<CombinedGroup>): boolean => {
    const popularLive = groups.find((group) => group.isHighlighted && group.hasLive);
    return popularLive && popularLive.hasLive ? popularLive.hasLive : false;
};

export const sportCloudFrontUrl: string = Features.isEnabled(sportUseProdCloudfront)
    ? SPORT_CLOUDFRONT.PROD_URL
    : get(root, "clientConfig.sport.cloudfrontUrl");

export const formatOdds = (odds: number) => {
    const numOfDigitsBeforeDecimal = odds?.toString().split(".")[0].length;

    switch (numOfDigitsBeforeDecimal) {
        case 1:
        case 2:
            return odds?.toFixed(2);
        case 3:
            return odds?.toFixed(1);

        default:
            return odds?.toFixed(0);
    }
};

export const getTennisTotalSetsStandings = (homeSets: number[], awaySets: number[]) => {
    const standings = {
        home: 0,
        away: 0,
    };

    homeSets.forEach((homeGames, index) => {
        const awayGames = awaySets[index];

        // Non-played sets have value -1
        if (awayGames === -1) return;

        // No one has won the set
        if (homeGames < 6 && awayGames < 6) return;

        // Need to win with 2 when it's not a tiebreak
        const isTiebreak = homeGames >= 6 && awayGames >= 6;
        if (!isTiebreak && Math.abs(homeGames - awayGames) < 2) return;

        // Home won the set
        if (homeGames > awayGames) {
            standings.home += 1;
            return;
        }

        // Away won the set
        if (awayGames > homeGames) {
            standings.away += 1;
        }
    });

    return standings;
};

export const getTennisCurrentSet = (homeSets: number[], awaySets: number[]) => {
    // Non-played sets have value -1
    const getIndexOfNonPlayedSet = homeSets.indexOf(-1);

    // It's the last set
    if (getIndexOfNonPlayedSet === -1) {
        return {
            home: homeSets[homeSets.length - 1],
            away: awaySets[awaySets.length - 1],
        };
    }

    // Match have started
    if (getIndexOfNonPlayedSet > 0) {
        return {
            home: homeSets[getIndexOfNonPlayedSet - 1],
            away: awaySets[getIndexOfNonPlayedSet - 1],
        };
    }

    // First set have not started
    return {
        home: 0,
        away: 0,
    };
};
