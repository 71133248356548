import type {Match, MatchPushEventType} from "@atg-sport-shared/big9-types/matchTypes";

export const MATCH_UPDATE = "big9/MATCH_UPDATE";

type UpdatePayload = {
    type: MatchPushEventType;
    match: Match;
};

export type MatchUpdateAction = {
    type: typeof MATCH_UPDATE;
    payload: UpdatePayload;
};

export const matchUpdate = ({type, match}: UpdatePayload): MatchUpdateAction => ({
    type: MATCH_UPDATE,
    payload: {type, match},
});
