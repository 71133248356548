import type {
    KambiGroupResponse,
    ParsedPrematchGroups,
    PrematchGroup,
} from "@atg-sport-shared/sportsbook-types-group";
import {checkSportFilter} from "@atg-sport-shared/sportsbook-utils-helpers";
import {getCommonFields} from "./basegroup.parser";

type KambiGroups = Array<KambiGroupResponse>;
type TreeParams = {
    groupTree: KambiGroups;
    parentId: null | number;
    depth: number;
};

export const kambiGroupsParser = (groups: KambiGroups): ParsedPrematchGroups => {
    let data: ParsedPrematchGroups = {};

    const parseGroupTree = ({groupTree, parentId, depth}: TreeParams): void => {
        groupTree.forEach((groupNode) => {
            const group: {
                [key: string]: any;
            } = {
                ...getCommonFields(groupNode),
                hasEvents: Boolean(groupNode.eventCount),
                leaf: !groupNode.groups,
                hasPrematch: true,
                parentId,
                depth,
            };

            if (groupNode.sortOrder) {
                group.sortOrder = parseInt(groupNode.sortOrder, 10);
            }

            data = {
                ...data,
                [group.id]: group,
            };

            if (Array.isArray(groupNode.groups)) {
                parseGroupTree({
                    groupTree: groupNode.groups,
                    parentId: groupNode.id,
                    depth: depth + 1,
                });
            }
        });
    };

    parseGroupTree({groupTree: groups, parentId: null, depth: 1});

    const generatePath = (group: PrematchGroup, tmpPath = ""): string => {
        const groupPath = tmpPath ? `${group.termKey}/${tmpPath}` : group.termKey;

        return group.parentId && data[group.parentId]
            ? generatePath(data[group.parentId], groupPath)
            : `${checkSportFilter(group.termKey)}/${groupPath}`;
    };

    Object.keys(data).forEach((key) => {
        data[key].path = generatePath(data[key]);
    });

    return data;
};
