export const BEGIN_KAMBI_LOAD = "BEGIN_KAMBI_LOAD";
export const SET_IS_KAMBI_LOADING = "SET_IS_KAMBI_LOADING";
export const SET_IS_KAMBI_DISPOSING = "SET_IS_KAMBI_DISPOSING";
export const REMOVE_KAMBI = "REMOVE_KAMBI";
export const KAMBI_WAPI_READY = "KAMBI_WAPI_READY";
export const KAMBI_ROUTE_START = "KAMBI_ROUTE_START";
export const KAMBI_ROUTE_STOP = "KAMBI_ROUTE_STOP";

type BeginKambiLoadAction = {
    type: typeof BEGIN_KAMBI_LOAD;
};

type SetIsKambiLoadingAction = {
    type: typeof SET_IS_KAMBI_LOADING;
    isLoading: boolean;
};

type SetIsKambiDisposingAction = {
    type: typeof SET_IS_KAMBI_DISPOSING;
    isDisposing: boolean;
};

type RemoveKambiAction = {
    type: typeof REMOVE_KAMBI;
};

type SetKambiWapiReadyAction = {
    type: typeof KAMBI_WAPI_READY;
    isWapiReady: boolean;
};

export type KambiWapiAction =
    | BeginKambiLoadAction
    | SetIsKambiLoadingAction
    | SetIsKambiDisposingAction
    | RemoveKambiAction
    | SetKambiWapiReadyAction;

export const beginKambiLoad = (): BeginKambiLoadAction => ({type: BEGIN_KAMBI_LOAD});

export const setIsKambiLoading = (isLoading: boolean): SetIsKambiLoadingAction => ({
    type: SET_IS_KAMBI_LOADING,
    isLoading,
});

export const setIsKambiDisposing = (isDisposing: boolean): SetIsKambiDisposingAction => ({
    type: SET_IS_KAMBI_DISPOSING,
    isDisposing,
});

export const removeKambi = (): RemoveKambiAction => ({type: REMOVE_KAMBI});

export const setKambiWapiReady = (isWapiReady: boolean): SetKambiWapiReadyAction => ({
    type: KAMBI_WAPI_READY,
    isWapiReady,
});
