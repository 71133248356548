import type {FetchReducer} from "@atg-shared/fetch-types";
import {createFetchReducer} from "@atg-shared/fetch-redux";
import type {StaticContentType} from "@atg-sport-shared/sportsbook-types-static-content";
import {
    type GetStaticLinksAction,
    GET_STATIC_CONTENT,
    SET_STATIC_CONTENT,
} from "./static.content.actions";

type State = {
    data: StaticContentType;
};

const initialState: State = {
    data: [],
};

export const staticContentReducer: FetchReducer<State, GetStaticLinksAction> =
    createFetchReducer(
        GET_STATIC_CONTENT,
        SET_STATIC_CONTENT,
        "",
        (state: State, action) => {
            const {type, payload} = action;

            switch (type) {
                case SET_STATIC_CONTENT: {
                    return {
                        ...state,
                        data: payload,
                    };
                }
                default:
                    return state;
            }
        },
        initialState,
    );
