import type {Selector} from "reselect";
import {createSelector} from "reselect";
import {values, sortBy} from "lodash";
import {isApp} from "@atg-shared/system";
import type {GlobalBig9State} from "@atg-sport-shared/big9-types";
import type {
    CouponSettings,
    OngoingMatchSettings,
    SettingsState,
    Setting,
} from "@atg-sport-shared/big9-types/settingsTypes";
import {OfferingStatus} from "@atg-sport-shared/big9-types/offeringTypes";
import {getOfferingStatus} from "@atg-sport-shared/big9-data-access-offering";
import {setCouponSettings, setOngoingMatchSettings} from "./settings.actions";

export const getSettings = (state: GlobalBig9State): SettingsState =>
    state.sportsbook.big9.settings;

export const getCostSelections = (): number[] =>
    isApp
        ? [5000, 10000, 15000, 20000, 30000, 50000]
        : [5000, 10000, 15000, 20000, 30000, 40000, 50000, 100000];

export const getCouponSettings: Selector<GlobalBig9State, CouponSettings> =
    createSelector(getSettings, (settings) => settings.coupon);

export const getSortedCouponSettings: Selector<GlobalBig9State, Setting[]> =
    createSelector(getCouponSettings, (couponSettings) => {
        const settingsValues = values(couponSettings);

        return sortBy(settingsValues, "order");
    });

export const getOngoingMatchSettings: Selector<GlobalBig9State, OngoingMatchSettings> =
    createSelector(getSettings, (settings) => settings.ongoingMatch);

export const getSortedOngoingMatchSettings: Selector<GlobalBig9State, Setting[]> =
    createSelector(getOngoingMatchSettings, (ongoingMatchSettings) => {
        const settingsValues = values(ongoingMatchSettings);

        return sortBy(settingsValues, "order");
    });

const getOfferingStatusSelector = (offeringId: string) => (state: GlobalBig9State) =>
    getOfferingStatus(offeringId)(state);

const getSortedOngoingMatchSettingsSelector = (state: GlobalBig9State) =>
    getSortedOngoingMatchSettings(state);

const getSortedCouponSettingsSelector = (state: GlobalBig9State) =>
    getSortedCouponSettings(state);

export const getPreOrOngoingSettings = (offeringId: string) =>
    createSelector(
        getOfferingStatusSelector(offeringId),
        getSortedOngoingMatchSettingsSelector,
        getSortedCouponSettingsSelector,
        (status: OfferingStatus | null, ongoingMatchSettings, couponSettings) => {
            const isLive =
                status === OfferingStatus.SELL_CLOSED ||
                status === OfferingStatus.DECIDED;
            const settingsData = isLive ? ongoingMatchSettings : couponSettings;
            const setSettingsAction = isLive
                ? setOngoingMatchSettings
                : setCouponSettings;

            return {settingsData, setSettingsAction};
        },
    );

export const getSortingSettings = createSelector(
    getSettings,
    (settings) => settings.sortingSettings,
);

export const getSelectedSortingOption = createSelector(
    getSortingSettings,
    (sortingSettings) => sortingSettings.selectedSortId,
);
