import type {StatisticsState} from "@atg-sport-shared/sportsbook-types-statistics";
import {
    type SportStatisticsAction,
    OPEN_SPORT_STATISTICS,
    CLOSE_SPORT_STATISTICS,
} from "./statistics.actions";

const initialState: StatisticsState = {eventId: null, origin: null};

export const statisticsReducer = (
    state: StatisticsState = initialState,
    action: SportStatisticsAction,
) => {
    switch (action.type) {
        case OPEN_SPORT_STATISTICS:
            return action.payload;
        case CLOSE_SPORT_STATISTICS:
            return initialState;
        default:
            return state;
    }
};
