export enum BetSelection {
    Home = "home",
    Tie = "tie",
    Away = "away",
    Over = "over",
    Under = "under",
    Wildcard = "wildcard",
}

export enum AdvancedBetSelection {
    HomeOver = "homeOver",
    HomeUnder = "homeUnder",
    TieOver = "tieOver",
    TieUnder = "tieUnder",
    AwayOver = "awayOver",
    AwayUnder = "awayUnder",
    Wildcard = "wildcard",
}

export enum BetOutcome {
    HomeOver = "o1",
    HomeUnder = "u1",
    TieOver = "oX",
    TieUnder = "uX",
    AwayOver = "o2",
    AwayUnder = "u2",
}

export type BetSelectionWithoutWildcard = Exclude<BetSelection, "wildcard">;
export type AdvancedBetSelectionWithoutWildcard = Exclude<
    AdvancedBetSelection,
    "wildcard"
>;

export type BetSelectionPlaceholder = "-";
export const betSelectionPlaceholder: BetSelectionPlaceholder = "-";

export const betSelectionMap: {[BS in BetSelectionWithoutWildcard]: string} & {
    [BPH in BetSelectionPlaceholder]: string;
} = {
    [BetSelection.Home]: "1",
    [BetSelection.Tie]: "X",
    [BetSelection.Away]: "2",
    [BetSelection.Over]: "Ö",
    [BetSelection.Under]: "U",
    [betSelectionPlaceholder]: "-",
};

export const advancedBetSelectionMap: {
    [ABS in AdvancedBetSelectionWithoutWildcard]: string;
} & {
    [BPH in BetSelectionPlaceholder]: string;
} = {
    [AdvancedBetSelection.HomeOver]: "1Ö",
    [AdvancedBetSelection.HomeUnder]: "1U",
    [AdvancedBetSelection.TieOver]: "XÖ",
    [AdvancedBetSelection.TieUnder]: "XU",
    [AdvancedBetSelection.AwayOver]: "2Ö",
    [AdvancedBetSelection.AwayUnder]: "2U",
    [betSelectionPlaceholder]: "-",
};

export const betOutcomeMap: {[BO in BetOutcome]: string} = {
    [BetOutcome.HomeOver]: "1Ö",
    [BetOutcome.HomeUnder]: "1U",
    [BetOutcome.TieOver]: "XÖ",
    [BetOutcome.TieUnder]: "XU",
    [BetOutcome.AwayOver]: "2Ö",
    [BetOutcome.AwayUnder]: "2U",
};

export const betOutcomeToAdvancedSelectionMap: {
    [BO in BetOutcome]: AdvancedBetSelectionWithoutWildcard;
} = {
    [BetOutcome.HomeOver]: AdvancedBetSelection.HomeOver,
    [BetOutcome.HomeUnder]: AdvancedBetSelection.HomeUnder,
    [BetOutcome.TieOver]: AdvancedBetSelection.TieOver,
    [BetOutcome.TieUnder]: AdvancedBetSelection.TieUnder,
    [BetOutcome.AwayOver]: AdvancedBetSelection.AwayOver,
    [BetOutcome.AwayUnder]: AdvancedBetSelection.AwayUnder,
};

export const betOutcomes: BetOutcome[] = [
    BetOutcome.HomeOver,
    BetOutcome.TieOver,
    BetOutcome.AwayOver,
    BetOutcome.HomeUnder,
    BetOutcome.TieUnder,
    BetOutcome.AwayUnder,
];

export const matchSelections: [BetSelection.Home, BetSelection.Tie, BetSelection.Away] = [
    BetSelection.Home,
    BetSelection.Tie,
    BetSelection.Away,
];

export const overUnderSelections: [BetSelection.Over, BetSelection.Under] = [
    BetSelection.Over,
    BetSelection.Under,
];

export const advancedSelectionsWithoutWildcard: AdvancedBetSelectionWithoutWildcard[] = [
    AdvancedBetSelection.HomeOver,
    AdvancedBetSelection.HomeUnder,
    AdvancedBetSelection.TieOver,
    AdvancedBetSelection.TieUnder,
    AdvancedBetSelection.AwayOver,
    AdvancedBetSelection.AwayUnder,
];
export const advancedSelections: AdvancedBetSelection[] = [
    AdvancedBetSelection.HomeOver,
    AdvancedBetSelection.HomeUnder,
    AdvancedBetSelection.TieOver,
    AdvancedBetSelection.TieUnder,
    AdvancedBetSelection.AwayOver,
    AdvancedBetSelection.AwayUnder,
    AdvancedBetSelection.Wildcard,
];

export const betSelectionsWithoutWildcard: BetSelectionWithoutWildcard[] = [
    ...matchSelections,
    ...overUnderSelections,
];

export const betSelections: BetSelection[] = [
    ...betSelectionsWithoutWildcard,
    BetSelection.Wildcard,
];
