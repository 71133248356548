import type {
    OddsFormat,
    LoginFailedReason,
    KambiMessage,
} from "@atg-sport-shared/sportsbook-types-kambi";

export const SB_KAMBI_LOADED = "SB_KAMBI_LOADED";
export const SB_KAMBI_UPDATE_ODDS_FORMAT = "SB_KAMBI_UPDATE_ODDS_FORMAT";
export const SB_SET_KAMBI_AUTH = "SB_SET_KAMBI_AUTH";
export const SB_RESET_KAMBI_AUTH = "SB_RESET_KAMBI_AUTH";
export const SB_SET_KAMBI_LOGIN_FAILED = "SB_SET_KAMBI_LOGIN_FAILED";
export const SB_KAMBI_LOGIN = "SB_KAMBI_LOGIN";
export const SB_LOGIN_PENDING = "SB_LOGIN_PENDING";
export const SB_PLACE_BET_FAILED = "SB_PLACE_BET_FAILED";
export const SB_SET_BET_COST = "SB_SET_BET_COST";
export const SB_KAMBI_MESSAGE = "SB_KAMBI_MESSAGE";
export const SB_FORCE_AUTH_FLOW = "SB_FORCE_AUTH_FLOW";
export const SB_PLACED_BET = "SB_PLACED_BET";

type SetKambiAuthAction = {
    type: typeof SB_SET_KAMBI_AUTH;
    isLoggedIn: boolean;
};

type KambiLoaded = {
    type: typeof SB_KAMBI_LOADED;
    loaded: boolean;
};

export type SetKambiLoginFailedAction = {
    type: typeof SB_SET_KAMBI_LOGIN_FAILED;
    loginFailedReason: LoginFailedReason;
};

type ResetKambiAuthAction = {
    type: typeof SB_RESET_KAMBI_AUTH;
};

type UpdateOddsFormatAction = {
    type: typeof SB_KAMBI_UPDATE_ODDS_FORMAT;
    oddsFormat: OddsFormat;
};

export type SetBetCostAction = {
    type: typeof SB_SET_BET_COST;
    betCost: number;
};

export type KambiLoginAction = {
    type: typeof SB_KAMBI_LOGIN;
    username: string;
    authCode: string;
};

export type KambiSetPendingLogin = {
    type: typeof SB_LOGIN_PENDING;
};

export type SetKambiPlacedBetAction = {
    type: typeof SB_PLACED_BET;
    couponRef: number;
};

export const updateOddsFormat = (oddsFormat: OddsFormat): UpdateOddsFormatAction => ({
    type: SB_KAMBI_UPDATE_ODDS_FORMAT,
    oddsFormat,
});

export const setKambiLoaded = (loaded: boolean): KambiLoaded => ({
    type: SB_KAMBI_LOADED,
    loaded,
});

export const kambiLoginFailed = (
    loginFailedReason: LoginFailedReason,
): SetKambiLoginFailedAction => ({
    type: SB_SET_KAMBI_LOGIN_FAILED,
    loginFailedReason,
});

export const setKambiAuth = (isLoggedIn: boolean): SetKambiAuthAction => ({
    type: SB_SET_KAMBI_AUTH,
    isLoggedIn,
});

export const setBetCost = (betCost: number): SetBetCostAction => ({
    type: SB_SET_BET_COST,
    betCost,
});

export const resetKambiAuth = (): ResetKambiAuthAction => ({
    type: SB_RESET_KAMBI_AUTH,
});

export const kambiLogin = (username: string, authCode: string): KambiLoginAction => ({
    type: SB_KAMBI_LOGIN,
    username,
    authCode,
});

export const kambiSetPendingLogin = (): KambiSetPendingLogin => ({
    type: SB_LOGIN_PENDING,
});

export type KambiActions =
    | KambiLoaded
    | SetKambiLoginFailedAction
    | SetKambiAuthAction
    | ResetKambiAuthAction
    | UpdateOddsFormatAction
    | KambiLoginAction
    | KambiSetPendingLogin
    | SetBetCostAction;

export const forceAuthenticationFlow = () => ({type: SB_FORCE_AUTH_FLOW});

export const placeBetFailed = () => ({type: SB_PLACE_BET_FAILED});

export const PLACE_BET_DENIED = "PLACE_BET_DENIED";

export const placeBetDenied = (reason: string) => ({type: PLACE_BET_DENIED, reason});

export const kambiClientMessage = (message: KambiMessage) => ({
    type: SB_KAMBI_MESSAGE,
    message,
});

export const kambiBetPlaced = (couponRef: number): SetKambiPlacedBetAction => ({
    type: SB_PLACED_BET,
    couponRef,
});
