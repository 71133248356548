import type {FetchAction} from "@atg-shared/fetch-types";
import {FETCH, call} from "@atg-shared/fetch-redux";
import type {GlobalState} from "@atg-sport-shared/sportsbook-types-root";
import type {ShortInfoResponse} from "@atg-sport-shared/sportsbook-types-short-info";
import * as api from "@atg-sport-shared/sportsbook-utils-cms-api";

export const GET_SHORTINFO = "GET_SHORTINFO";
export const SET_SHORTINFO = "SET_SHORTINFO";

export type FetchShortInfoAction = FetchAction<
    typeof GET_SHORTINFO,
    typeof SET_SHORTINFO,
    ShortInfoResponse,
    GlobalState
>;

export const fetchShortInfoAction = (): FetchShortInfoAction => ({
    type: FETCH,
    payload: {
        requestAction: GET_SHORTINFO,
        receiveAction: SET_SHORTINFO,
        callApi: call(api.fetchShortInfo),
    },
});
