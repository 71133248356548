import type {FetchAction} from "@atg-shared/fetch-types";
import {FETCH, call} from "@atg-shared/fetch-redux";
import type {GlobalState} from "@atg-sport-shared/sportsbook-types-root";
import type {StaticContentType} from "@atg-sport-shared/sportsbook-types-static-content";
import * as api from "@atg-sport-shared/sportsbook-utils-cms-api";

export const GET_STATIC_CONTENT = "GET_STATIC_CONTENT";
export const SET_STATIC_CONTENT = "SET_STATIC_CONTENT";

export type GetStaticLinksAction = FetchAction<
    typeof GET_STATIC_CONTENT,
    typeof SET_STATIC_CONTENT,
    StaticContentType,
    GlobalState
>;

export const getStaticContent = (): GetStaticLinksAction => ({
    type: FETCH,
    payload: {
        requestAction: GET_STATIC_CONTENT,
        receiveAction: SET_STATIC_CONTENT,
        callApi: call(api.fetchStaticContent),
    },
});
