import type {
    BaseGroup,
    CombinedGroup,
    GroupMap,
} from "@atg-sport-shared/sportsbook-types-group";
import {checkSportFilter} from "@atg-sport-shared/sportsbook-utils-helpers";

export const getCommonFields = ({
    id,
    name,
    englishName,
    termKey,
}: BaseGroup): BaseGroup => ({
    id,
    name,
    englishName,
    termKey,
});

export const getPathGroups = (
    path: Array<BaseGroup>,
    params?: Partial<CombinedGroup>,
): GroupMap => {
    const lastIndex = path.length - 1;
    const data: GroupMap = {};

    let groupPath = checkSportFilter(path[0].termKey);

    path.forEach((p, index) => {
        groupPath = `${groupPath}/${p.termKey}`;

        data[p.id] = {
            ...getCommonFields(p),
            ...params,
            path: groupPath,
            depth: index + 1,
            parentId: index === 0 ? null : path[index - 1].id,
            leaf: index === lastIndex,
        };
    });

    return data;
};
