import type {SportsbookRoute} from "@atg-sport-shared/sportsbook-types-root";
import {
    type RouteActions,
    SB_SET_ROUTE,
    SB_RESET_ROUTE,
    SB_SET_ROUTE_NOT_FOUND,
    SB_RESET_ROUTE_NOT_FOUND,
} from "./route.actions";

const initialState: SportsbookRoute = {
    raw: "",
    page: "",
    sport: "",
    region: undefined,
    league: undefined,
    participant: undefined,
    eventId: undefined,
    isLiveEvent: undefined,
    isMissingRoute: false,
};

export const routeReducer = (
    state: SportsbookRoute = initialState,
    action: RouteActions,
) => {
    switch (action.type) {
        case SB_SET_ROUTE:
            return {
                ...initialState,
                ...action.payload,
                isMissingRoute: false,
            };
        case SB_RESET_ROUTE:
            return initialState;
        case SB_SET_ROUTE_NOT_FOUND:
            return {
                ...state,
                isMissingRoute: true,
            };
        case SB_RESET_ROUTE_NOT_FOUND:
            return {
                ...state,
                isMissingRoute: false,
            };
        default:
            return state;
    }
};
