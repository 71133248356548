import type {EventId, Origin} from "@atg-sport-shared/sportsbook-types-statistics";

export const OPEN_SPORT_STATISTICS = "OPEN_SPORT_STATISTICS";
export const CLOSE_SPORT_STATISTICS = "CLOSE_SPORT_STATISTICS";

export type SportStatisticsAction = {
    type: typeof OPEN_SPORT_STATISTICS | typeof CLOSE_SPORT_STATISTICS;
    payload?: {
        eventId: EventId;
        origin: Origin;
    };
};

export const openSportStatistics = (
    eventId: EventId,
    origin: Origin,
): SportStatisticsAction => ({
    type: OPEN_SPORT_STATISTICS,
    payload: {eventId, origin},
});

export const closeSportStatistics = (): SportStatisticsAction => ({
    type: CLOSE_SPORT_STATISTICS,
});
