import {combineReducers} from "redux";
import {groupReducer} from "@atg-sport-shared/sportsbook-data-access-group";
import {betslipReducer} from "@atg-sport-shared/sportsbook-data-access-betslip";
import {
    kambiReducer,
    kambiClientReducer,
    kambiSocketResponseReducer,
    kambiErrorsReducer,
} from "@atg-sport-shared/sportsbook-data-access-kambi";
import {isApp, isSportApp} from "@atg-shared/system";
import {betOfferReducer} from "@atg-sport-shared/sportsbook-data-access-bet-offer";
import {routeReducer} from "@atg-sport-shared/sportsbook-data-access-route";
import {eventReducer} from "@atg-sport-shared/sportsbook-data-access-event";
import {shortInfoReducer} from "@atg-sport-shared/sportsbook-data-access-short-info";
import {popularReducer} from "@atg-sport-shared/sportsbook-data-access-popular";
import {searchReducer} from "@atg-sport-shared/sportsbook-data-access-search";
import {heroReducer} from "@atg-sport-shared/sportsbook-data-access-hero";
import {loadingReducer} from "@atg-sport-shared/sportsbook-data-access-loading";
import {staticContentReducer} from "@atg-sport-shared/sportsbook-data-access-static-content";
import {statisticsReducer} from "@atg-sport-shared/sportsbook-data-access-statistics";
import {nonRegionalSportsReducer} from "@atg-sport-shared/sportsbook-data-access-non-regional-sport";
import {crossPromotionsReducer} from "@atg-sport-shared/sportsbook-data-access-cross-promotions";
import {fallbackHeroReducer} from "@atg-sport-shared/sportsbook-data-access-fallback-hero";
import {prePacksReducer} from "@atg-sport-shared/sportsbook-data-access-pre-packs";
import {rootReducer as big9} from "@atg-sport-shared/big9-data-access-root";

export const sportReducer = combineReducers({
    kambi: kambiReducer,
    kambiClient: kambiClientReducer,
    route: routeReducer,
    betslip: betslipReducer,
    loadingState: loadingReducer,
    groups: groupReducer,
    events: eventReducer,
    betOffers: betOfferReducer,
    prePacks: prePacksReducer,
    // Web is not using the hero reducer anymore
    ...(isApp || isSportApp ? {hero: heroReducer} : {}),
    crossPromotions: crossPromotionsReducer,
    staticContent: staticContentReducer,
    nonRegionalSports: nonRegionalSportsReducer,
    search: searchReducer,
    popular: popularReducer,
    kambiSocketResponses: kambiSocketResponseReducer,
    statistics: statisticsReducer,
    shortInfo: shortInfoReducer,
    selectedFallbackEvent: fallbackHeroReducer,
    kambiErrors: kambiErrorsReducer,
    big9,
});
