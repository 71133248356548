import {LOADING_STATUS} from "@atg-shared/fetch-types";
import {
    type FetchFailAction,
    OFFERING_FETCH_FAIL,
    SERVICE,
} from "@atg-sport-shared/sportsbook-data-access-offering";
import {
    type SetLiveEvents,
    SET_LIVE_EVENTS,
} from "@atg-sport-shared/sportsbook-data-access-event";
import type {LoadingState} from "@atg-sport-shared/sportsbook-types-loading";

export type OfferingLoadingStateActions = SetLiveEvents | FetchFailAction;

const initialState: LoadingState = {
    liveEvents: LOADING_STATUS.INITIAL,
};

export const loadingReducer = (
    state: LoadingState = initialState,
    action: OfferingLoadingStateActions,
) => {
    switch (action.type) {
        case SET_LIVE_EVENTS:
            return {
                ...state,
                liveEvents: LOADING_STATUS.OK,
            };
        case OFFERING_FETCH_FAIL:
            if (action.payload.service !== SERVICE.LIVE_EVENTS) {
                return state;
            }

            return {
                ...state,
                liveEvents: LOADING_STATUS.ERROR,
            };
        default:
            return state;
    }
};
