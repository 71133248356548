import type {KambiBetslip} from "@atg-sport-shared/sportsbook-types-kambi";

export const SET_BETSLIP_OUTCOMES = "sport/SET_BETSLIP_OUTCOMES";

export const SET_BETSLIP_MAXIMIZED = "sport/SET_BETSLIP_MAXIMIZED";

export const SET_BETSLIP_PLACING_BET = "sport/SET_BETSLIP_PLACING_BET";

type OutcomeIds = Array<number>;

export type SetBetslipOutcomes = {
    type: typeof SET_BETSLIP_OUTCOMES;
    payload: {outcomeIds: OutcomeIds; betslip?: KambiBetslip};
};

export type SetBetslipMaximized = {
    type: typeof SET_BETSLIP_MAXIMIZED;
    payload: boolean;
};

export type SetBetslipPlacingBet = {
    type: typeof SET_BETSLIP_PLACING_BET;
    payload: boolean;
};

export const setBetslipOutcomes = (
    outcomeIds: OutcomeIds,
    betslip?: KambiBetslip,
): SetBetslipOutcomes => ({
    type: SET_BETSLIP_OUTCOMES,
    payload: {outcomeIds, betslip},
});

export const setBetslipMaximized = (maximized: boolean): SetBetslipMaximized => ({
    type: SET_BETSLIP_MAXIMIZED,
    payload: maximized,
});

export const setBetslipPlacingBet = (placing: boolean): SetBetslipPlacingBet => ({
    type: SET_BETSLIP_PLACING_BET,
    payload: placing,
});

export type BetslipActions =
    | SetBetslipOutcomes
    | SetBetslipMaximized
    | SetBetslipPlacingBet;
