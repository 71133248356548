import type {
    KambiHighlightedResponse,
    ParsedHighlightedGroups,
} from "@atg-sport-shared/sportsbook-types-group";
import {checkSportFilter} from "@atg-sport-shared/sportsbook-utils-helpers";
import {getCommonFields} from "./basegroup.parser";

type KambiHighlightedGroups = Array<KambiHighlightedResponse>;

export function kambiHighlightedParser(
    highlightedGroups: KambiHighlightedGroups,
): ParsedHighlightedGroups {
    const data: ParsedHighlightedGroups = {};

    highlightedGroups.forEach((highlightedGroup) => {
        const {id, pathTermId, eventCount, sortOrder} = highlightedGroup;

        data[id] = {
            ...getCommonFields(highlightedGroup),
            path: `${checkSportFilter(pathTermId.split("/")[1])}${pathTermId}`,
            isHighlighted: true,
            leagueSortOrder: parseInt(sortOrder, 10),
            hasEvents: Boolean(eventCount),
            hasPrematch: true,
        };
    });
    return data;
}
