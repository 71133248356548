import type {FetchReducer} from "@atg-shared/fetch-types";
import {createFetchReducer} from "@atg-shared/fetch-redux";
import type {HeroState} from "@atg-sport-shared/sportsbook-types-hero";
import type {HeroActions} from "./hero.actions";
import {REQUEST_HEROES, RECEIVE_HEROES} from "./hero.actions";

const initialState: HeroState = {
    items: [],
};

export const heroReducer: FetchReducer<HeroState, HeroActions> = createFetchReducer(
    REQUEST_HEROES,
    RECEIVE_HEROES,
    "",
    (state: HeroState, action: HeroActions) =>
        action.type === RECEIVE_HEROES && !action.error ? {items: action.payload} : state,
    initialState,
);
