import type {SportsbookRoute} from "@atg-sport-shared/sportsbook-types-root";

export const SB_SET_ROUTE = "SB_SET_ROUTE";
export const SB_RESET_ROUTE = "SB_RESET_ROUTE";
export const SB_SET_ROUTE_NOT_FOUND = "SB_SET_ROUTE_NOT_FOUND";
export const SB_RESET_ROUTE_NOT_FOUND = "SB_RESET_ROUTE_NOT_FOUND";
export const SB_SET_ROUTE_PATH = "SB_SET_ROUTE_PATH";

export type SetRouteAction = {
    type: typeof SB_SET_ROUTE;
    payload: SportsbookRoute;
};

export type ResetRouteAction = {
    type: typeof SB_RESET_ROUTE;
};

export type SetRouteNotFoundAction = {
    type: typeof SB_SET_ROUTE_NOT_FOUND;
    url: string;
};

export type ResetRouteNotFoundAction = {
    type: typeof SB_RESET_ROUTE_NOT_FOUND;
};

export type SetRoutePathAction = {
    type: typeof SB_SET_ROUTE_PATH;
    payload: string;
};

export type RouteActions =
    | SetRouteAction
    | ResetRouteAction
    | SetRouteNotFoundAction
    | ResetRouteNotFoundAction;

export const resetRoute = (): ResetRouteAction => ({
    type: SB_RESET_ROUTE,
});

export const setRouteNotFound = (url: string): SetRouteNotFoundAction => ({
    type: SB_SET_ROUTE_NOT_FOUND,
    url,
});

export const resetRouteNotFound = (): ResetRouteNotFoundAction => ({
    type: SB_RESET_ROUTE_NOT_FOUND,
});

export const setRoute = (payload: SportsbookRoute): SetRouteAction => ({
    type: SB_SET_ROUTE,
    payload,
});

export const setPath = (payload: string): SetRoutePathAction => ({
    type: SB_SET_ROUTE_PATH,
    payload,
});
