import {values} from "lodash";
import type {
    ParsedLiveGroups,
    ParsedPrematchGroups,
    ParsedHighlightedGroups,
    GroupState,
    GroupMap,
    CombinedGroup,
} from "@atg-sport-shared/sportsbook-types-group";
import {
    type GroupActions,
    OFFERING_SET_GROUPS,
    OFFERING_SET_LIVE_GROUPS,
    OFFERING_SET_HIGHLIGHTED_GROUPS,
    OFFERING_SET_LIST_VIEW_GROUPS,
} from "./group.actions";

const mergeGroups = (
    state: GroupState,
    groups: ParsedPrematchGroups | GroupMap,
): GroupState =>
    values(groups).reduce(
        (acc, group) => ({...acc, [group.id]: {...acc[group.id], ...group}}),
        state,
    );

const mergeLiveGroups = (state: GroupState, pathGroups: ParsedLiveGroups): GroupState => {
    const nonLiveAndLeagueGroups = values(state).filter(
        (g) => g.hasPrematch || g.defaultEventType,
    );
    const data: GroupState = {};

    nonLiveAndLeagueGroups.forEach((ng) => {
        data[ng.id] = {...ng, hasLive: false};
    });

    Object.keys(pathGroups).forEach((pathGroupKey) => {
        if (data[pathGroupKey]) {
            data[pathGroupKey] = {
                ...data[pathGroupKey],
                ...pathGroups[pathGroupKey],
            };
        } else {
            data[pathGroupKey] = pathGroups[pathGroupKey] as CombinedGroup;
        }
    });

    return data;
};

const mergeHighlightedGroups = (
    state: GroupState,
    pathGroups: ParsedHighlightedGroups,
): GroupState => {
    const data = {...state};
    Object.keys(pathGroups).forEach((pathGroupKey) => {
        if (data[pathGroupKey]) {
            data[pathGroupKey] = {
                ...state[pathGroupKey],
                ...pathGroups[pathGroupKey],
            };
        } else {
            data[pathGroupKey] = {...pathGroups[pathGroupKey]} as CombinedGroup;
        }
    });

    return data;
};

export const groupReducer = (state: GroupState = {}, action: GroupActions) => {
    switch (action.type) {
        case OFFERING_SET_GROUPS:
        case OFFERING_SET_LIST_VIEW_GROUPS:
            return mergeGroups(state, action.payload);

        case OFFERING_SET_LIVE_GROUPS:
            return mergeLiveGroups(state, action.payload);

        case OFFERING_SET_HIGHLIGHTED_GROUPS:
            return mergeHighlightedGroups(state, action.payload);

        default:
            return state;
    }
};
