import type {Coupon} from "@atg-sport-shared/big9-types/couponTypes";

export const PURCHASE_FAILED = "big9/PURCHASE_FAILED";
export const PURCHASE_SUCCESS = "big9/PURCHASE_SUCCESS";
export const START_PURCHASE = "big9/START_PURCHASE";
export const PURCHASE_LOADING = "big9/PURCHASE_LOADING";
export const PURCHASE_DONE = "big9/PURCHASE_DONE";

export type StartPurchaseAction = {
    type: typeof START_PURCHASE;
    payload: {harryStake: Coupon["coupon"]["harryStake"]};
};

export const startPurchase = (
    harryStake: Coupon["coupon"]["harryStake"],
): StartPurchaseAction => ({
    type: START_PURCHASE,
    payload: {
        harryStake,
    },
});

export type PurchaseLoadingAction = {
    type: typeof PURCHASE_LOADING;
};

export const loadingPurchase = (): PurchaseLoadingAction => ({
    type: PURCHASE_LOADING,
});

export type PurchaseSuccessAction = {
    type: typeof PURCHASE_SUCCESS;
};

export const purchaseSuccess = (): PurchaseSuccessAction => ({
    type: PURCHASE_SUCCESS,
});

export type PurchaseDoneAction = {
    type: typeof PURCHASE_DONE;
    payload?: null;
    error?: boolean;
};

export const purchaseDone = (): PurchaseDoneAction => ({
    type: PURCHASE_DONE,
});

export type PurchaseFailedAction = {
    type: typeof PURCHASE_FAILED;
    payload: {message?: string};
    error?: boolean;
};

export const purchaseFailed = ({
    payload,
    error,
}: {
    payload: {message?: string};
    error?: boolean;
}): PurchaseFailedAction => ({
    type: PURCHASE_FAILED,
    payload,
    error,
});

export const LACKING_FUNDS = "big9/LACKING_FUNDS";

export type LackingFundsAction = {
    type: typeof LACKING_FUNDS;
};

export const lackingFunds = (): LackingFundsAction => ({
    type: LACKING_FUNDS,
});
