import root from "window-or-global";
import {get} from "lodash";
import Features, {sportUseProdData} from "@atg-shared/client-features";
import {ODDS_FORMAT_DECIMAL} from "@atg-sport-shared/sportsbook-types-kambi";
import {isApp, isSportApp} from "@atg-shared/system";

const useProdData = Features.isEnabled(sportUseProdData);

const baseStaticUrl = get(root, "clientConfig.sport.kambi.bootstrapUrl");

const env = useProdData ? "prod" : get(root, "clientConfig.sport.kambi.env");

const baseOfferingUrl = useProdData
    ? "https://eu-offering-api.kambicdn.com"
    : get(root, "clientConfig.sport.kambi.offeringUrl");

export const CLIENT_SRC = `${baseStaticUrl}/client/atg/kambi-bootstrap.js?cb=${Date.now()}`;

const SORT_BY_TIME = "sortByTime";
const SORT_BY_LEAGUE = "sortByLeague";

export type KambiCustomerSettings = {
    disableCashIn: boolean;
    enableServerSideCashOut: boolean;
    enableBetSettlementToaster: boolean;
    enablePrematchStats: boolean;
    enableQuickBrowse: boolean;
    enableStickyContent: boolean;
    enableStickyHeader: boolean;
    enableFixedHeightClient: boolean;
    fixedHeaderHeight: number;
    enableNewFrameworkLayout: boolean;
    enableOddsFormatSelector: boolean;
    enableRacingModePinnedBetslip: boolean;
    enableHomeModePinnedBetslip: boolean;
    hideHeader: boolean;
    enableFilterMenu: boolean;
    enablePreviouslySearchedTerms: boolean;
    enableTermSearch: boolean;
    enableNavigationPanel: boolean;
    enableAllSportsInNavpanel: boolean;
    enableMyBetsHarmonization: boolean;
    reservedRoutes: Array<string>;
    emptyClientRoutes: Array<string>;
    googleAnalyticsWebPropertyID: string;
    disableFilterTabs: boolean;
    enableMultiSportsEventsFilter: boolean;
    showBreadcrumbsInPlaceOfTermSearch: boolean;
    showGroupHeadingsInListView: boolean;
    customizedBetslipFeedbackTypes: Array<string>;
    defaultSortForEventGroups: typeof SORT_BY_TIME | typeof SORT_BY_LEAGUE;
    prioritiseClientApiLoading: boolean;
    startingSoonInterval: number;
};

export type KambiUserSettings = {
    oddsFormat: string;
    currency: string;
    locale: string;
    market: string;
    playerId?: string;
    ticket?: string;
    streamingAllowedForPlayer: boolean;
    allowCookies?: boolean;
    environment: string;
};

export type KambiOfferingSettings = {
    baseUrl: string;
    offering: string;
    sessionId: number | void;
    baseParams: {
        lang: string;
        market: string;
        // eslint-disable-next-line
        client_id: number;
        // eslint-disable-next-line
        channel_id: number; // 1=desktop, 3=mobile
    };
    pollingIntervals: {
        getGroups: number;
        getLiveEvents: number;
        getSoonEvents: number;
        getHighlighted: number;
    };
};

export type KambiWidgetSettings = {
    hidden: Array<string>;
};

/*
 * GA has issues reading query params that are between the base path and kambi's
 * hash-routing, so they have to be placed after the hashroute, making them part of
 * kambi's route. In order for our reserved/empty routes to work even when they
 * contain query params (utm-tags mainly), we optionally match a question mark which
 * can be followed by any characters as long as they occur at the end of a route.
 */
export const withOptionalParams = (s: string): string => `${s}(?:\\?.*)?`;

export const DEFAULT_CUSTOMER_SETTINGS: KambiCustomerSettings = {
    disableCashIn: false,
    enableServerSideCashOut: true,
    enableBetSettlementToaster: true,
    enablePrematchStats: true,
    enableQuickBrowse: true,
    enableStickyContent: true,
    enableStickyHeader: false,
    enableFixedHeightClient: false,
    fixedHeaderHeight: 34,
    enableNewFrameworkLayout: true,
    enableOddsFormatSelector: false,
    enableRacingModePinnedBetslip: false,
    enableHomeModePinnedBetslip: false,
    hideHeader: true,
    enableFilterMenu: false,
    enablePreviouslySearchedTerms: false,
    enableTermSearch: false,
    enableNavigationPanel: false,
    enableAllSportsInNavpanel: false,
    reservedRoutes: [
        "^home",
        "sports/a-z",
        "filter/football/[A-Za-z0-9_-]+/[A-Za-z0-9_-]+/all/league-table",
        "filter/football/[A-Za-z0-9_-]+/[A-Za-z0-9_-]+/all/top-lists",
    ].map(withOptionalParams),
    emptyClientRoutes: [
        "home(/.*)?",
        "sports/a-z",
        "filter/football/[A-Za-z0-9_-]+/[A-Za-z0-9_-]+/all/league-table",
        "filter/football/[A-Za-z0-9_-]+/[A-Za-z0-9_-]+/all/top-lists",
    ].map(withOptionalParams),
    googleAnalyticsWebPropertyID: "",
    disableFilterTabs: false,
    enableMultiSportsEventsFilter: false,
    showBreadcrumbsInPlaceOfTermSearch: false,
    showGroupHeadingsInListView: true,
    customizedBetslipFeedbackTypes: ["VET_COUPON_UNFUNDED", "VET_NOT_ENOUGH_FUNDS"],
    defaultSortForEventGroups: SORT_BY_LEAGUE,
    prioritiseClientApiLoading: true,
    startingSoonInterval: 18,
    enableMyBetsHarmonization: !isApp && !isSportApp, // Set to true once new MyBets design is supported in the app
};

export const DEFAULT_USER_SETTINGS: KambiUserSettings = {
    oddsFormat: ODDS_FORMAT_DECIMAL,
    currency: "SEK",
    locale: "sv_SE",
    market: "SE",
    playerId: "",
    ticket: "",
    streamingAllowedForPlayer: true,
    allowCookies: undefined,
    environment: env,
};

export const DEFAULT_OFFERING_SETTINGS: KambiOfferingSettings = {
    baseUrl: `${baseOfferingUrl}/`,
    offering: "atg",
    sessionId: undefined,
    baseParams: {
        lang: "sv_SE",
        market: "SE",
        client_id: 2,
        channel_id: 1,
    },
    pollingIntervals: {
        getGroups: 20 * 60 * 1000,
        getLiveEvents: 30 * 1000,
        getSoonEvents: 2 * 60 * 1000,
        getHighlighted: 20 * 60 * 1000,
    },
};

export enum CustomElements {
    EventStatisticsButton = "event-statistics-button",
    EsportPregame = "esport-pregame",
    EsportStream = "esport-stream",
    EsportScoreboard = "esport-scoreboard",
    ShortMatchInfo = "short-match-info",
    InsufficientBalance = "insufficient-balance",
    BetslipBalance = "betslip-balance",
    PrematchStatistics = "prematch-statistics",
    FootballStudioDice = "football-studio-dice",
    EventLeagueTable = "event-league-table",
    LoginBanner = "login-banner",
    BetBuilderVideo = "bet-builder-video",
}

export const WIDGET_SETTINGS = {
    hidden: ["gm-league-table", "gm-team-performance", "gm-head-to-head"],
    resets: ["gm-league-table", "gm-team-performance", "gm-head-to-head"],
    widgets: [
        {
            widgetId: "BetslipBalance",
            widgetType: "web-component",
            tag: CustomElements.BetslipBalance,
            height: 38,
        },
        {
            widgetId: "InsufficientBalance",
            widgetType: "web-component",
            tag: CustomElements.InsufficientBalance,
            height: 58,
            conditions: {
                authenticated: true,
            },
        },
        {
            widgetId: "EventLeagueTable",
            widgetType: "web-component",
            tag: CustomElements.EventLeagueTable,
        },
        {
            widgetId: "LoginBanner",
            widgetType: "web-component",
            tag: CustomElements.LoginBanner,
        },
        {
            widgetId: "EventStatisticsButton",
            widgetType: "web-component",
            tag: CustomElements.EventStatisticsButton,
        },
        {
            widgetId: "EsportPregame",
            widgetType: "web-component",
            tag: CustomElements.EsportPregame,
        },
        {
            widgetId: "EsportStream",
            widgetType: "web-component",
            tag: CustomElements.EsportStream,
        },
        {
            widgetId: "EsportScoreboard",
            widgetType: "web-component",
            tag: CustomElements.EsportScoreboard,
        },
        {
            widgetId: "ShortMatchInfo",
            widgetType: "web-component",
            tag: CustomElements.ShortMatchInfo,
        },
        {
            widgetId: "PrematchStatistics",
            widgetType: "web-component",
            tag: CustomElements.PrematchStatistics,
        },
        {
            widgetId: "FootballStudioDice",
            widgetType: "web-component",
            tag: CustomElements.FootballStudioDice,
        },
        {
            widgetId: "BetBuilderVideo",
            widgetType: "web-component",
            tag: CustomElements.BetBuilderVideo,
        },
    ],
    targets: {
        eventTop: [
            {
                order: 1,
                widgetId: "LoginBanner",
            },
        ],
        eventBetOffersCategories: [
            {
                order: 1,
                widgetId: "BetBuilderVideo",
                args: {category: "BET-BUILDER"},
            },
        ],
        eventBeforeBetOffers: [
            {
                order: 1,
                widgetId: "ShortMatchInfo",
            },
            {
                order: 1,
                widgetId: "EsportStream",
            },
        ],
        betslipMain: [
            {
                order: 1,
                widgetId: "BetslipBalance",
            },
            {
                order: 2,
                widgetId: "InsufficientBalance",
            },
        ],
        eventColumn2Placeholder: [
            {
                order: 2,
                widgetId: "EsportScoreboard",
            },
            {
                order: 3,
                widgetId: "EsportPregame",
            },
        ],
        eventColumn2Placeholder2: [
            {
                order: 0,
                widgetId: "EventStatisticsButton",
            },
            {
                order: 1,
                widgetId: "PrematchStatistics",
            },
            {
                order: 2,
                widgetId: "EventLeagueTable",
            },
        ],
        eventGroupBottom: [
            {
                order: 0,
                widgetId: "FootballStudioDice",
            },
        ],
    },
};
