export const PLACE_BET = "big9/PLACE_BET";
export const PLACE_BET_V2 = "big9/PLACE_BET_V2";
export const REQUEST_PLACE_BET = "big9/REQUEST_PLACE_BET";
export const RECEIVE_PLACE_BET = "big9/RECEIVE_PLACE_BET";
export const RECEIVE_PLACE_BET_SIMPLE_HARRY_MODAL =
    "big9/RECEIVE_PLACE_BET_SIMPLE_HARRY_MODAL";

export const REQUEST_BETS = "big9/REQUEST_BETS";
export const RECEIVE_BETS = "big9/RECEIVE_BETS";
export const START_FETCHING_BETS = "big9/START_FETCHING_BETS";
export const FETCH_MORE_BETS = "big9/FETCH_MORE_BETS";
export const FETCH_BETS_LAST_PAGE_REACHED = "big9/FETCH_BETS_LAST_PAGE_REACHED";

export const CORRECT_BET = "big9/CORRECT_BET";
export const SET_CORRECTED_BETS = "big9/SET_CORRECTED_BETS";
export const REVEAL_BET_ROWS = "big9/REVEAL_BET_ROWS";

export const SET_HIGHLIGHT_BIG9_BET = "big9/SET_HIGHLIGHT_BIG9_BET";
export const SET_HIGHLIGHT_BIG9_OFFERING_BET = "big9/SET_HIGHLIGHT_BIG9_OFFERING_BET";

export const TRIGGER_KYC_QUESTIONNAIRE = "big9/TRIGGER_KYC_QUESTIONNAIRE";

export const UPDATE_BETS = "big9/UPDATE_BETS";
