import type {Match} from "@atg-sport-shared/big9-types/matchTypes";

export const START_MATCH_CLOCK = "big9/START_MATCH_CLOCK";
export const STOP_MATCH_CLOCK = "big9/STOP_MATCH_CLOCK";
export const UPDATE_MATCH_CLOCK = "big9/UPDATE_MATCH_CLOCK";
export const ADD_MATCH_CLOCK = "big9/ADD_MATCH_CLOCK";
export const START_CLOCKS_BY_OFFERING = "big9/START_CLOCKS_BY_OFFERING";

type StartMatchClockPayload = {
    matchId: Match["id"];
    minute?: number;
    timestamp?: string;
};

export type StartMatchClockAction = {
    type: typeof START_MATCH_CLOCK;
    payload: StartMatchClockPayload;
};

type StopMatchClockPayload = {matchId: Match["id"]};

export type StopMatchClockAction = {
    type: typeof STOP_MATCH_CLOCK;
    payload: StopMatchClockPayload;
};

type UpdateMatchClockPayload = {matchId: Match["id"]; minute: number};

export type UpdateMatchClockAction = {
    type: typeof UPDATE_MATCH_CLOCK;
    payload: UpdateMatchClockPayload;
};

type AddMatchClockPayload = {matchId: Match["id"]; minute: number};

export type AddMatchClockAction = {
    type: typeof ADD_MATCH_CLOCK;
    payload: AddMatchClockPayload;
};

type StartClocksByOfferingPayload = {
    offeringId: string;
};

export type StartClocksByOfferingAction = {
    type: typeof START_CLOCKS_BY_OFFERING;
    payload: StartClocksByOfferingPayload;
};

export const startMatchClock = ({
    matchId,
    minute,
    timestamp,
}: StartMatchClockPayload): StartMatchClockAction => ({
    type: START_MATCH_CLOCK,
    payload: {matchId, minute, timestamp},
});

export const addMatchClock = ({
    matchId,
    minute,
}: AddMatchClockPayload): AddMatchClockAction => ({
    type: ADD_MATCH_CLOCK,
    payload: {matchId, minute},
});

export const stopMatchClock = ({
    matchId,
}: StopMatchClockPayload): StopMatchClockAction => ({
    type: STOP_MATCH_CLOCK,
    payload: {matchId},
});

export const updateMatchClock = ({
    matchId,
    minute,
}: UpdateMatchClockPayload): UpdateMatchClockAction => ({
    type: UPDATE_MATCH_CLOCK,
    payload: {matchId, minute},
});

export const startClocksByOffering = (
    offeringId: string,
): StartClocksByOfferingAction => ({
    type: START_CLOCKS_BY_OFFERING,
    payload: {offeringId},
});
