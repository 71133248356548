const BETSLIP_OUTCOMES = "BetslipOutcomes";
const PLACE_BET_STATE = "PlaceBetState";
const EVENT_INFO = "EventInfo";
const BETSLIP_MAXIMIZED = "BetslipMaximize";
const BETSLIP_MAXIMIZED_CHANGE = "BetslipMaximizedChange";
const CLIENT_ODDS_FORMAT = "ClientOddsFormat";
const HEARTBEAT = "WAPI_HEARTBEAT";
const LOGOUT = "WAPI_LOGOUT";
const NOTIFICATION = "WAPI_NOTIFICATION";
const PLACE_BET = "PLACE_BET";
const BETSLIP_UPDATE_STAKE = "BETSLIP_UPDATE_STAKE";
const STAKE_UPDATE_TYPE_COMBINATION = "STAKE_UPDATE_TYPE_COMBINATION";
const BETSLIP_STAKE_UPDATED = "BetslipStakeUpdated";

const STAKE_UPDATE_TYPE_SINGLE = "STAKE_UPDATE_TYPE_SINGLE";
const EVENT_STATS = "EVENT_STATS";
const NOT_FOUND = "NOT_FOUND";
const USER_DATA = "UserData";
const USER_SESSION_CHANGE = "UserSessionChange";
const PAGE_INFO = "PageInfo";

export const RCT_SINGLE = "RCT_SINGLE";
export const RCT_COMBINATION = "RCT_COMBINATION";

export const WAPI = {
    BETSLIP_OUTCOMES,
    PLACE_BET_STATE,
    EVENT_INFO,
    PAGE_INFO,
    BETSLIP_MAXIMIZED,
    BETSLIP_MAXIMIZED_CHANGE,
    CLIENT_ODDS_FORMAT,
    HEARTBEAT,
    LOGOUT,
    NOTIFICATION,
    PLACE_BET,
    BETSLIP_UPDATE_STAKE,
    BETSLIP_STAKE_UPDATED_TYPES: {
        STAKE_UPDATE_TYPE_COMBINATION,
        STAKE_UPDATE_TYPE_SINGLE,
    },
    BETSLIP_STAKE_UPDATED,
    EVENT_STATS,
    NOT_FOUND,
    USER_DATA,
    USER_SESSION_CHANGE,
    LOGOUT_REASONS: {
        LOGIN_FAILED: "LOGIN_FAILED",
        LOGOUT_REQUESTED: "LOGOUT_REQUESTED",
        SESSION_TIMED_OUT: "SESSION_TIMED_OUT",
    },
};

export const ODDS_FORMAT_DECIMAL = "decimal";
export const ODDS_FORMAT_FRACTIONAL = "fractional";
export const ODDS_FORMAT_AMERICAN = "american";

const NOTIFICATION_LOGIN = "punterLoginSucceeded";
const NOTIFICATION_LOGOUT = "sessionTimedOut";
const CUSTOMIZED_FEEDBACK = "customizedFeedback";
const REQUEST_LOGIN = "loginRequested";
const DATA_LAYER_PUSHED = "dataLayerPushed";
const PAGE_RENDERED = "pageRendered";
const PLACING_BET_FAILED = "placingBetFailed";
const PLACED_BET = "placedBet";

export const NOTIFICATION_TYPE = {
    LOGIN: NOTIFICATION_LOGIN,
    LOGOUT: NOTIFICATION_LOGOUT,
    CUSTOMIZED_FEEDBACK,
    REQUEST_LOGIN,
    DATA_LAYER_PUSHED,
    PAGE_RENDERED,
    PLACING_BET_FAILED,
    PLACED_BET,
};

const LOGIN_FAILED_KAMBI_ERROR = "KAMBI_ERROR";
const LOGIN_FAILED_ATG_ERROR = "ATG_ERROR";

export const LOGIN_FAILED = {
    KAMBI_ERROR: LOGIN_FAILED_KAMBI_ERROR,
    ATG_ERROR: LOGIN_FAILED_ATG_ERROR,
};

export const WOULD_EXCEED_WEEKLY_LOSS_LIMIT = "wouldExceedWeeklyLossLimit";

export const PLACE_BET_STATE_VALUE = {
    PLACING: "placing",
    SUCCEEDED: "succeeded",
    FAILED: "failed",
};
