// @ts-ignore
import queryString from "query-string";
import dayjs from "dayjs";
import * as Sfx from "redux-saga/effects";
import {get} from "lodash";
import {pureFetch} from "@atg-shared/fetch";
import {
    kambiGroupsParser,
    kambiLivePathParser,
    kambiHighlightedParser,
    kambiLeaguesParser,
    kambiEventsParser,
    kambiBetOffersParser,
    kambiPathParser,
} from "@atg-sport-shared/sportsbook-utils-parsers";
import {
    setGroups,
    setLiveGroups,
    setHighlightedGroups,
    setListViewGroups,
} from "@atg-sport-shared/sportsbook-data-access-group";
import {getKambiFormattedDate} from "@atg-sport-shared/sportsbook-utils-helpers";
import type {KambiResponseType} from "@atg-sport-shared/sportsbook-types-event";
import {setBetOffers} from "@atg-sport-shared/sportsbook-data-access-bet-offer";
import {setEvents, setLiveEvents} from "@atg-sport-shared/sportsbook-data-access-event";
import {
    DEFAULT_CUSTOMER_SETTINGS,
    DEFAULT_OFFERING_SETTINGS,
} from "@atg-sport-shared/sportsbook-utils-kambi";
import {
    type GetLeagueAction,
    OFFERING_GET_GROUPS,
    OFFERING_GET_LIVE_EVENTS,
    OFFERING_GET_SOON_EVENTS,
    OFFERING_GET_LEAGUE,
    OFFERING_GET_HIGHLIGHTED,
    offeringFetchFail,
    highlightsSuccess,
} from "./offering.actions";

export const listViewBasePath = `listView/all/all/all/all`;

export const generateOfferingUrl = (
    service: string,
    params: {[key: string]: string | boolean} = {},
): string => {
    const {baseUrl, baseParams, offering} = DEFAULT_OFFERING_SETTINGS;
    const queryParams = queryString.stringify({
        ...params,
        ...baseParams,
        ncid: Date.now(),
    });
    return `${baseUrl}offering/v2018/${offering}/${service}?${queryParams}`;
};

export const SERVICE = {
    GROUPS: "GROUPS",
    LIVE_EVENTS: "LIVE_EVENTS",
    LIST_VIEW: "LIST_VIEW",
    HIGHLIGHTED: "HIGHLIGHTED",
    STARTING_WITHIN: "STARTING_WITHIN",
};

export const fetchKambiData = (
    service: string,
    params: {[key: string]: string} = {},
): Promise<any> => {
    const url = generateOfferingUrl(service, params);
    return pureFetch(url, {method: "GET"});
};

export function* getGroupsFlow(): Generator<Sfx.Effect, void, any> {
    try {
        const groupData = yield Sfx.call(fetchKambiData, "group.json");

        const parsedGroups = kambiGroupsParser(groupData.data.group.groups);

        yield Sfx.put(setGroups(parsedGroups));
    } catch (e: unknown) {
        yield Sfx.put(
            offeringFetchFail(
                SERVICE.GROUPS,
                e as {
                    [key: string]: string;
                },
            ),
        );
    }
}

export function* getLiveEventsFlow(): Generator<Sfx.Effect, void, any> {
    try {
        const liveEventsData = yield Sfx.call(
            fetchKambiData,
            `${listViewBasePath}/in-play.json`,
        );

        const events = get(liveEventsData, "data.events", []);

        const parsedGroups = kambiLivePathParser(events);
        const parsedEvents = kambiEventsParser(events);
        const parsedBetOffers = kambiBetOffersParser(events);

        yield Sfx.put(setLiveGroups(parsedGroups));
        yield Sfx.put(setLiveEvents(parsedEvents, Date.now()));
        yield Sfx.put(setBetOffers(parsedBetOffers));
    } catch (e: unknown) {
        yield Sfx.put(
            offeringFetchFail(
                SERVICE.LIVE_EVENTS,
                e as {
                    [key: string]: string;
                },
            ),
        );
    }
}

export function* getLeagueFlow(
    action: GetLeagueAction,
): Generator<Sfx.Effect, void, any> {
    try {
        const leagueData = yield Sfx.call(
            fetchKambiData,
            `listView/${action.payload}.json`,
        );

        const parsedGroups = kambiLeaguesParser(leagueData.data);

        yield Sfx.put(setListViewGroups(parsedGroups));
    } catch (e: unknown) {
        yield Sfx.put(
            offeringFetchFail(
                SERVICE.LIST_VIEW,
                e as {
                    [key: string]: string;
                },
            ),
        );
    }
}

export function* getHighlightedFlow(): Generator<Sfx.Effect, void, any> {
    try {
        const highlightedData = yield Sfx.call(fetchKambiData, "group/highlight.json");

        const parsedHighlightedGroups = kambiHighlightedParser(
            highlightedData.data.groups,
        );

        yield Sfx.put(setHighlightedGroups(parsedHighlightedGroups));
        yield Sfx.put(highlightsSuccess());
    } catch (e: unknown) {
        yield Sfx.put(
            offeringFetchFail(
                SERVICE.HIGHLIGHTED,
                e as {
                    [key: string]: string;
                },
            ),
        );
    }
}

export function* getStartingSoonFlow(): Generator<Sfx.Effect, void, any> {
    try {
        const now = dayjs();
        const from = getKambiFormattedDate(now);
        const to = getKambiFormattedDate(
            now.add(DEFAULT_CUSTOMER_SETTINGS.startingSoonInterval, "hours"),
        );

        // Kambi's starting-soon is actually a starting-within in disguise, so
        // in order to fetch the same data as the kambi client will we use
        // customer settings "startinSoonInterval" to set the to date in
        // for the starting-within request and use now as from since that
        // is how the kambi client does it.
        const eventsData: KambiResponseType = yield Sfx.call(
            // @ts-ignore
            fetchKambiData,
            `${listViewBasePath}/starting-within`,
            {from, to, useCombined: true},
        );

        const events = get(eventsData, "data.events", []);

        const parsedGroups = kambiPathParser(events);
        const parsedEvents = kambiEventsParser(events);
        const parsedBetOffers = kambiBetOffersParser(events);

        yield Sfx.put(setListViewGroups(parsedGroups));
        yield Sfx.put(setEvents(parsedEvents));
        yield Sfx.put(setBetOffers(parsedBetOffers));
    } catch (error: unknown) {
        yield Sfx.put(
            offeringFetchFail(
                SERVICE.STARTING_WITHIN,
                error as {
                    [key: string]: string;
                },
            ),
        );
    }
}

export function* offeringSaga(): Generator<Sfx.Effect, void, any> {
    yield Sfx.takeLatest(OFFERING_GET_GROUPS, getGroupsFlow);
    yield Sfx.takeLatest(OFFERING_GET_LIVE_EVENTS, getLiveEventsFlow);
    yield Sfx.takeLatest(OFFERING_GET_SOON_EVENTS, getStartingSoonFlow);
    yield Sfx.takeLatest(OFFERING_GET_HIGHLIGHTED, getHighlightedFlow);
    yield Sfx.takeLatest(OFFERING_GET_LEAGUE, getLeagueFlow);
}
