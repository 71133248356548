import type {BetslipState} from "@atg-sport-shared/sportsbook-types-betslip";
import type {BetslipActions} from "./betslip.actions";
import {
    SET_BETSLIP_OUTCOMES,
    SET_BETSLIP_MAXIMIZED,
    SET_BETSLIP_PLACING_BET,
} from "./betslip.actions";

const initialState = {
    outcomeIds: [],
    betslip: undefined,
    maximized: false,
    placingBet: false,
};

export const betslipReducer = (
    state: BetslipState = initialState,
    action: BetslipActions,
) => {
    switch (action.type) {
        case SET_BETSLIP_OUTCOMES:
            return {
                ...state,
                outcomeIds: action.payload.outcomeIds,
                betslip: action.payload.betslip,
            };

        case SET_BETSLIP_MAXIMIZED:
            return {
                ...state,
                maximized: action.payload,
            };

        case SET_BETSLIP_PLACING_BET:
            return {
                ...state,
                placingBet: action.payload,
            };

        default:
            return state;
    }
};
