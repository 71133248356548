export const MAX_STAKE = 9999900;

export enum ErrorTypes {
    HARRY_ERROR = "harry",
    MAX_STAKE_ERROR = "maxStake",
    WILDCARD_ERROR = "wildcard",
    OUTCOMES_ERROR = "outcomes",
}

export const REQUEST_COUPONS = "big9/REQUEST_COUPONS";
export const RECEIVE_COUPONS = "big9/RECEIVE_COUPONS";
export const REQUEST_SHARED_COUPON = "big9/REQUEST_SHARED_COUPON";
export const RECEIVE_SHARED_COUPON = "big9/RECEIVE_SHARED_COUPON";
export const RESET_COUPONS = "big9/RESET_COUPONS";
export const REQUEST_CREATE_COUPON = "big9/REQUEST_CREATE_COUPON";
export const RECEIVE_CREATE_COUPON = "big9/RECEIVE_CREATE_COUPON";
export const REQUEST_SAVE_COUPON = "big9/REQUEST_SAVE_COUPON";
export const RECEIVE_SAVE_COUPON = "big9/RECEIVE_SAVE_COUPON";
export const REQUEST_DELETE_COUPON = "big9/REQUEST_DELETE_COUPON";
export const RECEIVE_DELETE_COUPON = "big9/RECEIVE_DELETE_COUPON";
export const CREATE_COUPON_FLOW = "big9/CREATE_COUPON_FLOW";
export const CREATE_HARRY_COUPON = "big9/CREATE_HARRY_COUPON";
export const CREATE_COUPON = "big9/CREATE_COUPON";
export const SET_STAKE = "big9/SET_STAKE";
export const SET_MODIFIED = "big9/SET_MODIFIED";
export const CLEAR_COUPON = "big9/CLEAR_COUPON";
export const TOGGLE_HARRY_FOR_COUPON = "big9/TOGGLE_HARRY_FOR_COUPON";
export const SET_HARRY = "big9/SET_HARRY";
export const SET_HARRY_STAKE = "big9/SET_HARRY_STAKE";
export const SET_WILDCARD = "big9/SET_WILDCARD";
export const VALIDATE_COUPON = "big9/VALIDATE_COUPON";
export const VALIDATE_COUPON_FAILED = "big9/VALIDATE_COUPON_FAILED";
export const CLEAR_COUPON_VALIDATION = "big9/CLEAR_COUPON_VALIDATION";
export const DELETE_COUPON = "big9/DELETE_COUPON";
export const DELETE_COUPON_FLOW = "big9/DELETE_COUPON_FLOW";
export const SAVE_COUPON = "big9/SAVE_COUPON";
export const COPY_COUPON = "big9/COPY_COUPON";

export const SET_COUPON = "big9/SET_COUPON";
export const SYNC_ACTIVE_COUPON = "big9/SYNC_ACTIVE_COUPON";
