export const REQUEST_OFFERINGS = "big9/REQUEST_OFFERINGS";
export const RECEIVE_OFFERINGS = "big9/RECEIVE_OFFERINGS";

export const REQUEST_OFFERING = "big9/REQUEST_OFFERING";
export const RECEIVE_OFFERING = "big9/RECEIVE_OFFERING";

export const REQUEST_OFFERING_START = "big9/REQUEST_OFFERING_START";
export const RECEIVE_OFFERING_START = "big9/RECEIVE_OFFERING_START";

export const OFFERING_UPDATE = "big9/OFFERING_UPDATE";

export const SET_ACTIVE_OFFERING_TYPE = "big9/SET_ACTIVE_OFFERING_TYPE";
