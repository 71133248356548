import type {Reducer, AnyAction} from "redux";
import {
    RECEIVE_COUPONS,
    TOGGLE_HARRY_FOR_COUPON,
    SET_WILDCARD,
    DELETE_COUPON,
    CREATE_COUPON,
    RESET_COUPONS,
    SET_COUPON,
    SYNC_ACTIVE_COUPON,
    type ReceiveCouponsAction,
    type SetWildcardAction,
    type ToggleHarryForCouponAction,
    type DeleteCouponAction,
    type CreateCouponAction,
    type ResetCouponsAction,
    type SetCouponAction,
    type SyncActiveCouponAction,
    RESET_OUTCOMES,
    TOGGLE_HARRY,
    TOGGLE_OUTCOME,
} from "@atg-sport-shared/big9-types";
import type {OutcomesState} from "@atg-sport-shared/big9-types/outcomeTypes";
import type {ApiCoupon} from "@atg-sport-shared/big9-types/couponTypes";
import {BetSelection} from "@atg-sport-shared/big9-types/selectionTypes";
import {
    generateOutcomeId,
    toggleOutcomeValue,
} from "@atg-sport-shared/big9-util-outcome-helpers";
import type {
    ToggleOutcomeAction,
    ResetOutcomesAction,
    ToggleHarryAction,
} from "./outcomes.actions";

type OutcomesActions =
    | SetCouponAction
    | ReceiveCouponsAction
    | ToggleOutcomeAction
    | ResetOutcomesAction
    | ToggleHarryAction
    | ToggleHarryForCouponAction
    | DeleteCouponAction
    | CreateCouponAction
    | SetWildcardAction
    | ResetCouponsAction
    | SyncActiveCouponAction;

const initialState = {};

const setCoupon = (
    state: OutcomesState,
    action: ReceiveCouponsAction | SetCouponAction,
) =>
    action.payload?.length
        ? {
              ...state,
              ...action.payload.reduce((acc, current: ApiCoupon): OutcomesState => {
                  const old = Object.values(current.coupon.outcomes).find(
                      (outcome) =>
                          state[
                              generateOutcomeId({
                                  couponId: current.id,
                                  matchId: outcome.matchId,
                              })
                          ],
                  );

                  return old
                      ? state
                      : {
                            ...acc,
                            ...current.coupon.outcomes,
                        };
              }, state),
          }
        : state;

const receiveCoupons = (state: OutcomesState, action: ReceiveCouponsAction) =>
    !action.error ? setCoupon(state, action) : state;

const syncCoupon = (state: OutcomesState, {payload}: SyncActiveCouponAction) => ({
    ...state,
    ...payload.coupon.outcomes,
});

const toggleOutcome = (state: OutcomesState, action: ToggleOutcomeAction) => {
    const {couponId, selection, matchId} = action.payload;
    const outcomeId = generateOutcomeId({couponId, matchId});
    const outcome = state[outcomeId];
    const betOfferType = [
        BetSelection.Home,
        BetSelection.Tie,
        BetSelection.Away,
    ].includes(selection)
        ? "fullTime"
        : "overUnder";

    const updatedOutcome = toggleOutcomeValue(outcome[betOfferType], selection);

    return {
        ...state,
        [outcomeId]: {
            ...outcome,
            [betOfferType]: updatedOutcome,
            matchId,
        },
    };
};

const resetOutcomes = (state: OutcomesState, action: ResetOutcomesAction) => ({
    ...state,
    ...action.payload.outcomeIds.reduce((acc, outcomeId) => {
        const stateOutcome = state[outcomeId];

        return {
            ...acc,
            [outcomeId]: {
                ...stateOutcome,
                fullTime: [],
                overUnder: [],
                harry: false,
            },
        };
    }, {}),
});

const toggleHarryRow = (state: OutcomesState, action: ToggleHarryAction) => {
    const {couponId, matchId, harry, harryIsDisabled} = action.payload;
    const outcomeId = generateOutcomeId({couponId, matchId});
    const outcome = state[outcomeId];

    return {
        ...state,
        [outcomeId]: {
            ...outcome,
            harry,
            harryIsDisabled,
        },
    };
};

const setHarryForCoupon = (state: OutcomesState, action: ToggleHarryForCouponAction) => {
    const {outcomeIds, active} = action.payload;
    return {
        ...state,
        ...outcomeIds.reduce(
            (acc, outcomeId) => ({
                ...acc,
                [outcomeId]: {...state[outcomeId], harry: active},
            }),
            {},
        ),
    };
};

const setHarryOnWildcard = (state: OutcomesState, action: SetWildcardAction) => {
    const {couponId, matchId} = action.payload;
    const outcomeId = generateOutcomeId({couponId, matchId});
    const outcome = state[outcomeId];

    return {
        ...state,
        [outcomeId]: {
            ...outcome,
            harry: false,
        },
    };
};

const deleteCouponOutcomes = (state: OutcomesState, action: DeleteCouponAction) => {
    const {outcomeIds} = action.payload;

    return Object.keys(state)
        .filter((key) => !outcomeIds?.includes(key))
        .reduce(
            (acc, curr) => ({
                ...acc,
                [curr]: state[curr],
            }),
            {},
        );
};

const createOutcomesForCoupon = (
    state: OutcomesState,
    {payload: {outcomes}}: CreateCouponAction,
): OutcomesState => ({
    ...state,
    ...outcomes,
});

export const outcomes: Reducer<OutcomesState> = (
    state: OutcomesState = {},
    _action: OutcomesActions | AnyAction,
) => {
    // Redux typescript issue, fixed in https://github.com/reduxjs/redux/pull/3679
    // TODO: Remove AnyAction type when Redux has been updated
    const action = _action as OutcomesActions;

    switch (action.type) {
        case SET_COUPON:
            return setCoupon(state, action);
        case SYNC_ACTIVE_COUPON:
            return syncCoupon(state, action);
        case RECEIVE_COUPONS:
            return receiveCoupons(state, action);
        case RESET_COUPONS:
            return initialState;
        case CREATE_COUPON:
            return createOutcomesForCoupon(state, action);
        case TOGGLE_OUTCOME: {
            return toggleOutcome(state, action);
        }
        case RESET_OUTCOMES: {
            return resetOutcomes(state, action);
        }
        case TOGGLE_HARRY: {
            return toggleHarryRow(state, action);
        }
        case TOGGLE_HARRY_FOR_COUPON: {
            return setHarryForCoupon(state, action);
        }
        case DELETE_COUPON: {
            return deleteCouponOutcomes(state, action);
        }
        case SET_WILDCARD: {
            return setHarryOnWildcard(state, action);
        }
        default: {
            return state;
        }
    }
};
