import type {BetMatch} from "@atg-sport-shared/big9-types/betTypes";
import type {Match} from "@atg-sport-shared/big9-types/matchTypes";
import {MatchStatus, MatchPushEventType} from "@atg-sport-shared/big9-types/matchTypes";

const assertUnreachable = (item: never): never => {
    throw new Error(`Unreachable case reached! ${item}`);
};

export const secondsToMinutes = (seconds: number) => Math.round(seconds / 60);

export const matchProgress = ({
    minutes,
    matchStatus,
}: {
    minutes?: number;
    matchStatus: MatchStatus;
}): number => {
    if (matchStatus === MatchStatus.DECIDED || matchStatus === MatchStatus.CANCELED) {
        return 100;
    }

    if (matchStatus === MatchStatus.ONGOING) {
        const progress = Math.round(+((minutes ?? 0) / 90).toFixed(2) * 100);

        if (progress <= 5) {
            return 5;
        }

        if (progress > 95) {
            return 95;
        }

        return progress;
    }

    return 0;
};

export const updateMatchFromLiveData = ({
    matchToUpdate,
    updateType,
    newMatchData,
}: {
    matchToUpdate: Match;
    updateType: MatchPushEventType;
    newMatchData: Match;
}): Match => {
    switch (updateType) {
        case MatchPushEventType.MatchStarted: {
            const {home, away} = newMatchData.score ?? {home: 0, away: 0};

            return {
                ...matchToUpdate,
                status: MatchStatus.ONGOING,
                score: {home, away},
            };
        }
        case MatchPushEventType.ScoreChange: {
            const {home, away} = newMatchData.score ?? {home: 0, away: 0};

            return {
                ...matchToUpdate,
                status: MatchStatus.ONGOING,
                score: {home, away},
            };
        }
        case MatchPushEventType.MatchEnded: {
            return {
                ...matchToUpdate,
                status: MatchStatus.DECIDED,
            };
        }
        case MatchPushEventType.MatchCanceled: {
            return {
                ...matchToUpdate,
                status: MatchStatus.CANCELED,
            };
        }
        case MatchPushEventType.StartCanceled: {
            return {
                ...matchToUpdate,
                status: MatchStatus.UPCOMING,
            };
        }
        case MatchPushEventType.Overtime: {
            return {
                ...matchToUpdate,
                liveData: {
                    ...matchToUpdate.liveData,
                    overTime: newMatchData.liveData?.overTime,
                },
            };
        }
        default:
            assertUnreachable(updateType);
    }

    return matchToUpdate;
};

export const hasMatchFinished = (match: BetMatch) =>
    match.status !== MatchStatus.UNDER_REVIEW &&
    (match.status === MatchStatus.DECIDED || match.status === MatchStatus.CANCELED);
