import {
    SHOP_SHARE_SERVICE_URL_TOKENIZED,
    SHOP_SHARE_SERVICE_URL,
} from "@atg-shared/service-url";
import {pureFetch} from "@atg-shared/fetch";
import {fetchAuthorized} from "@atg-shared/auth";
import type {AtgResponse} from "@atg-shared/fetch-types";
import type {ShopShare} from "@atg-shop-shared/types/shopShare";
import type {Coupon} from "@atg-sport-shared/big9-types/couponTypes";

export type ShareSportCouponResponse = {
    id: string;
    coupon: Coupon;
    modified: string;
};

export const createCoupon = (coupon: Coupon) =>
    fetchAuthorized<ShareSportCouponResponse>(
        `${SHOP_SHARE_SERVICE_URL_TOKENIZED}/sport-coupon`,
        {
            method: "POST",
            body: JSON.stringify({
                id: coupon.id,
                coupon,
            }),
        },
    );

export const deleteCoupon = (couponId: string) =>
    fetchAuthorized<ShareSportCouponResponse>(
        `${SHOP_SHARE_SERVICE_URL_TOKENIZED}/sport-coupon/${couponId}`,
        {
            method: "DELETE",
            body: JSON.stringify({
                id: couponId,
            }),
        },
    );

export const fetchCoupon = (
    couponId: string,
): Promise<AtgResponse<ShareSportCouponResponse>> =>
    pureFetch<ShareSportCouponResponse>(
        `${SHOP_SHARE_SERVICE_URL}/sport-coupon/${couponId}`,
    );

export const saveBig9Coupon = async (coupon: Coupon) => {
    const res = await fetchAuthorized<ShareSportCouponResponse>(
        `${SHOP_SHARE_SERVICE_URL_TOKENIZED}/sport-coupon`,
        {
            method: "PUT",
            body: JSON.stringify({
                id: coupon.id,
                coupon,
                modified: coupon.modified,
            }),
        },
    );

    const {modified} = res.data;

    return {...coupon, modified};
};

export type AllCouponsResponse = {
    shares: ShopShare[];
    totalNumberOfHits: number;
};

export const fetchAllShares = ({shopId, gameId}: {shopId: string; gameId: string}) =>
    fetchAuthorized<AllCouponsResponse>(
        `${SHOP_SHARE_SERVICE_URL_TOKENIZED}/shops/${shopId}/shares?gameId=${gameId}&sort=relevance`,
    );
