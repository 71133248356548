import {OfferingStatus} from "./offeringTypes";

export const liveOfferingStatuses = [
    OfferingStatus.SELL_CLOSED,
    OfferingStatus.UNDER_REVIEW,
    OfferingStatus.REVOKING_GRADING,
    OfferingStatus.GRADING,
    OfferingStatus.GRADED,
    OfferingStatus.DECIDED,
];

export const preLiveOfferingStatuses = [OfferingStatus.SELL_OPEN];

export type CountryCode =
    | "BE"
    | "DE"
    | "DK"
    | "ES"
    | "FR"
    | "GB-ENG"
    | "GB-SCT"
    | "GR"
    | "IN-CL"
    | "IN-EL"
    | "IN-IN"
    | "IT"
    | "NL"
    | "NO"
    | "PT"
    | "RU"
    | "SE"
    | "TR"
    | "UA";
