import {get} from "lodash";
import type {GroupMap} from "@atg-sport-shared/sportsbook-types-group";
import type {KambiEvent} from "@atg-sport-shared/sportsbook-types-event";
import {getPathGroups} from "./basegroup.parser";

type Event = {
    event: KambiEvent;
};

type LeagueResponse = {
    events: Array<Event>;
    eventTypes: Array<"matches" | "competitions">;
    activeEventTypes: Array<"matches" | "competitions">;
    defaultEventType: "matches" | "competitions";
};

export const kambiLeaguesParser = (leagueData: LeagueResponse): GroupMap => {
    const {eventTypes, activeEventTypes, defaultEventType} = leagueData;

    const event = get(leagueData, "events[0].event");

    if (!event) {
        return {};
    }

    const groups = getPathGroups(event.path);
    const group = groups[event.path[event.path.length - 1].id];

    return {
        ...groups,
        [group.id]: {
            ...group,
            activeEventTypes: eventTypes,
            defaultEventType:
                activeEventTypes.indexOf(defaultEventType) !== -1
                    ? defaultEventType
                    : activeEventTypes[0],
        },
    };
};
