import type {Reducer, AnyAction} from "redux";
import * as _FetchReducer from "@atg-shared/fetch-redux";
import type {
    FormattedCouponData,
    RawCouponData,
    ReduxCoupon,
} from "@atg-sport-shared/big9-types/couponTypes";
import {CouponStatus, CouponType} from "@atg-sport-shared/big9-types/couponTypes";
import type {CouponsFetchState} from "@atg-sport-shared/big9-types";
import {
    type FetchCouponsAction,
    type SetStakeAction,
    type SetModifiedAction,
    type ReceiveCouponsAction,
    type SetHarryAction,
    type SetHarryStakeAction,
    type SetWildcardAction,
    type CreateCouponRequestAction,
    type SaveCouponRequest,
    type DeleteCouponAction,
    type CreateCouponAction,
    type ResetCouponsAction,
    type ValidateCouponFailedAction,
    type ClearCouponValidationAction,
    type FetchSharedCouponAction,
    type ReceiveSaveCouponAction,
    type ReceiveCreateCouponAction,
    type SetCouponAction,
    type SyncActiveCouponAction,
    SET_COUPON,
    REQUEST_COUPONS,
    RECEIVE_COUPONS,
    RESET_COUPONS,
    SET_STAKE,
    SET_MODIFIED,
    SET_HARRY,
    SET_HARRY_STAKE,
    SET_WILDCARD,
    DELETE_COUPON,
    RECEIVE_CREATE_COUPON,
    RECEIVE_SAVE_COUPON,
    CREATE_COUPON,
    VALIDATE_COUPON_FAILED,
    CLEAR_COUPON_VALIDATION,
    SYNC_ACTIVE_COUPON,
} from "@atg-sport-shared/big9-types";

const FetchReducer = _FetchReducer as any;
type CouponActions =
    | FetchCouponsAction
    | FetchSharedCouponAction
    | SetStakeAction
    | SetModifiedAction
    | SetHarryAction
    | SetHarryStakeAction
    | DeleteCouponAction
    | SetWildcardAction
    | CreateCouponAction
    | CreateCouponRequestAction
    | SaveCouponRequest
    | ResetCouponsAction
    | ClearCouponValidationAction
    | ValidateCouponFailedAction
    | ReceiveCreateCouponAction
    | ReceiveSaveCouponAction
    | SetCouponAction
    | SyncActiveCouponAction;

const initialState: CouponsFetchState = FetchReducer.createInitialState({
    data: {},
});

const formatCoupon = (coupon: RawCouponData): FormattedCouponData => ({
    ...coupon,
    outcomes: Object.keys(coupon.outcomes).map((key) => key),
    type: coupon.type || CouponType.SIMPLE,
});

const setCoupon = (
    state: CouponsFetchState,
    action: ReceiveCouponsAction | SetCouponAction,
) =>
    action.payload?.length
        ? {
              ...state,
              data: {
                  ...state.data,
                  ...action.payload.reduce(
                      (acc, current) =>
                          state.data?.[current.id]
                              ? acc
                              : {
                                    ...acc,
                                    [current.id]: {
                                        ...current,
                                        coupon: formatCoupon(current.coupon),
                                    },
                                },
                      {},
                  ),
              },
          }
        : state;

const couponsReducer: Reducer<CouponsFetchState> = FetchReducer.createFetchReducer(
    REQUEST_COUPONS,
    RECEIVE_COUPONS,
    "",
    (state: CouponsFetchState, action: ReceiveCouponsAction) =>
        !action.error && setCoupon(state, action),
    initialState,
);

const syncCoupon = (state: CouponsFetchState, {payload}: SyncActiveCouponAction) => ({
    ...state,
    data: {
        [payload.id]: {
            ...payload,
            coupon: formatCoupon(payload.coupon),
        },
    },
});

const setCouponMetadata = (
    state: CouponsFetchState,
    action: ReceiveCreateCouponAction | ReceiveSaveCouponAction,
) => {
    const {
        payload: {id, couponCreator, modified},
    } = action;
    return {
        ...state,
        data: state.data?.[id]
            ? {
                  ...state.data,
                  [id]: {
                      ...state.data[id],
                      couponCreator,
                      modified,
                  },
              }
            : state.data,
    };
};

const setStake = (state: CouponsFetchState, action: SetStakeAction) => {
    const {
        payload: {couponId, stake},
    } = action;
    return {
        ...state,
        data: state.data?.[couponId]
            ? {
                  ...state.data,
                  [couponId]: {
                      ...state.data[couponId],
                      coupon: {
                          ...state.data[couponId].coupon,
                          rowCost: stake,
                      },
                  },
              }
            : state.data,
    };
};

const setModified = (
    state: CouponsFetchState,
    action: SetModifiedAction,
): CouponsFetchState => {
    const {
        payload: {couponId, modified},
    } = action;
    return {
        ...state,
        data: state.data?.[couponId]
            ? {
                  ...state.data,
                  [couponId]: {
                      ...state.data[couponId],
                      modified,
                      coupon: {
                          ...state.data[couponId].coupon,
                          modified, // TODO -> Might be removed?
                      },
                  },
              }
            : state.data,
    };
};

// All bets with Harry Boy will be made with the rowCost = 50 öre
const updateHarryStake = (
    {coupon, ...metadata}: ReduxCoupon,
    newAmount: number,
): ReduxCoupon => ({
    ...metadata,
    coupon: {
        ...coupon,
        harryStake: newAmount,
        rowCost: 50,
    },
});

const setHarryStake = (
    state: CouponsFetchState,
    {payload: {couponId, setAmount}}: SetHarryAction | SetHarryStakeAction,
): CouponsFetchState =>
    state.data?.[couponId]
        ? {
              ...state,
              data: {
                  ...state.data,
                  [couponId]: updateHarryStake(state.data[couponId], setAmount),
              },
          }
        : state;

const deleteCoupon = (
    state: CouponsFetchState,
    {payload: {couponId}}: DeleteCouponAction,
): CouponsFetchState => {
    const {[couponId]: _, ...rest} = state.data || {};

    return state.data?.[couponId]
        ? {
              ...state,
              data: rest,
          }
        : state;
};

const setWildcard = (
    state: CouponsFetchState,
    {payload: {couponId, matchId}}: SetWildcardAction,
): CouponsFetchState =>
    state.data?.[couponId]
        ? {
              ...state,
              data: {
                  ...state.data,
                  [couponId]: {
                      ...state.data[couponId],
                      coupon: {
                          ...state.data[couponId].coupon,
                          wildcard:
                              `${couponId}-${matchId}` ===
                              state.data[couponId].coupon.wildcard
                                  ? null
                                  : `${couponId}-${matchId}`,
                      },
                  },
              },
          }
        : state;

const createCoupon = (
    state: CouponsFetchState,
    {payload: {coupon}}: CreateCouponAction,
): CouponsFetchState => ({...state, data: {...state.data, ...coupon}});

const validateCoupon = (
    state: CouponsFetchState,
    {payload: {couponId}}: ClearCouponValidationAction | ValidateCouponFailedAction,
    failed: boolean,
) => {
    const coupon = state.data && state.data[couponId];

    if (!coupon) return state;

    return {
        ...state,
        data: {
            ...state.data,
            [couponId]: {
                ...coupon,
                coupon: {
                    ...coupon.coupon,
                    status: failed ? CouponStatus.ValidationFailed : null,
                },
            },
        },
    };
};

export const coupons: Reducer<CouponsFetchState> = (
    state = initialState,
    _action: AnyAction,
) => {
    // Redux typescript issue, fixed in https://github.com/reduxjs/redux/pull/3679
    // TODO: Remove AnyAction type when Redux has been updated
    const action = _action as CouponActions;

    switch (action.type) {
        case SET_COUPON:
            return setCoupon(state, action);
        case SYNC_ACTIVE_COUPON:
            return syncCoupon(state, action);
        case RECEIVE_COUPONS:
            return couponsReducer(state, action);
        case RESET_COUPONS:
            return initialState;
        case CREATE_COUPON:
            return createCoupon(state, action);
        case RECEIVE_CREATE_COUPON:
        case RECEIVE_SAVE_COUPON:
            if (action.error) return state;
            return setCouponMetadata(state, action);
        case SET_STAKE:
            return setStake(state, action);
        case SET_MODIFIED:
            return setModified(state, action);
        case SET_HARRY:
        case SET_HARRY_STAKE:
            return setHarryStake(state, action);
        case DELETE_COUPON:
            return deleteCoupon(state, action);
        case SET_WILDCARD:
            return setWildcard(state, action);
        case CLEAR_COUPON_VALIDATION:
            return validateCoupon(state, action, false);
        case VALIDATE_COUPON_FAILED:
            return validateCoupon(state, action, true);
        default:
            return state;
    }
};
