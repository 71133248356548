import type {
    PrePackSelection,
    PrePacksState,
} from "@atg-sport-shared/sportsbook-types-pre-packs";
import {EventState} from "@atg-sport-shared/kambi-graphql";
import type {PrePacksActions} from "./prePacks.actions";
import {FETCH_PRE_PACKS_SUCCESS, FETCH_PRE_PACKS_ERROR} from "./prePacks.actions";

export const prePacksReducer = (
    state: PrePacksState = {data: {}, error: null},
    action: PrePacksActions,
) => {
    switch (action.type) {
        case FETCH_PRE_PACKS_SUCCESS: {
            const {betOffers, events, prePacks} = action.payload.data;

            const buildPrepacks = prePacks.reduce((acc, prePack) => {
                const prePackSelectionPackages =
                    prePack.prePackSelections[0].combinations[0].groups[0].groups;

                // Picking out the first event as we only have support for prepacks with one event
                const prePackEvent = events.find(
                    (event) =>
                        event.id === prePackSelectionPackages[0].outcomes[0].eventId,
                );

                const prePackSelections = prePackSelectionPackages.reduce(
                    (previousSelection, selectionPackage) => {
                        const {id: prepackId, betOfferId} = selectionPackage.outcomes[0];

                        const prePackBetoffer = betOffers.find(
                            (betOffer) => betOffer.id === betOfferId,
                        );

                        const selectionOutcome = prePackBetoffer?.outcomes.reduce(
                            (result, outcome) => {
                                if (outcome.id === prepackId) {
                                    if (
                                        "participant" in outcome &&
                                        outcome?.participant
                                    ) {
                                        return outcome?.participant.toString();
                                    }

                                    if ("line" in outcome && outcome.line) {
                                        return `${outcome.label} ${outcome.line / 1000}`;
                                    }

                                    return outcome.label.toString();
                                }
                                return result;
                            },
                            "",
                        );

                        const selectionCriterion = prePackBetoffer?.criterion.label;

                        const selectionOutcomeId =
                            prePackBetoffer?.outcomes.find(
                                (outcome) => outcome.id === prepackId,
                            )?.id || 0;

                        return [
                            ...previousSelection,
                            {
                                label: `${selectionOutcome} - ${selectionCriterion}`,
                                outcomeId: selectionOutcomeId,
                            },
                        ];
                    },
                    [] as PrePackSelection[],
                );

                const totalOdds = (
                    prePack.prePackSelections[0].combinations[0].odds.decimal / 1000
                ).toFixed(2);

                const hashPath = `#event/${prePackEvent?.id}/tab/partnerspecials`;

                const builtPrepack = {
                    eventId: prePackEvent?.id,
                    sport: prePackEvent?.sport,
                    name: prePackEvent?.name,
                    isLive: prePackEvent?.state === EventState.Started,
                    selections: prePackSelections,
                    totalOdds,
                    hashPath,
                };

                return {
                    ...acc,
                    [prePack.id]: builtPrepack,
                };
            }, {});

            return {
                ...state,
                data: buildPrepacks,
            };
        }
        case FETCH_PRE_PACKS_ERROR:
            return {...state, error: action.payload};
        default:
            return state;
    }
};
