import type {FetchAction} from "@atg-shared/fetch-types";
import {FETCH, call} from "@atg-shared/fetch-redux";
import * as api from "@atg-sport-shared/sportsbook-utils-kambi";
import type {SearchResponse} from "@atg-sport-shared/sportsbook-types-search";

export const REQUEST_SEARCH = "sport/REQUEST_SEARCH";
export const RECEIVE_SEARCH = "sport/RECEIVE_SEARCH";
export const RESET_SEARCH = "sport/RESET_SEARCH";
export const SET_SEARCH_TERM = "sport/SET_SEARCH_TERM";

type SearchAction = FetchAction<
    typeof REQUEST_SEARCH,
    typeof RECEIVE_SEARCH,
    SearchResponse
>;

type ResetSearchAction = {
    type: typeof RESET_SEARCH;
};

export const search = (term: string): SearchAction => ({
    type: FETCH,
    payload: {
        requestAction: REQUEST_SEARCH,
        receiveAction: RECEIVE_SEARCH,
        callApi: call(api.search, term),
    },
});

export const resetSearch = (): ResetSearchAction => ({
    type: RESET_SEARCH,
});

export type SetSearchTermAction = {
    type: typeof SET_SEARCH_TERM;
    term: string;
};

export const setSearchTerm = (term: string): SetSearchTermAction => ({
    type: SET_SEARCH_TERM,
    term,
});

export type SearchActions = SearchAction | ResetSearchAction | SetSearchTermAction;
