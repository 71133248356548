import type {
    ParsedPrematchGroups,
    ParsedLiveGroups,
    ParsedHighlightedGroups,
    GroupMap,
} from "@atg-sport-shared/sportsbook-types-group";

export const OFFERING_SET_GROUPS = "OFFERING_SET_GROUPS";
export const OFFERING_SET_LIVE_GROUPS = "OFFERING_SET_LIVE_GROUPS";
export const OFFERING_SET_LIST_VIEW_GROUPS = "OFFERING_SET_LIST_VIEW_GROUPS";
export const OFFERING_SET_HIGHLIGHTED_GROUPS = "OFFERING_SET_HIGHLIGHTED_GROUPS";

export type SetGroupsAction = {
    type: typeof OFFERING_SET_GROUPS;
    payload: ParsedPrematchGroups;
};

type SetLiveGroupsAction = {
    type: typeof OFFERING_SET_LIVE_GROUPS;
    payload: ParsedLiveGroups;
};

type SetHighlightedGroupsAction = {
    type: typeof OFFERING_SET_HIGHLIGHTED_GROUPS;
    payload: ParsedHighlightedGroups;
};

type SetListViewGroupsAction = {
    type: typeof OFFERING_SET_LIST_VIEW_GROUPS;
    payload: GroupMap;
};

export const setGroups = (groupData: ParsedPrematchGroups): SetGroupsAction => ({
    type: OFFERING_SET_GROUPS,
    payload: groupData,
});

export const setLiveGroups = (groupData: ParsedLiveGroups): SetLiveGroupsAction => ({
    type: OFFERING_SET_LIVE_GROUPS,
    payload: groupData,
});

export const setListViewGroups = (payload: GroupMap): SetListViewGroupsAction => ({
    type: OFFERING_SET_LIST_VIEW_GROUPS,
    payload,
});

export const setHighlightedGroups = (
    groupData: ParsedHighlightedGroups,
): SetHighlightedGroupsAction => ({
    type: OFFERING_SET_HIGHLIGHTED_GROUPS,
    payload: groupData,
});

export type GroupActions =
    | SetGroupsAction
    | SetLiveGroupsAction
    | SetListViewGroupsAction
    | SetHighlightedGroupsAction;
