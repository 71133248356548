import type {ParsedLiveGroups} from "@atg-sport-shared/sportsbook-types-group";
import type {KambiPath} from "@atg-sport-shared/sportsbook-types-event";
import {getPathGroups} from "./basegroup.parser";

type KambiEvent = {
    path: Array<KambiPath>;
};

type KambiEventsItem = {
    event: KambiEvent;
};

type KambiEvents = Array<KambiEventsItem>;

export const kambiLivePathParser = (events: KambiEvents): ParsedLiveGroups =>
    events.reduce((data, {event}) => {
        const {path} = event;
        const {id} = path[0];

        const newData = {
            ...getPathGroups(path, {hasLive: true, hasEvents: true}),
            ...data,
        } as ParsedLiveGroups;

        if (typeof newData[id].liveCount === "number") {
            // @ts-ignore : does not recognize check above
            newData[id].liveCount += 1;
        } else {
            newData[id].liveCount = 1;
        }

        return newData;
    }, {} as ParsedLiveGroups);
