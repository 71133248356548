import type {FetchReducer} from "@atg-shared/fetch-types";
import {createFetchReducer} from "@atg-shared/fetch-redux";
import type {ShortInfoResponse} from "@atg-sport-shared/sportsbook-types-short-info";
import {
    type FetchShortInfoAction,
    GET_SHORTINFO,
    SET_SHORTINFO,
} from "./short.info.actions";

export const shortInfoReducer: FetchReducer<
    {data: ShortInfoResponse},
    FetchShortInfoAction
> = createFetchReducer(
    GET_SHORTINFO,
    SET_SHORTINFO,
    "",
    (state: {data: ShortInfoResponse}, action) =>
        action.type === SET_SHORTINFO && !action.error
            ? {
                  data: action.payload,
              }
            : state,
    {data: []},
);
