import type {MatchStatus} from "./matchTypes";

export enum SettingId {
    Odds = "odds",
    BetDistribution = "betDistribution",
    MatchComment = "comment",
    ShouldHighlighWrongSelelctions = "shouldHighlightWrongSelections",
}

export type Setting = {
    id: SettingId;
    value: boolean;
    label: string;
    order: number;
    requiresAuth: boolean;
};

export type CouponSettings = {
    [SettingId.Odds]: Setting;
    [SettingId.BetDistribution]: Setting;
    [SettingId.MatchComment]: Setting;
};

export type OngoingMatchSettings = {
    [SettingId.BetDistribution]: Setting;
    [SettingId.ShouldHighlighWrongSelelctions]: Setting;
};

export type SortOption =
    | MatchStatus.DECIDED
    | MatchStatus.ONGOING
    | MatchStatus.UPCOMING
    | "number"
    | "startTime";

export type SortSetting = {
    id: SortOption;
    label: string;
};

export type SortSettings = {
    selectedSortId: SortOption;
    options: SortSetting[];
};

export type SettingsState = {
    coupon: CouponSettings;
    ongoingMatch: OngoingMatchSettings;
    sortingSettings: SortSettings;
};
