import type {Effect} from "redux-saga/effects";
import {call, select, takeLatest} from "redux-saga/effects";
import * as storage from "@atg-shared/storage";
import {SET_COUPON_SETTINGS, SET_ONGOING_MATCH_SETTINGS} from "./settings.actions";
import {getCouponSettings, getOngoingMatchSettings} from "./settings.selectors";

export const PRE_MATCH_SETTINGS_KEY = "big9PreMatchSettings";
export const ONGOING_MATCH_SETTINGS_KEY = "big9OngoingMatchSettings";

export function* saveCouponSettings(): Generator<Effect, void, void> {
    const couponSettings = yield select(getCouponSettings);
    // @ts-ignore
    yield call(storage.putObject, PRE_MATCH_SETTINGS_KEY, couponSettings);
}

export function* saveOngoingMatchSettings(): Generator<Effect, void, void> {
    const ongoingMatchSettings = yield select(getOngoingMatchSettings);
    // @ts-ignore
    yield call(storage.putObject, ONGOING_MATCH_SETTINGS_KEY, ongoingMatchSettings);
}

export function* startlistSettingsSaga(): Generator<Effect, void, void> {
    yield takeLatest([SET_COUPON_SETTINGS], saveCouponSettings);
    yield takeLatest([SET_ONGOING_MATCH_SETTINGS], saveOngoingMatchSettings);
}
