import {createFetchReducer} from "@atg-shared/fetch-redux";
import type {
    SearchItem,
    SearchResponseItem,
    SearchState,
} from "@atg-sport-shared/sportsbook-types-search";
import {
    type SearchActions,
    REQUEST_SEARCH,
    RECEIVE_SEARCH,
    RESET_SEARCH,
    SET_SEARCH_TERM,
} from "./search.actions";

const initialState: SearchState = {
    term: "",
    items: [],
};

const transformResponseItems = (
    hits: Array<string>,
    list: Array<SearchResponseItem>,
): Array<SearchItem> =>
    hits.reduce((acc, id) => {
        const item = list.find((searchItem) => searchItem.id === id);

        if (!item) {
            return acc;
        }

        return [
            ...acc,
            {
                type: item.type,
                path: `filter${item.id}`,
                name: item.localizedName,
                parentPath: `filter${item.parentId}`.replace(/(\/all)+$/g, ""),
            },
        ];
    }, [] as SearchItem[]);

export const searchReducer = createFetchReducer(
    REQUEST_SEARCH,
    RECEIVE_SEARCH,
    RESET_SEARCH,
    (state: SearchState, action: SearchActions) => {
        switch (action.type) {
            case SET_SEARCH_TERM:
                return {...state, term: action.term};
            case RESET_SEARCH:
                return {...state, items: []};
            case RECEIVE_SEARCH:
                return action.error
                    ? state
                    : {
                          ...state,
                          items: transformResponseItems(
                              action.payload.searchHitsId,
                              action.payload.resultTerms,
                          ),
                      };

            default:
                return state;
        }
    },
    initialState,
);
