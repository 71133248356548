import type {CouponType, Selection} from "@atg-sport-shared/big9-types/couponTypes";
import type {BetSelectionWithoutWildcard} from "@atg-sport-shared/big9-types/selectionTypes";
import {
    AdvancedBetSelection,
    BetSelection,
} from "@atg-sport-shared/big9-types/selectionTypes";
import type {
    AdvancedSelectionV2,
    AdvancedSelectionWithoutWildcard,
    OutcomeSimpleSelection,
} from "@atg-sport-shared/big9-types/outcomeTypes";
import type {Match, MatchScore} from "@atg-sport-shared/big9-types/matchTypes";
import {MatchStatus} from "@atg-sport-shared/big9-types/matchTypes";
import type {
    Offering,
    OfferingMatch,
    OfferingWithMatchRefs,
} from "@atg-sport-shared/big9-types/offeringTypes";
import {OfferingStatus} from "@atg-sport-shared/big9-types/offeringTypes";
import type {
    AdvancedSelections,
    BetCouponMatch,
    Receipt,
    Selections,
} from "@atg-sport-shared/big9-types/betTypes";
import {
    BetMode,
    BetChannel,
    BetStatus,
    BetSummary,
} from "@atg-sport-shared/big9-types/betTypes";
import dayjs from "dayjs";

export const availableBetSelections: ReadonlyArray<BetSelectionWithoutWildcard> = [
    BetSelection.Home,
    BetSelection.Tie,
    BetSelection.Away,
    BetSelection.Over,
    BetSelection.Under,
];

type BetTeamInfo = {
    [key: string]: {team: string; receiptCounter: string; selections: Selections};
};

type WinnerRightNow = {
    score: MatchScore | undefined;
    overUnderLimit: Offering["overUnderLimit"];
};

export const getWinnerRightNow = ({score, overUnderLimit}: WinnerRightNow) => {
    if (!score) return null;

    const isHomeWin = score.home > score.away;
    const isTie = score.away === score.home;
    const isAwayWin = score.away > score.home;
    const isOver = score.home + score.away > overUnderLimit;
    const isUnder = !isOver;

    return {
        home: isHomeWin,
        tie: isTie,
        away: isAwayWin,
        over: isOver,
        under: isUnder,
    };
};

type SimpleOrAdvancedUserSelectionsProps = {
    matches?: ReadonlyArray<BetCouponMatch>;
    betMode?: BetMode;
};

export const getSimpleOrAdvancedUserSelections = ({
    matches,
    betMode = BetMode.Simple,
}: SimpleOrAdvancedUserSelectionsProps): Selections | AdvancedSelections | null => {
    const isAdvanced = betMode === BetMode.Advanced;

    if (!matches) return null;

    return matches.reduce(
        (acc, curr) => ({
            ...acc,
            [curr.number]: isAdvanced ? curr.advancedSelections : curr.selections,
        }),
        {} as Selections | AdvancedSelections,
    );
};

export const getWinnerRightNowAdvancedMode = ({
    score,
    overUnderLimit,
}: WinnerRightNow): Selection[CouponType.ADVANCED] | null => {
    const winner = getWinnerRightNow({score, overUnderLimit});

    if (!winner || !score) return null;

    const {home, tie, away, over, under} = winner;

    return {
        homeOver: home && over,
        homeUnder: home && under,
        tieOver: tie && over,
        tieUnder: tie && under,
        awayOver: away && over,
        awayUnder: away && under,
    };
};

export const getIsSelectionCorrect = ({
    userSelection,
    betSelection,
    score,
    overUnderLimit,
}: {
    userSelection: OutcomeSimpleSelection | null;
    betSelection: BetSelection;
    score: MatchScore | undefined;
    overUnderLimit: Offering["overUnderLimit"];
}): boolean => {
    const winner = getWinnerRightNow({score, overUnderLimit});

    const {home, tie, away, over, under, wildcard} =
        userSelection || ({} as OutcomeSimpleSelection);

    // When the match has not started and we got no score (null),
    // then tie and under are treated as win.
    if (winner === null) {
        return (
            (Boolean(tie || wildcard) && betSelection === BetSelection.Tie) ||
            (Boolean(under || wildcard) && betSelection === BetSelection.Under)
        );
    }

    if ((home || wildcard) && betSelection === BetSelection.Home) {
        return winner.home;
    }

    if ((tie || wildcard) && betSelection === BetSelection.Tie) {
        return winner.tie;
    }

    if ((away || wildcard) && betSelection === BetSelection.Away) {
        return winner.away;
    }

    if ((over || wildcard) && betSelection === BetSelection.Over) {
        return winner.over;
    }

    if ((under || wildcard) && betSelection === BetSelection.Under) {
        return winner.under;
    }

    return false;
};

const isAdvancedSelection = (
    selection: AdvancedSelectionV2 | OutcomeSimpleSelection,
): selection is AdvancedSelectionV2 =>
    (selection as AdvancedSelectionV2).homeOver !== undefined ||
    (selection as AdvancedSelectionV2).homeUnder !== undefined ||
    (selection as AdvancedSelectionV2).tieOver !== undefined ||
    (selection as AdvancedSelectionV2).tieUnder !== undefined ||
    (selection as AdvancedSelectionV2).awayOver !== undefined ||
    (selection as AdvancedSelectionV2).awayUnder !== undefined;

const getIsAdvancedSelectionCorrect = ({
    userSelection,
    overUnderLimit,
    score,
}: {
    userSelection: AdvancedSelectionV2;
    score: MatchScore | undefined;
    overUnderLimit: Offering["overUnderLimit"];
}): boolean => {
    if (userSelection.wildcard) return true;

    const winner = getWinnerRightNowAdvancedMode({score, overUnderLimit});

    if (!winner) return false;

    const {homeOver, homeUnder, tieOver, tieUnder, awayOver, awayUnder} = winner;

    if (homeOver && userSelection.homeOver) return true;
    if (homeUnder && userSelection.homeUnder) return true;
    if (tieOver && userSelection.tieOver) return true;
    if (tieUnder && userSelection.tieUnder) return true;
    if (awayOver && userSelection.awayOver) return true;
    if (awayUnder && userSelection.awayUnder) return true;

    return false;
};

export const getIsMatchCorrect = ({
    userSelection = null,
    score,
    overUnderLimit,
    status,
}: {
    userSelection: AdvancedSelectionV2 | OutcomeSimpleSelection | null;
    score: MatchScore | undefined;
    overUnderLimit: Offering["overUnderLimit"];
    status?: MatchStatus;
}): boolean => {
    if (status === MatchStatus.CANCELED) {
        return true;
    }

    if (!userSelection) return false;

    if (userSelection.wildcard) return true;

    if (isAdvancedSelection(userSelection)) {
        return getIsAdvancedSelectionCorrect({
            userSelection,
            overUnderLimit,
            score,
        });
    }
    const correctSelections = availableBetSelections.reduce(
        (prev, betSelection) => ({
            ...prev,
            [betSelection]: getIsSelectionCorrect({
                userSelection,
                betSelection,
                overUnderLimit,
                score,
            }),
        }),
        {} as {[BS in BetSelectionWithoutWildcard]: boolean},
    );

    return (
        (correctSelections[BetSelection.Home] ||
            correctSelections[BetSelection.Tie] ||
            correctSelections[BetSelection.Away]) &&
        (correctSelections[BetSelection.Over] || correctSelections[BetSelection.Under])
    );
};

export const getIsWin = (numberOfWins: number) => numberOfWins > 6;

export const getNumberOfWins = ({
    matches,
    userBetSelections,
}: {
    matches: ReadonlyArray<OfferingMatch | (Match & {overUnderLimit: number})>;
    userBetSelections: Selections | AdvancedSelections | null;
}) =>
    matches.filter(({overUnderLimit, score, number, status}) =>
        getIsMatchCorrect({
            overUnderLimit,
            score,
            userSelection: userBetSelections && userBetSelections[number],
            status,
        }),
    )?.length;

const getNumberOfSelectionsInOutcome = (
    selection: OutcomeSimpleSelection | AdvancedSelectionV2 | null,
) => {
    if (!selection) return 0;
    if (selection.wildcard || selection.canceled) return 1;

    if (isAdvancedSelection(selection)) {
        return Object.values(selection).filter((bet) => bet === true).length;
    }

    const {home, tie, away, over, under} = selection;

    return (
        Object.values({home, tie, away}).filter((bet) => bet === true).length *
        Object.values({over, under}).filter((bet) => bet === true).length
    );
};

const getNumberOfCorrectSelections = (
    overUnderLimit: number,
    match: OfferingMatch | Match,
    userSelection: OutcomeSimpleSelection | AdvancedSelectionV2 | null,
    numberOfSelections: number,
) => {
    const matchCancelled = match.status === MatchStatus.CANCELED;
    const isMatchCorrect = getIsMatchCorrect({
        overUnderLimit,
        score: match.score,
        userSelection,
        status: match.status,
    });

    if (userSelection?.wildcard) return 1;
    if (matchCancelled) return numberOfSelections;
    return isMatchCorrect ? 1 : 0;
};

export const getCurrentCouponPayout = ({
    matches,
    userBetSelections,
    offering,
    stakePerCombination,
}: {
    matches: ReadonlyArray<OfferingMatch | Match>;
    userBetSelections: Selections | AdvancedSelections | null;
    offering: OfferingWithMatchRefs | null;
    stakePerCombination?: number | null;
}) => {
    const {
        overUnderLimit,
        pool: {payouts, unitStake},
    } = offering || {pool: {}};

    if (overUnderLimit && payouts && unitStake && stakePerCombination) {
        const payoutMultipliers = matches.reduce(
            (accumulator, currentMatch) => {
                const numberOfSelections = getNumberOfSelectionsInOutcome(
                    userBetSelections && userBetSelections[currentMatch.number],
                );

                const numberOfCorrectSelections = getNumberOfCorrectSelections(
                    overUnderLimit,
                    currentMatch,
                    userBetSelections && userBetSelections[currentMatch.number],
                    numberOfSelections,
                );

                const numberOfIncorrectSelections =
                    currentMatch.status !== MatchStatus.CANCELED
                        ? numberOfSelections - numberOfCorrectSelections
                        : 0;

                return {
                    nine: accumulator.nine * numberOfCorrectSelections,
                    eight:
                        accumulator.eight * numberOfCorrectSelections +
                        accumulator.nine * numberOfIncorrectSelections,
                    seven:
                        accumulator.seven * numberOfCorrectSelections +
                        accumulator.eight * numberOfIncorrectSelections,
                };
            },
            {seven: 0, eight: 0, nine: 1},
        );

        // TODO: Revisit and investigate if we need this at all?
        if (
            (!payouts[7].unitDividend && !payouts[7].jackpot) ||
            (!payouts[8].unitDividend && !payouts[8].jackpot) ||
            (!payouts[9].unitDividend && !payouts[9].jackpot)
        ) {
            return 0;
        }

        const stakeMultiplier = stakePerCombination / unitStake;

        return (
            ((payouts[7].unitDividend || 0) * payoutMultipliers.seven +
                (payouts[8].unitDividend || 0) * payoutMultipliers.eight +
                (payouts[9].unitDividend || 0) * payoutMultipliers.nine) *
            stakeMultiplier
        );
    }

    return 0;
};

export const channelToReadableText = (
    channel?: BetChannel,
    summary?: BetSummary.FILE_BET | BetSummary.SHOP,
): string | null => {
    const channelMap = {
        [BetChannel.APP_ANDROID]: "ATG mobilapp",
        [BetChannel.APP_IOS]: "ATG mobilapp",
        [BetChannel.WEB_ATGSE]: "Internet",
        [BetChannel.WEB_TILLSAMMANS]: "Tillsammans",
        [BetChannel.UNKNOWN]: "Okänd",
        [BetChannel.RETAIL_TERMINAL]: "Butik",
        [BetChannel.AGENT_TERMINAL]: "Butik",
    };

    const summaryMap = {
        [BetSummary.FILE_BET]: "Reducerat spel",
        [BetSummary.SHOP]: "Ombudslag på internet",
    };

    if (summary) return summaryMap[summary];
    if (channel) return channelMap[channel];

    return channelMap.UNKNOWN;
};

export const getFirstBetId = (receipt: Receipt) =>
    receipt.shares?.sharedBetId ?? receipt.bets[0]?.tsn;

export const offeringStatusMap = {
    [OfferingStatus.UPCOMING]: "Kommande",
    [OfferingStatus.SELL_OPEN]: "Kommande",
    [OfferingStatus.SELL_CLOSED]: "Pågår",
    [OfferingStatus.DECIDED]: "Pågår",
    [OfferingStatus.GRADING]: "Pågår",
    [OfferingStatus.GRADED]: "Pågår",
    [OfferingStatus.UNDER_REVIEW]: "Pågår",
    [OfferingStatus.REVOKING_GRADING]: "Pågår",
    [OfferingStatus.CANCELED]: "Inställd",
    [OfferingStatus.FINALIZED]: "Avgjord",
};

const getBetSummary = (summary: Receipt["summary"]) => {
    if (summary.shop) return BetSummary.SHOP;
    if (summary.filebet) return BetSummary.FILE_BET;
    return undefined;
};

export const formatReceiptWithOffering = ({
    receipt,
    receiptId,
    offering,
}: {
    receipt: Receipt;
    receiptId: string;
    offering: Offering;
}) => {
    const placed = receipt.shares?.boughtAt ?? receipt.bets[0]?.placedAt;
    const status = offeringStatusMap[offering.status];

    const isTillsammans = receipt.summary.tillsammans;

    const betChannel = isTillsammans
        ? BetChannel.WEB_TILLSAMMANS
        : receipt.bets[0]?.channel;

    const betSummary = getBetSummary(receipt.summary);

    const channel = channelToReadableText(betChannel, betSummary) || "Okänd";

    const matchdate = offering.startTime;

    const cost = receipt.shares?.cost.my ?? receipt.bets[0]?.cost;

    const payout = receipt.shares?.winnings?.my ?? receipt.bets[0]?.payout;

    return {
        receipt,
        receiptId,
        offering,
        placed,
        status,
        channel,
        matchdate,
        cost,
        payout,
    };
};

export const revealDecidedMatches = (
    matches: ReadonlyArray<Match>,
    revealedRows: ReadonlyArray<boolean> = new Array(9).fill(false),
) =>
    revealedRows.map(
        (v, i) =>
            [MatchStatus.DECIDED, MatchStatus.CANCELED].includes(matches[i].status) || v,
    );

export const getRevealedRowsSummary = (
    matches: ReadonlyArray<Match>,
    revealedRows: ReadonlyArray<boolean> = new Array(9).fill(false),
) => ({
    done: revealedRows.every((v, i) => v || matches[i].status === MatchStatus.CANCELED),
    canReveal: revealedRows.some(
        (v, i) => !v && matches[i].status === MatchStatus.DECIDED,
    ),
    started: revealedRows.some((v, i) => v && matches[i].status !== MatchStatus.CANCELED),
});

export const getRowsCount = (
    allSelections: ReadonlyArray<OutcomeSimpleSelection | AdvancedSelectionV2>,
) =>
    allSelections.map((selection) => {
        if (selection.wildcard || selection.canceled) return 1;

        if (isAdvancedSelection(selection)) {
            const {homeOver, homeUnder, tieOver, tieUnder, awayOver, awayUnder} =
                selection;
            return (
                (homeOver ? 1 : 0) +
                (homeUnder ? 1 : 0) +
                (tieOver ? 1 : 0) +
                (tieUnder ? 1 : 0) +
                (awayOver ? 1 : 0) +
                (awayUnder ? 1 : 0)
            );
        }

        const {home, tie, away, over, under} = selection;

        return (
            ((home ? 1 : 0) + (tie ? 1 : 0) + (away ? 1 : 0)) *
            ((over ? 1 : 0) + (under ? 1 : 0))
        );
    });

export const getRowsTotal = (rowsCount: ReadonlyArray<number>) =>
    rowsCount.reduce((acc, row) => acc * row, 1);

export const filterReceiptWithoutCancelledBets = (receipt: Receipt) =>
    receipt.bets.some(
        (bet) =>
            !(bet.status === BetStatus.Canceled || bet.status === BetStatus.Refunded),
    );

export const getReceiptsBetInfo = (receipts: Receipt[]) =>
    Object.assign(
        {},
        ...receipts
            .map((receipt) =>
                receipt.bets.map((bet, index) => ({
                    [bet.tsn]: {
                        team:
                            receipt.shares?.name ||
                            receipt.shares?.shopInfo.name ||
                            channelToReadableText(bet?.channel),
                        receiptCounter:
                            receipt.bets.length > 1
                                ? ` ${index + 1}/${receipt.bets.length}`
                                : "",
                        selections:
                            bet.coupons[0].matches?.reduce(
                                (prev, {number, selections}) => ({
                                    ...prev,
                                    [number]: selections,
                                }),
                                {} as Selections,
                            ) ?? null,
                    },
                })),
            )
            .flat(),
    ) as BetTeamInfo;

export const getReceiptShareRefund = (receipt: Receipt) =>
    (receipt?.shares?.sharePrice ?? 0) * (receipt?.shares?.nrOfShares?.my ?? 0);

export const getTotalReceiptCost = (receipt: Receipt) => {
    const bet = receipt?.bets[0] ?? {};

    return receipt.summary.shop || receipt.summary.tillsammans
        ? receipt.shares?.cost?.my || getReceiptShareRefund(receipt)
        : bet.cost;
};

export const getPayoutTotal = (receipt: Receipt) => {
    const isTogetherGame = receipt.summary.tillsammans || receipt.summary.shop;
    const isMultipeTogetherGame = receipt.summary.tillsammans && receipt.bets.length > 1;
    const bet = receipt?.bets[0] ?? {};

    if (isTogetherGame || isMultipeTogetherGame) {
        return receipt.shares?.winnings?.my ?? 0;
    }

    return bet.payout || 0;
};

export const calculateCorrectAdvancedSelections = (
    selections: AdvancedSelectionV2,
    score: MatchScore | undefined,
    overUnderLimit: number,
): AdvancedSelectionWithoutWildcard => {
    if (!score)
        return {
            [AdvancedBetSelection.HomeOver]: false,
            [AdvancedBetSelection.HomeUnder]: false,
            [AdvancedBetSelection.TieOver]: false,
            [AdvancedBetSelection.TieUnder]: false,
            [AdvancedBetSelection.AwayOver]: false,
            [AdvancedBetSelection.AwayUnder]: false,
        };

    const {home, away} = score;

    const totalScore = away + home;

    return {
        [AdvancedBetSelection.HomeOver]: Boolean(
            selections?.homeOver && home > away && totalScore > overUnderLimit,
        ),
        [AdvancedBetSelection.HomeUnder]: Boolean(
            selections?.homeUnder && home > away && totalScore < overUnderLimit,
        ),
        [AdvancedBetSelection.TieOver]: Boolean(
            selections?.tieOver && home === away && totalScore > overUnderLimit,
        ),
        [AdvancedBetSelection.TieUnder]: Boolean(
            selections?.tieUnder && home === away && totalScore < overUnderLimit,
        ),
        [AdvancedBetSelection.AwayOver]: Boolean(
            selections?.awayOver && home < away && totalScore > overUnderLimit,
        ),
        [AdvancedBetSelection.AwayUnder]: Boolean(
            selections?.awayUnder && home < away && totalScore < overUnderLimit,
        ),
    };
};

export const wasCanceledBeforeBetPlaced = (
    isMatchCanceled: boolean,
    canceledAt: string | undefined,
    placedAt: string | undefined,
    selections: AdvancedSelectionV2,
) => {
    if (!isMatchCanceled) return undefined;

    if (!canceledAt || !placedAt) return undefined;

    const hasTieUnderSelection = selections[AdvancedBetSelection.TieUnder];

    const hasOtherSelections = Object.entries(selections).some(
        ([key, value]) => key !== AdvancedBetSelection.TieUnder && value,
    );

    const hasOnlyTieUnderSelection = hasTieUnderSelection && !hasOtherSelections;

    return dayjs(canceledAt).isBefore(dayjs(placedAt)) && hasOnlyTieUnderSelection;
};

export const emptyCanceledBetSimpleSelections = {
    [BetSelection.Home]: false,
    [BetSelection.Tie]: false,
    [BetSelection.Away]: false,
    [BetSelection.Over]: false,
    [BetSelection.Under]: false,
    [BetSelection.Wildcard]: false,
};

export const emptyCanceledBetAdvancedSelections = {
    [AdvancedBetSelection.HomeOver]: false,
    [AdvancedBetSelection.HomeUnder]: false,
    [AdvancedBetSelection.TieOver]: false,
    [AdvancedBetSelection.TieUnder]: false,
    [AdvancedBetSelection.AwayOver]: false,
    [AdvancedBetSelection.AwayUnder]: false,
    [AdvancedBetSelection.Wildcard]: false,
};
