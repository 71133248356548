import {combineReducers} from "redux";
import type {AnyAction} from "redux";
import type {Big9State} from "@atg-sport-shared/big9-types";
import {offeringsReducer} from "@atg-sport-shared/pool-data-access-offering";
import {matchesReducer} from "@atg-sport-shared/big9-data-access-matches";
import {betsReducer} from "@atg-sport-shared/big9-data-access-bets";
import {settingsReducer} from "@atg-sport-shared/big9-data-access-settings";
import {outcomes} from "@atg-sport-shared/big9-data-access-outcomes";
import {statisticsReducer} from "@atg-sport-shared/big9-data-access-statistics";
import {coupons} from "@atg-sport-shared/big9-data-access-coupons";
import {purchaseReducer} from "@atg-sport-shared/big9-data-access-purchase";
// Wait for package to be moved to @sport-shop-shared
// eslint-disable-next-line @nx/enforce-module-boundaries
import shareSportCouponReducer from "@atg-shop-shared/share-coupon-redux/shareSportCouponReducer";
import {matchClockReducer} from "@atg-sport-shared/big9-data-access-match-clock";

// @ts-ignore
const combinedReducers = combineReducers<Big9State>({
    coupons,
    outcomes,
    offerings: offeringsReducer,
    settings: settingsReducer,
    matches: matchesReducer,
    matchClocks: matchClockReducer,
    statistics: statisticsReducer,
    bets: betsReducer,
    // @ts-ignore
    purchase: purchaseReducer,
    shareSportCoupon: shareSportCouponReducer,
});

export const rootReducer = (state: Big9State, action: AnyAction) =>
    combinedReducers(state, action);
