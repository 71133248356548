import {
    type KambiErrorsState,
    ErrorTypes,
} from "@atg-sport-shared/sportsbook-types-kambi";
import {
    type KambiBetFailureErrorAction,
    type KambiResetErrorsAction,
    KAMBI_BET_FAILURE_ERROR,
    KAMBI_RESET_ERRORS,
} from "../actions/kambi.errors.actions";

const initialState: KambiErrorsState = {hasError: false, errorTypes: []};

type KambiErrorsActions = KambiBetFailureErrorAction | KambiResetErrorsAction;

export const kambiErrorsReducer = (
    state: KambiErrorsState = initialState,
    action: KambiErrorsActions,
) => {
    switch (action.type) {
        case KAMBI_BET_FAILURE_ERROR:
            return {
                ...state,
                hasError: true,
                errorTypes: [...state.errorTypes, ErrorTypes.BetFailure],
                betFailureReason: action.payload.reason,
            };
        case KAMBI_RESET_ERRORS:
            return initialState;
        default:
            return state;
    }
};
