import {
    type KambiState,
    ODDS_FORMAT_DECIMAL,
} from "@atg-sport-shared/sportsbook-types-kambi";
import {
    type HighlightsSuccessAction,
    OFFERING_HIGHLIGHTS_SUCCESS,
} from "@atg-sport-shared/sportsbook-types-offering";
import {MemberActions} from "@atg-global-shared/member-data-access";
import {
    type KambiActions,
    SB_KAMBI_LOADED,
    SB_SET_KAMBI_AUTH,
    SB_SET_KAMBI_LOGIN_FAILED,
    SB_KAMBI_UPDATE_ODDS_FORMAT,
    SB_SET_BET_COST,
    SB_RESET_KAMBI_AUTH,
    SB_LOGIN_PENDING,
} from "../actions/kambi.actions";

const initialState: KambiState = {
    isLoggedIn: false,
    loginFailedReason: undefined,
    kambiLoaded: false,
    isHighlightsLoaded: false,
    oddsFormat: ODDS_FORMAT_DECIMAL,
    isLoginPending: false,
    betCost: 0,
};

type Actions =
    | KambiActions
    | HighlightsSuccessAction
    | MemberActions.FinishMemberFlowAction;

export const kambiReducer = (
    state: KambiState = initialState,
    action: Actions,
): KambiState => {
    switch (action.type) {
        case SB_KAMBI_LOADED:
            if (!action.loaded) {
                return {
                    ...initialState,
                    isHighlightsLoaded: state.isHighlightsLoaded,
                };
            }

            return {
                ...state,
                kambiLoaded: action.loaded,
            };

        case SB_SET_KAMBI_AUTH:
            return {
                ...state,
                isLoggedIn: action.isLoggedIn,
                loginFailedReason: undefined,
                isLoginPending: false,
            };

        case SB_LOGIN_PENDING:
            return {
                ...state,
                isLoginPending: true,
            };

        case SB_SET_KAMBI_LOGIN_FAILED:
            return {
                ...state,
                loginFailedReason: action.loginFailedReason,
                isLoginPending: false,
            };

        case SB_RESET_KAMBI_AUTH:
            return {
                ...state,
                isLoggedIn: false,
                loginFailedReason: undefined,
            };

        case MemberActions.FINISH_MEMBER_FLOW:
            return {
                ...state,
                loginFailedReason: undefined,
            };

        case SB_KAMBI_UPDATE_ODDS_FORMAT:
            return {
                ...state,
                oddsFormat: action.oddsFormat,
            };

        case OFFERING_HIGHLIGHTS_SUCCESS:
            return {
                ...state,
                isHighlightsLoaded: true,
            };
        case SB_SET_BET_COST:
            return {
                ...state,
                betCost: action.betCost,
            };
        default:
            return state;
    }
};
