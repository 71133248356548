import type {BetOffer, BetOfferState} from "@atg-sport-shared/sportsbook-types-bet-offer";
import {
    type BetofferActions,
    SET_BET_OFFERS,
    SET_WAPI_BET_OFFERS,
} from "./bet.offer.actions";

const setBetOffers = (state: BetOfferState, betOffers: Array<BetOffer>): BetOfferState =>
    betOffers.reduce(
        (acc, betOffer) => ({
            ...acc,
            [betOffer.id]: {
                ...acc[`${betOffer.id}`],
                ...betOffer,
            },
        }),
        state,
    );

const setWapiBetOffers = (
    state: BetOfferState,
    betOffers: Array<BetOffer>,
): BetOfferState =>
    betOffers.reduce(
        (acc, betOffer) => ({
            ...acc,
            [betOffer.id]: {
                ...acc[`${betOffer.id}`],
                ...betOffer,
                tags: betOffer.tags ? betOffer.tags : acc[`${betOffer.id}`].tags,
            },
        }),
        state,
    );

export const betOfferReducer = (state: BetOfferState = {}, action: BetofferActions) => {
    switch (action.type) {
        case SET_BET_OFFERS:
            return setBetOffers(state, action.payload);

        case SET_WAPI_BET_OFFERS:
            return setWapiBetOffers(state, action.betOffers);

        default:
            return state;
    }
};

export default betOfferReducer;
