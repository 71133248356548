import {values} from "lodash";
import {combinePopularSportsUrl} from "@atg-sport-shared/sportsbook-utils-helpers";
import {
    type GroupActions,
    OFFERING_SET_HIGHLIGHTED_GROUPS,
} from "@atg-sport-shared/sportsbook-data-access-group";
import type {CombinedGroup} from "@atg-sport-shared/sportsbook-types-group";
import type {PopularState} from "@atg-sport-shared/sportsbook-types-popular";

export const popularReducer = (state: PopularState = {}, action: GroupActions) => {
    switch (action.type) {
        case OFFERING_SET_HIGHLIGHTED_GROUPS:
            return {
                ...state,
                popularUrl: combinePopularSportsUrl(
                    values(action.payload) as Array<CombinedGroup>,
                ),
            };
        default:
            return state;
    }
};
