import type {FetchAction} from "@atg-shared/fetch-types";
import {FETCH, call} from "@atg-shared/fetch-redux";
import * as api from "@atg-sport-shared/sportsbook-utils-cms-api";
import type {GlobalState} from "@atg-sport-shared/sportsbook-types-root";
import type {NonRegionalSportsType} from "@atg-sport-shared/sportsbook-types-non-regional-sport";

export const GET_NON_REGIONAL_SPORTS = "GET_NON_REGIONAL_SPORTS";
export const SET_NON_REGIONAL_SPORTS = "SET_NON_REGIONAL_SPORTS";

export type GetNonRegionalSports = FetchAction<
    typeof GET_NON_REGIONAL_SPORTS,
    typeof SET_NON_REGIONAL_SPORTS,
    NonRegionalSportsType,
    GlobalState
>;

export const getNonRegionalSports = (): GetNonRegionalSports => ({
    type: FETCH,
    payload: {
        requestAction: GET_NON_REGIONAL_SPORTS,
        receiveAction: SET_NON_REGIONAL_SPORTS,
        callApi: call(api.fetchNonRegionalSports),
    },
});
