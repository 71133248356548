import type {BetOffer} from "@atg-sport-shared/sportsbook-types-bet-offer";
import type {BetOffer as KambiBetOffer} from "@atg-sport-shared/sportsbook-types-event";

export const SET_BET_OFFERS = "sport/SET_BET_OFFERS";
export const SET_WAPI_BET_OFFERS = "sport/SET_WAPI_BET_OFFERS";
export const WAPI_BET_OFFERS_RECEIVED = "sport/WAPI_BET_OFFERS_RECEIVED";

export type SetBetOffersAction = {
    type: typeof SET_BET_OFFERS;
    payload: Array<BetOffer>;
};

export type SetWapiBetOffersAction = {
    type: typeof SET_WAPI_BET_OFFERS;
    betOffers: Array<BetOffer>;
};

export type WapiBetOffersReceivedAction = {
    type: typeof WAPI_BET_OFFERS_RECEIVED;
    betOffers: Array<KambiBetOffer>;
};

export const setBetOffers = (payload: Array<BetOffer>): SetBetOffersAction => ({
    type: SET_BET_OFFERS,
    payload,
});

export const setWapiBetOffers = (betOffers: Array<BetOffer>): SetWapiBetOffersAction => ({
    type: SET_WAPI_BET_OFFERS,
    betOffers,
});

export const wapiBetOffersReceived = (
    betOffers: Array<KambiBetOffer>,
): WapiBetOffersReceivedAction => ({
    type: WAPI_BET_OFFERS_RECEIVED,
    betOffers,
});

export type BetofferActions = SetBetOffersAction | SetWapiBetOffersAction;
