import type {KambiSocketResponse} from "@atg-sport-shared/sportsbook-types-event";

export const RECEIVE_SOCKET_REPONSE = "sport/RECEIVE_SOCKET_REPONSE";
export const TAKING_SOCKET_RESPONSES = "sport/TAKING_SOCKET_RESPONSES";

export type ReceiveSocketResponseAction = {
    type: typeof RECEIVE_SOCKET_REPONSE;
    payload: KambiSocketResponse[];
};

export const recieveSocketResponse = (
    payload: KambiSocketResponse[],
): ReceiveSocketResponseAction => ({
    type: RECEIVE_SOCKET_REPONSE,
    payload,
});

export type TakingSocketResponesAction = {
    type: typeof TAKING_SOCKET_RESPONSES;
};

export const takingSocketResponses = (): TakingSocketResponesAction => ({
    type: TAKING_SOCKET_RESPONSES,
});
