import type {
    Event,
    KambiEventInfoBasic,
    KambiEventInfoScoreGoalBased,
    KambiEventInfoScoreSetBased,
} from "@atg-sport-shared/sportsbook-types-event";

export const SET_EVENTS = "sport/SET_EVENTS";
export const SET_LIVE_EVENTS = "sport/SET_LIVE_EVENTS";
export const SET_EVENT_INFO = "sport/SET_EVENT_INFO";
export const SET_SCORE_GOAL_BASED = "sport/SET_SCORE_GOAL_BASED";
export const SET_SCORE_SET_BASED = "sport/SET_SCORE_SET_BASED";
export const SET_SCORE_GAME_BASED = "sport/SET_SCORE_GAME_BASED";
export const SET_BIG9_TAG = "sport/SET_BIG9_TAG";

type Events = Event[];

export type SetEvents = {
    type: typeof SET_EVENTS;
    payload: Events;
};

export type SetLiveEvents = {
    type: typeof SET_LIVE_EVENTS;
    payload: {
        events: Events;
        now: number;
    };
};

export type SetBasicEventInfoAction = {
    type: typeof SET_EVENT_INFO;
    payload: KambiEventInfoBasic;
};

export type SetScoreGoalBasedAction = {
    type: typeof SET_SCORE_GOAL_BASED;
    payload: KambiEventInfoScoreGoalBased;
};

export type SetScoreSetBasedAction = {
    type: typeof SET_SCORE_SET_BASED;
    payload: KambiEventInfoScoreSetBased;
};

export type SetScoreGameBasedAction = {
    type: typeof SET_SCORE_GAME_BASED;
    payload: KambiEventInfoScoreSetBased;
};

export type SetBig9Tag = {
    type: typeof SET_BIG9_TAG;
    payload: ReadonlyArray<Event["id"]>;
};

export const setEvents = (payload: Events): SetEvents => ({
    type: SET_EVENTS,
    payload,
});

export const setLiveEvents = (events: Events, now: number): SetLiveEvents => ({
    type: SET_LIVE_EVENTS,
    payload: {events, now},
});

export const setBasicEventInfo = (
    payload: KambiEventInfoBasic,
): SetBasicEventInfoAction => ({
    type: SET_EVENT_INFO,
    payload,
});

export const setScoreGoalBased = (
    payload: KambiEventInfoScoreGoalBased,
): SetScoreGoalBasedAction => ({
    type: SET_SCORE_GOAL_BASED,
    payload,
});

export const setScoreSetBased = (
    payload: KambiEventInfoScoreSetBased,
): SetScoreSetBasedAction => ({
    type: SET_SCORE_SET_BASED,
    payload,
});

export const setScoreGameBased = (
    payload: KambiEventInfoScoreSetBased,
): SetScoreGameBasedAction => ({
    type: SET_SCORE_GAME_BASED,
    payload,
});

export const setBig9TagForEvents = (payload: ReadonlyArray<Event["id"]>): SetBig9Tag => ({
    type: SET_BIG9_TAG,
    payload,
});

export type EventActions =
    | SetEvents
    | SetLiveEvents
    | SetBasicEventInfoAction
    | SetScoreGoalBasedAction
    | SetScoreSetBasedAction
    | SetScoreGameBasedAction
    | SetBig9Tag;
