import type {Setting, SortOption} from "@atg-sport-shared/big9-types/settingsTypes";

export const SET_COUPON_SETTINGS = "big9/SET_COUPON_SETTINGS";
export const SET_ONGOING_MATCH_SETTINGS = "big9/SET_ONGOING_MATCH_SETTINGS";
export const SET_SORT_BY = "big9/SET_SORT_BY";
export const LOAD_SETTINGS = "big9/LOAD_SETTINGS";

export type SetCouponSettingsAction = {
    type: typeof SET_COUPON_SETTINGS;
    payload: ReadonlyArray<Setting>;
};

export type SetOngoingMatchSettingsAction = {
    type: typeof SET_ONGOING_MATCH_SETTINGS;
    payload: ReadonlyArray<Setting>;
};

export type SetSortByAction = {type: typeof SET_SORT_BY; payload: SortOption};

export type LoadSettingsAction = {type: typeof LOAD_SETTINGS};

export const setCouponSettings = (
    payload: ReadonlyArray<Setting>,
): SetCouponSettingsAction => ({
    type: SET_COUPON_SETTINGS,
    payload,
});

export const setOngoingMatchSettings = (
    payload: ReadonlyArray<Setting>,
): SetOngoingMatchSettingsAction => ({
    type: SET_ONGOING_MATCH_SETTINGS,
    payload,
});

export const setSortBy = (payload: SortOption): SetSortByAction => ({
    type: SET_SORT_BY,
    payload,
});

export const loadSettings = (): LoadSettingsAction => ({type: LOAD_SETTINGS});
