import type {KambiGroupResponse} from "@atg-sport-shared/sportsbook-types-group";

export type Category = {
    id: number;
    englishName: string;
    localizedName: string;
};

export type Term = {
    type: string;
    id: string;
    termKey: string;
    localizedName: string;
    parentId: string;
    englishName: string;
    value?: string;
};

export type Group = {
    id: number;
    name?: string;
    boCount: number;
    uri?: string;
    englishName?: string;
    groups?: Array<Group | null | void>;
    sport: string;
    eventCount: number;
    secondsToNextEvent?: number;
    termKey?: string;
    pathTermId?: string;
    sortOrder?: string;
};

export type Ticker = {
    eventId?: number;
    type?: string;
    minute?: number;
    message?: string;
    id: number;
};

export type Score = {
    home?: string;
    away?: string;
    info?: string;
    who?: string;
};

export type LiveFeedUpdate = {
    ticker?: Ticker;
    score?: Score;
    type?: string;
};

export type LiveStatistics = {
    occurrenceTypeId?: string;
    count?: number;
};

export type KambiMatchClock = {
    minute?: number;
    second?: number;
    period?: string;
    running?: boolean;
    disabled?: boolean;
};

export type Player = {
    id: number;
    name?: string;
};

export type MatchOccurrence = {
    id: number;
    eventId?: number;
    occurrenceTypeId?: string;
    secondInPeriod?: number;
    secondInMatch?: number;
    secondInPeriodAddedTime?: number;
    periodId?: string;
    player?: Player;
    playerOut?: Player;
    additionalProperties?: Array<string>;
    action?: string;
    index?: number;
    periodIndex?: number;
};

export type OutcomeCriterion = {
    type: number;
    name: string;
};

export type Outcome = {
    id: number;
    label: string;
    englishLabel: string;
    odds: number;
    line?: number;
    distance?: string;
    scratched?: boolean;
    startNr?: number;
    prevOdds?: Array<number>;
    criterion?: OutcomeCriterion;
    participant?: string;
    popular?: boolean;
    type: string;
    homeTeamMember?: boolean;
    betOfferId?: number;
    changedDate: string;
    participantId?: number;
    oddsFractional: string;
    oddsAmerican: string;
    status: "OPEN" | "CLOSED" | "SUSPENDED" | "SETTLED";
    cashOutStatus: "ENABLED" | "DISABLED" | "SUSPENDED";
    homeScore?: string;
    awayScore?: string;
};

export type Team = {
    yellowCards?: number;
    redCards?: number;
    corners?: number;
};

export type Statistics = {
    football?: {
        home?: Team;
        away?: Team;
    };
    sets?: {
        home?: Array<number>; // non played sets are -1
        away?: Array<number>; // non played sets are -1
        homeServe?: boolean;
    };
};

export type Tags =
    | "OFFERED_LIVE"
    | "STREAMED_WEB"
    | "STREAMED_MOBILE"
    | "PREMATCH_STATS"
    | "VISUALIZATION"
    | "OPEN_FOR_LIVE"
    | "SHOW_START_NUMBER"
    | "MATCH"
    | "COMPETITION"
    | "AMERICAN_DISPLAY_FORMAT"
    // Can be appended in events reducer for big 9 matches
    | "BIG_9";

export type VisualizationOccurrence = {
    id: number;
    eventId?: number;
    occurrenceTypeId?: number;
    periodId?: number;
    visualization?: {
        position?: {
            x?: number; // x position of the field occurrence
            y?: number; // y position of the field occurrence
            zone?: string;
        };
    };
};

type Criterion = {
    englishLabel?: string;
    id?: number;
    label?: string;
    occurrenceType?: string;
    order?: Array<unknown>;
};

export type BetOffer = {
    id: number | string;
    suspended?: boolean;
    closed?: string | boolean;
    criterion?: Criterion;
    extra?: string;
    betOfferType?: {
        id: number;
        name: string;
        englishName: string;
    };
    type?: string;
    placeLimit?: number;
    eventId: number;
    outcomes: Array<Outcome>;
    place?: boolean;
    eachWay?: {
        fractionMilli?: number;
        terms?: string;
        placeLimit?: number;
    };
    scorerType?: OutcomeCriterion;
    combinableOutcomes?: {
        playerOutcomes?: Array<Outcome>;
        resultOutcomes?: Array<Outcome>;
        outcomeCombinations?: {
            odds?: number;
            oddsFractional?: string;
            oddsAmerican?: string;
            playerOutcome?: Outcome;
            resultOutcome?: Outcome;
        };
    };
    tags?: Array<string>;
    oddsStats?: {
        unexpectedOddsTrend?: boolean;
        outcomeId?: number;
        startingOdds?: number;
        startingOddsFractional?: string;
        startingOddsAmerican?: string;
    };
    sortOrder?: number;
    cashOutStatus?: "ENABLED" | "DISABLED" | "SUSPENDED";
    from?: number | null | void;
    to?: number | null | void;
    description?: string;
};

export type KambiPath = {
    id: number;
    name: string;
    englishName: string;
    termKey: string;
};

export type KambiEvent = {
    id: number;
    name: string;
    englishName?: string;
    homeName?: string;
    awayName?: string;
    start?: string;
    originalStartTime?: string;
    group?: string;
    groupId?: number;
    path: Array<KambiPath>;
    nonLiveBoCount?: number;
    liveBoCount?: number;
    sport?: string;
    tags?: Array<Tags>;
    state?: "NOT_STARTED" | "STARTED" | "FINISHED";
    distance?: string;
    eventNumber?: number;
    nameDetails?: string;
    editorial?: string;
    raceClass?: string;
    raceType?: string;
    trackType?: string;
    going?: string;
    timeform?: {
        analystVerdict?: string;
        drawComment?: string;
    };
    participants?: Array<unknown>;
    rank?: number;
    groupSortOrder?: number;
    teamColors?: unknown;
    sortOrder?: number;
    prematchEnd?: string;
    meetingId?: string;
};

export type LiveData = {
    eventId: number;
    matchClock?: KambiMatchClock;
    score?: Score;
    statistics?: Statistics;
    description?: string;
    latestVisualization?: VisualizationOccurrence;
    occurrences?: Array<MatchOccurrence>;
    liveFeedUpdates?: Array<LiveFeedUpdate>;
    tickers?: Array<Ticker>;
    liveStatistics?: Array<LiveStatistics>;
};

export type KambiEventsArray = Array<{
    event: KambiEvent;
    betOffers: Array<BetOffer>;
}>;

export type KambiResponseType = {
    data: {
        events: KambiEventsArray;
    };
};

export const EVENT_INFO_BASIC = "BASIC";
export const EVENT_INFO_SCORE = "SCORE";
export const EVENT_INFO_BET_OFFERS = "BET_OFFERS";

export const EVENT_SCORE_GOAL_BASED = "MATCH_GOAL_BASED";
export const EVENT_SCORE_SET_BASED = "MATCH_SET_BASED";
export const EVENT_SERVING_HOME = "SERVING_HOME";
export const EVENT_SERVING_AWAY = "SERVING_AWAY";

export type Serving = typeof EVENT_SERVING_HOME | typeof EVENT_SERVING_AWAY;

type EventInfoPath = {
    id: number;
    name: string;
    englishName: string;
};

export type KambiEventInfoBasic = {
    info: typeof EVENT_INFO_BASIC;
    id: number;
    groupId: number;
    name: string;
    startTimestamp: number;
    path: Array<EventInfoPath>;
};

type KambiEventInfoScoreBase = {
    info: typeof EVENT_INFO_SCORE;
    id: number;
    sport: string;
};

export type KambiEventInfoScoreGoalBased = {
    type: typeof EVENT_SCORE_GOAL_BASED;
    score: {
        home: string;
        away: string;
        info?: string;
    };
} & KambiEventInfoScoreBase;

export type KambiEventInfoScoreSetBased = {
    type: typeof EVENT_SCORE_SET_BASED;
    score: {
        hasGames: boolean;
        remainingSets: Array<string>;
        home: {points: string; serving?: boolean};
        away: {points: string; serving?: boolean};
        sets: Array<{
            home: {value: string; won: boolean | null | void};
            away: {value: string; won: boolean | null | void};
        }>;
        info?: string;
    };
} & KambiEventInfoScoreBase;

export type KambiEventInfoBetOffers = {
    info: typeof EVENT_INFO_BET_OFFERS;
    id: number;
    betoffers: Array<BetOffer>;
};

export type KambiEventInfo =
    | KambiEventInfoBasic
    | KambiEventInfoScoreGoalBased
    | KambiEventInfoScoreSetBased
    | KambiEventInfoBetOffers;

export type KambiBetofferResponse = {
    betoffers: Array<BetOffer>;
    events: Array<KambiEvent>;
};

export type KambiEventBetofferResponse = {
    events: Array<{
        event: KambiEvent;
        betOffers: Array<BetOffer>;
    }>;
    group: {groups: KambiGroupResponse[]};
};

export const EVENT_SCORE_TYPE_MATCH = "EVENT_SCORE_TYPE_MATCH";
export const EVENT_SCORE_TYPE_SET = "EVENT_SCORE_TYPE_SET";
export const EVENT_SCORE_TYPE_GAME = "EVENT_SCORE_TYPE_GAME";

export const SCORE_SET_STATUS_PENDING = "SCORE_SET_STATUS_PENDING";
export const SCORE_SET_STATUS_STARTED = "SCORE_SET_STATUS_STARTED";
export const SCORE_SET_STATUS_DONE = "SCORE_SET_STATUS_DONE";

export type ScoreSetStatus =
    | typeof SCORE_SET_STATUS_PENDING
    | typeof SCORE_SET_STATUS_STARTED
    | typeof SCORE_SET_STATUS_DONE;

export type EventScoreMatch = {
    type: typeof EVENT_SCORE_TYPE_MATCH;
    home?: string;
    away?: string;
    info?: string;
};

export type EventScoreSet = {
    type: typeof EVENT_SCORE_TYPE_SET;
    sets: Array<{
        status: ScoreSetStatus;
        home: string;
        away: string;
    }>;
    info?: string;
};

export type GameSetItem = {
    game: string;
    point: string;
};

export type EventScoreGame = {
    type: typeof EVENT_SCORE_TYPE_GAME;
    sets: Array<{
        status: ScoreSetStatus;
        home: GameSetItem;
        away: GameSetItem;
    }>;
    info?: string;
};

export type EventScore = EventScoreMatch | EventScoreSet | EventScoreGame;

export type MatchClock = {
    seconds: number;
    running: boolean;
    ts: number;
    disabled?: boolean;
    minute?: number | string;
    second?: number | string;
    period?: string;
    minutesLeftInPeriod?: number;
    secondsLeftInPeriod?: number;
};

export type Event = {
    id: number;
    name?: string;
    homeName?: string;
    awayName?: string;
    sportName?: string;
    pathIds: Array<number> | null | void;
    startTimestamp: number | null | void;
    matchClock?: MatchClock | null | void;
    score?: EventScore;
    serving?: Serving | null | void;
    tags?: Array<Tags>;
    homeStatistics?: Team | null | void;
    awayStatistics?: Team | null | void;
    nrOfBetOffers?: number;
    nonLiveBoCount?: number;
    liveBoCount?: number;
};

export type EventState =
    | {
          [key: string]: Event;
      }
    | undefined;

export type CMSEventMetadata = {
    id: number;
    homeName: string;
    awayName: string;
    name: string;
    startDate: string | number;
    path: Array<{
        id: number;
        englishName: string;
        name: string;
        termKey: string;
    }>;
    sportName: string;
    nrOfBetOffers: number;
};

export type FetchedKambiData = {
    event: KambiEvent;
    betOffers: Array<BetOffer>;
    liveData?: LiveData;
};
