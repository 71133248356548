import {get} from "lodash";
import {
    ODDS_FORMAT_DECIMAL,
    ODDS_FORMAT_FRACTIONAL,
    ODDS_FORMAT_AMERICAN,
} from "@atg-sport-shared/sportsbook-types-kambi";
import type {
    KambiEvent,
    BetOffer as KambiBetOffer,
    Outcome as KambiOutcome,
    FetchedKambiData,
} from "@atg-sport-shared/sportsbook-types-event";
import {
    type BetOffer,
    type Outcome,
    type BetOfferType,
    BET_OFFER_TYPE_MATCH,
    BET_OFFER_TYPE_POSITION,
    BET_OFFER_TYPE_UNKNOWN,
    BET_OFFER_TYPE_WINNER,
} from "@atg-sport-shared/sportsbook-types-bet-offer";

const BET_OFFER_TYPE_MAP = {
    "2": BET_OFFER_TYPE_MATCH,
    "4": BET_OFFER_TYPE_WINNER,
    "117": BET_OFFER_TYPE_POSITION,
};

const getBetOfferType = (betOffer: KambiBetOffer): BetOfferType => {
    // @ts-expect-error - betOfferType.id is typed as number, so conversion to "2" etc is wrong
    const betOfferTypeId = get(betOffer, "betOfferType.id") as "2" | "4" | "117";
    return (BET_OFFER_TYPE_MAP[betOfferTypeId] ||
        betOffer.type ||
        BET_OFFER_TYPE_UNKNOWN) as BetOfferType;
};

const createOutcomeLabel = ({
    outcome,
    betOfferType,
    homeName,
    awayName,
}: {
    outcome: KambiOutcome;
    betOfferType: BetOfferType;
    homeName: string | undefined;
    awayName: string | undefined;
}) => {
    switch (betOfferType) {
        case BET_OFFER_TYPE_MATCH:
            if (outcome.type === "OT_CROSS") return "Oavgjort";
            if (!outcome.type) return outcome.label;
            return outcome.type === "OT_ONE" ? homeName : awayName;
        default:
            return outcome.label;
    }
};

type OutcomeParams = {
    betOfferSuspended: boolean;
    betOfferType: BetOfferType;
    homeName: string | undefined;
    awayName: string | undefined;
    outcome: KambiOutcome;
};

const parseOutcome = ({
    betOfferSuspended,
    betOfferType,
    homeName,
    awayName,
    outcome,
}: OutcomeParams): Outcome => {
    const {id, betOfferId, odds, oddsAmerican, oddsFractional} = outcome;
    const isValid = Number.isInteger(odds) && odds >= 1010;

    const oddsDecimal = isValid ? (odds / 1000).toFixed(2) : "";

    return {
        id,
        betOfferId,
        odds,
        formattedOdds: {
            [ODDS_FORMAT_DECIMAL]: oddsDecimal,
            [ODDS_FORMAT_FRACTIONAL]: oddsFractional,
            [ODDS_FORMAT_AMERICAN]: oddsAmerican,
        },
        suspended: betOfferSuspended || !isValid,
        label:
            createOutcomeLabel({
                outcome,
                betOfferType,
                homeName,
                awayName,
            }) || "",
    };
};

export const parseBetOffer = (
    event: Partial<KambiEvent>,
    betOffer: KambiBetOffer,
): BetOffer => {
    const {id, eventId, outcomes, tags} = betOffer;

    const suspended = get(betOffer, "suspended", false);
    const type = getBetOfferType(betOffer);
    const label = get(betOffer, "criterion.label", "");
    const {homeName, awayName} = event;

    const outcomeParams = {
        betOfferSuspended: suspended,
        betOfferType: type,
        homeName,
        awayName,
    };

    return {
        id,
        eventId,
        tags,
        label,
        suspended,
        type,
        outcomes: outcomes.map((outcome) => parseOutcome({...outcomeParams, outcome})),
    };
};

export const kambiBetOffersParser = (events: FetchedKambiData[]): Array<BetOffer> =>
    events.reduce((acc, data) => {
        const {event, betOffers} = data;

        if (!Array.isArray(betOffers) || betOffers.length === 0) return acc;

        return [...acc, ...betOffers.map((betOffer) => parseBetOffer(event, betOffer))];
    }, [] as BetOffer[]);
