export const OFFERING_GET_GROUPS = "OFFERING_GET_GROUPS";
export const OFFERING_GET_LIVE_EVENTS = "OFFERING_GET_LIVE_EVENTS";
export const OFFERING_GET_SOON_EVENTS = "OFFERING_GET_SOON_EVENTS";
export const OFFERING_GET_HIGHLIGHTED = "OFFERING_GET_HIGHLIGHTED";
export const OFFERING_GET_LEAGUE = "OFFERING_GET_LEAGUE";
export const OFFERING_HIGHLIGHTS_SUCCESS = "OFFERING_HIGHLIGHTS_SUCCESS";

export const OFFERING_FETCH_FAIL = "OFFERING_FETCH_FAIL";

export type GetGroupsAction = {type: typeof OFFERING_GET_GROUPS};
export type GetLiveEventsAction = {type: typeof OFFERING_GET_LIVE_EVENTS};
export type GetSoonEventsAction = {type: typeof OFFERING_GET_SOON_EVENTS};
export type GetHighlightedAction = {type: typeof OFFERING_GET_HIGHLIGHTED};
export type GetLeagueAction = {type: typeof OFFERING_GET_LEAGUE; payload: string};
export type HighlightsSuccessAction = {type: typeof OFFERING_HIGHLIGHTS_SUCCESS};

export type FetchFailAction = {
    type: "OFFERING_FETCH_FAIL";
    payload: {
        error: {
            [key: string]: any;
        };
        service: string;
    };
};

export const getGroups = (): GetGroupsAction => ({
    type: OFFERING_GET_GROUPS,
});

export const getLiveEvents = (): GetLiveEventsAction => ({
    type: OFFERING_GET_LIVE_EVENTS,
});

export const getSoonEvents = (): GetSoonEventsAction => ({
    type: OFFERING_GET_SOON_EVENTS,
});

export const getHighlighted = (): GetHighlightedAction => ({
    type: OFFERING_GET_HIGHLIGHTED,
});

export const getLeague = (queryString: string): GetLeagueAction => ({
    type: OFFERING_GET_LEAGUE,
    payload: queryString,
});

export const highlightsSuccess = (): HighlightsSuccessAction => ({
    type: OFFERING_HIGHLIGHTS_SUCCESS,
});

export const offeringFetchFail = (
    service: string,
    error: {
        [key: string]: string | number;
    },
): FetchFailAction => ({
    type: OFFERING_FETCH_FAIL,
    payload: {
        error,
        service,
    },
});

export type OfferingActions =
    | GetGroupsAction
    | GetLiveEventsAction
    | GetSoonEventsAction
    | GetHighlightedAction
    | GetLeagueAction
    | FetchFailAction;
