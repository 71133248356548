import type {Reducer} from "redux";
// @ts-ignore
import * as _FetchReducer from "@atg-shared/fetch-redux";
import type {
    OfferingsFetchState,
    SetActiveOfferingType,
} from "@atg-sport-shared/pool-types";
import type {
    Offering,
    OfferingWithMatchRefs,
    OfferingsById,
} from "@atg-sport-shared/pool-types/offeringTypes";
import {
    type FetchOfferingsAction,
    type OfferingUpdateAction,
    type FetchOfferingAction,
    type ReceiveOfferingStartAction,
    REQUEST_OFFERINGS,
    RECEIVE_OFFERINGS,
    OFFERING_UPDATE,
    RECEIVE_OFFERING,
    RECEIVE_OFFERING_START,
    SET_ACTIVE_OFFERING_TYPE,
} from "@atg-sport-shared/pool-types";

const FetchReducer = _FetchReducer as any;

type OfferingsAction =
    | FetchOfferingsAction
    | FetchOfferingAction
    | OfferingUpdateAction
    | ReceiveOfferingStartAction
    | SetActiveOfferingType;

const initialState: OfferingsFetchState = FetchReducer.createInitialState({
    allIds: [],
    byId: {},
    // activeOfferingType: undefined,
});

export const offeringTransform = (offering: OfferingWithMatchRefs) => ({
    [offering.id]: {
        ...offering,
        matches: offering.matches.map(({id, number, startTime, status}) => ({
            id,
            number,
            startTime,
            status,
        })),
        ...(offering.pool
            ? {
                  pool: {
                      ...offering.pool,
                      numberOfSystems: Math.round(offering.pool.numberOfSystems),
                  },
              }
            : {}),
    },
});

const offeringsToObject = (offerings: ReadonlyArray<Offering>) =>
    offerings.reduce(
        (acc: OfferingsById, offering: Offering) => ({
            ...acc,
            ...offeringTransform(offering),
        }),
        {},
    );

export const offeringsReducer: Reducer<OfferingsFetchState> =
    FetchReducer.createFetchReducer(
        REQUEST_OFFERINGS,
        RECEIVE_OFFERINGS,
        "",
        (state: OfferingsFetchState, action: OfferingsAction) => {
            switch (action.type) {
                case RECEIVE_OFFERINGS:
                case RECEIVE_OFFERING: {
                    if (!action.error && action.payload) {
                        // @ts-expect-error suppressed error during Flow -> TS migration, fix when possible
                        const newOfferingIds = Array.from(action.payload).map(
                            ({id}: Offering) => id,
                        );
                        const newIds = newOfferingIds.filter(
                            (id) => !state.allIds.find((newId) => newId === id),
                        );

                        const allOfferings = {
                            ...state.byId,
                            // @ts-expect-error suppressed error during Flow -> TS migration, fix when possible
                            ...offeringsToObject(action.payload),
                        };
                        const allIds = [...state.allIds, ...newIds];

                        return {
                            ...state,
                            byId: allOfferings,
                            allIds,
                        };
                    }

                    return state;
                }
                case OFFERING_UPDATE: {
                    const {offering} = action.payload;

                    return {
                        ...state,
                        byId: {...state.byId, ...offeringTransform(offering)},
                    };
                }
                case RECEIVE_OFFERING_START: {
                    if (!action?.payload || !action?.payload[0]) {
                        return state;
                    }

                    return {
                        ...state,
                        ...action.payload,
                    };
                }
                case SET_ACTIVE_OFFERING_TYPE: {
                    if (!action?.payload) {
                        return state;
                    }

                    return {
                        ...state,
                        ...action?.payload,
                    };
                }
                default: {
                    return state;
                }
            }
        },
        initialState,
    );
