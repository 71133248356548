export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

/**   Represents a bet offer belonging to an event. */
export type BetOffer = {
  __typename?: 'BetOffer';
  betOfferType: Maybe<BetOfferType>;
  cashOutStatus: Maybe<CashOutStatus>;
  /**   Date and time after which no bets will be accepted for this bet offer and the bet offer will be removed from the offering. If the bet offer is offered live this field will be omitted. See Data Formats for the formatting.) */
  closed: Maybe<Scalars['String']['output']>;
  criterion: Maybe<BetOfferCriterion>;
  eachWay: Maybe<EachWay>;
  /**   Identifier of the event the bet offer belongs to. */
  eventId: Maybe<Scalars['Float']['output']>;
  /**   Localized extra information for the bet offer. */
  extra: Maybe<Scalars['String']['output']>;
  /**   Position bet offers only. Start of position interval. */
  from: Maybe<Scalars['Float']['output']>;
  /**   Unique identifier of the bet offer. */
  id: Maybe<Scalars['Float']['output']>;
  oddsStats: Maybe<OddsStats>;
  outcomes: Maybe<Array<Maybe<Outcome>>>;
  /**   If value is true, then the bet offer is Outright Place bet offer. */
  place: Maybe<Scalars['Boolean']['output']>;
  /**   The number of participants in the Outright Place bet offer. See also the place property. Value 2 means first two participants to finish, value 3 means first three participants to finish etc. The number of positions for a MultiPosition bet offer. (2 for forecast and 3 for tricast for example) */
  placeLimit: Maybe<Scalars['Float']['output']>;
  sortOrder: Maybe<Scalars['Float']['output']>;
  /**   If value is true, then the bet offer is suspended. The property is omitted otherwise. */
  suspended: Maybe<Scalars['Boolean']['output']>;
  tags: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /**   Position bet offers only. End of position interval. */
  to: Maybe<Scalars['Float']['output']>;
};

export type BetOfferCategory = {
  __typename?: 'BetOfferCategory';
  id: Maybe<Scalars['String']['output']>;
  label: Maybe<Scalars['String']['output']>;
};

/**   Bet Offer Category Filter configuration object that can, optionally, be passed to the event list query. */
export type BetOfferCategoryFilter = {
  /**   Whether or not to include combinable bet offers in the response. */
  includeCombined: InputMaybe<Scalars['Boolean']['input']>;
  /**   Whether or not to include outright bet offers in the response. */
  includeOutrights: InputMaybe<Scalars['Boolean']['input']>;
  /**   Whether or not to list outright bet offers first. */
  outrightsFirst: InputMaybe<Scalars['Boolean']['input']>;
};

/**   Bet offer criterion. */
export type BetOfferCriterion = {
  __typename?: 'BetOfferCriterion';
  /**   English language label for the criterion. */
  englishLabel: Maybe<Scalars['String']['output']>;
  /**   Identifier of the criterion. */
  id: Maybe<Scalars['Float']['output']>;
  /**   Localized label for the criterion. */
  label: Maybe<Scalars['String']['output']>;
  /**   Time criteria, during what time period of the event this bet offer applies to. The possible values might be extended. If not known, the value is UNTYPED. */
  lifetime: Maybe<LifeTime>;
  /**   The number of occurrences of the occurrence type during the lifetime this bet offer applies to. */
  occurrenceNumber: Maybe<Scalars['Float']['output']>;
  /**   Used for sorting of bet offers of type OCCURRENCE_METHOD. This number represents the sequence order of the occurrence type, within the lifetime. */
  occurrenceNumberInLifetime: Maybe<Scalars['Float']['output']>;
  /**   Occurrence type criteria, what is counted. The possible values might be extended. If not known, the value is UNTYPED. */
  occurrenceType: Maybe<OccurenceType>;
  /**   List of numbers describing point within a match, depending on match structure. E.g. a tennis game at set 1, game 5, point 2 has the value [1, 5, 2]. This enables the possibility to sort between bet offers with the same criterion. */
  order: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  /**   First to reach this value. */
  raceToValue: Maybe<Scalars['Float']['output']>;
};

/**   The type of the bet offer. */
export type BetOfferType = {
  __typename?: 'BetOfferType';
  /**   English language label for the bet offer type. */
  englishName: Maybe<Scalars['String']['output']>;
  /**   Identifier of the bet offer type. */
  id: Maybe<Scalars['Float']['output']>;
  /**   Localized label for the bet offer type. */
  name: Maybe<Scalars['String']['output']>;
};

/**   Cashout status of the outcome. */
export enum CashOutStatus {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED',
  Suspended = 'SUSPENDED'
}

export type Category = {
  __typename?: 'Category';
  englishName: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['Float']['output']>;
  localizedName: Maybe<Scalars['String']['output']>;
};

export type CategoryGroup = {
  __typename?: 'CategoryGroup';
  categories: Maybe<Array<Maybe<Category>>>;
  categoryGroupName: Maybe<Scalars['String']['output']>;
  localizedName: Maybe<Scalars['String']['output']>;
};

/**   An object that represents the coupon type. */
export type Coupon = {
  __typename?: 'Coupon';
  /**   The item identifier. */
  itemId: Maybe<Scalars['Float']['output']>;
  /**   The item type. */
  itemType: Maybe<Scalars['String']['output']>;
};

/**   The object that represents the coupon-event type. */
export type CouponEvent = {
  __typename?: 'CouponEvent';
  /**   Away team name. */
  awayName: Maybe<Scalars['String']['output']>;
  /**   List of all the bet offers for the event. */
  betOffers: Maybe<Array<Maybe<BetOffer>>>;
  /**   Event name with default formatting. The name will contain homeName and awayName property values separated by a dash. Useful for analytics tracking */
  englishName: Maybe<Scalars['String']['output']>;
  /**   Event group name. */
  group: Maybe<Scalars['String']['output']>;
  /**   Event group identifier. */
  groupId: Maybe<Scalars['Float']['output']>;
  /**   Home team name. */
  homeName: Maybe<Scalars['String']['output']>;
  /**   Unique identifier of the event. */
  id: Maybe<Scalars['Float']['output']>;
  /**   The number of active live bet offers on this event. */
  liveBoCount: Maybe<Scalars['Float']['output']>;
  /**
   *   homeName - awayName
   *  homeName vs awayName
   *  awayName vs homeName
   *  awayName @ homeName
   *  awayName - homeName
   */
  name: Maybe<Scalars['String']['output']>;
  nameDelimiter: Maybe<Scalars['String']['output']>;
  nonLiveBoCount: Maybe<Scalars['Int']['output']>;
  /**   Term-key parents for the event groups of this event */
  path: Maybe<Array<Maybe<Path>>>;
  /**   The priorioty of the event. */
  prio: Maybe<Scalars['Int']['output']>;
  /**   The total ranking of all the participants. */
  rank: Maybe<Scalars['Int']['output']>;
  /**   The sport for the event. This could typically be identifiers like 'FOOTBALL', 'BOXING', 'BASKET'. */
  sport: Maybe<Scalars['String']['output']>;
  /**   Event start date and time. See Data Formats for the formatting. */
  start: Maybe<Scalars['String']['output']>;
  /**   The state of the event. */
  state: Maybe<Scalars['String']['output']>;
  /**   List of tags for the event. */
  tags: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

/**   The object that represents the coupon-group type. */
export type CouponGroup = {
  __typename?: 'CouponGroup';
  /**   Event group identifier. */
  groupId: Maybe<Scalars['Float']['output']>;
  /**   Index of the group level. */
  groupLevelIndex: Maybe<Scalars['Float']['output']>;
  /**   Event group name. */
  groupName: Maybe<Scalars['String']['output']>;
  /**   Term-key parents for the event groups of this event */
  path: Maybe<Array<Maybe<Path>>>;
};

/**   Response returned by the coupon query. */
export type CouponResponse = {
  __typename?: 'CouponResponse';
  /**   List of coupons. */
  coupons: Maybe<Array<Maybe<Coupon>>>;
  /**   List of coupon events. */
  events: Maybe<Array<Maybe<CouponEvent>>>;
  /**   List of groups. */
  groups: Maybe<Array<Maybe<CouponGroup>>>;
  /**   The name of the coupon. */
  name: Maybe<Scalars['String']['output']>;
};

export type EachWay = {
  __typename?: 'EachWay';
  /**   The odds fraction divided with 1000. For example 1/5 will be returned like 200. */
  fractionMilli: Maybe<Scalars['Float']['output']>;
  /**   The place limit for the each way. The number of positions for a MultiPosition bet offer. (2 for forecast and 3 for tricast) */
  placeLimit: Maybe<Scalars['Float']['output']>;
  /**   The terms for the each way bet offer connected to the current bet offer. For example 1/5 3 means the odds will be 1/5 of the winning odds and the first 3 places will be paid out. */
  terms: Maybe<Scalars['String']['output']>;
};

/**   Represents one event, either a match or a competition. */
export type Event = {
  __typename?: 'Event';
  /**   Away team name. */
  awayName: Maybe<Scalars['String']['output']>;
  /**   List of bet offers for the event. */
  betOffers: Maybe<Array<Maybe<BetOffer>>>;
  description: Maybe<Scalars['String']['output']>;
  /**   String representing the distance of for example a horse racing event. */
  distance: Maybe<Scalars['String']['output']>;
  /**   An editorial about the event. For example a description of conditions of a horse racing course. */
  editorial: Maybe<Scalars['String']['output']>;
  /**   Event name with default formatting. The name will contain homeName and awayName property values separated by a dash. Useful for analytics tracking */
  englishName: Maybe<Scalars['String']['output']>;
  /**   For example a horse racing event can consist of several races. Each race will be an individual event in the API. The eventNumber is the number of the race. */
  eventNumber: Maybe<Scalars['Float']['output']>;
  /**   The extra information which is relevant to an event. */
  extraInfo: Maybe<Scalars['String']['output']>;
  /**   The going at the race. For example 'Good to Soft (Good in places on the home straight)'. */
  going: Maybe<Scalars['String']['output']>;
  /**   Event group name. */
  group: Maybe<Scalars['String']['output']>;
  /**   Event group identifier. */
  groupId: Maybe<Scalars['Float']['output']>;
  /**   The sort order of the event group for this event. With low number ranked higher. */
  groupSortOrder: Maybe<Scalars['Float']['output']>;
  /**   Home team name. */
  homeName: Maybe<Scalars['String']['output']>;
  /**   Unique identifier of the event. */
  id: Maybe<Scalars['Float']['output']>;
  /**   Represents one visualization occurrence within an event, e.g. CARDS_YELLOW_HOME. This entity may also be pushed (mt=27). Optional, not set if there are no visualization occurrences available. */
  latestVisualization: Maybe<LatestVisualization>;
  /**   The number of active live bet offers on this event. */
  liveBoCount: Maybe<Scalars['Float']['output']>;
  /**   List of live feed updates for the event. */
  liveFeedUpdates: Maybe<Array<Maybe<LiveFeedUpdate>>>;
  /**   Occurrence counter for one specific occurrence type. */
  liveStatistics: Maybe<Array<Maybe<LiveStatistic>>>;
  /**   Match clock of the current event. Optional, not set if match clock is not available. */
  matchClock: Maybe<MatchClock>;
  /**   Unique identifier for a meeting that this event is part of, only applicable for horse racing and greyhound sport. */
  meetingId: Maybe<Scalars['String']['output']>;
  /**
   *   homeName - awayName
   *  homeName vs awayName
   *  awayName vs homeName
   *  awayName @ homeName
   *  awayName - homeName
   */
  name: Maybe<Scalars['String']['output']>;
  /**   The delimiter used between the teams in the event name. Only returned for match events. */
  nameDelimiter: Maybe<Scalars['String']['output']>;
  /**   For example a horse racing event can consist of several races. Each race will be an individual event in the API. The nameDetails is additional information for the event name. */
  nameDetails: Maybe<Scalars['String']['output']>;
  /**   The number of active prematch bet offers on this event. */
  nonLiveBoCount: Maybe<Scalars['Float']['output']>;
  /**   List of match occurrences for the event. */
  occurrences: Maybe<Array<Maybe<MatchOccurence>>>;
  /**   The original start time of the event, usually HH:mm. (Can be seen as a part of the name for racing events) */
  originalStartTime: Maybe<Scalars['String']['output']>;
  /**   The list of event participants. Only included if the request parameter includeParticipants=true is set, except for events within the sports 'GALLOPS', 'HARNESS' and 'GREYHOUNDS' which always includes the participants. */
  participants: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  path: Maybe<Array<Maybe<Path>>>;
  /**   List of pre packs belonging to the event. */
  prePacks: Maybe<Array<Maybe<PrePack>>>;
  /**   Timestamp when the event should no longer be considered prematch. Last date and time of the event closing time and all its prematch betoffers. See Data Formats for the formatting. */
  prematchEnd: Maybe<Scalars['String']['output']>;
  /**   Class of race. For example '3'. */
  raceClass: Maybe<Scalars['String']['output']>;
  /**
   *   Flat
   *  Hurdle
   *  Chase
   *  National Hunt Flat
   */
  raceType: Maybe<Scalars['String']['output']>;
  /**   The total ranking of all the participants */
  rank: Maybe<Scalars['Float']['output']>;
  /**   Current event score */
  score: Maybe<Score>;
  /**   Sort order of the event within its event group, administrated through Presentation Manager. */
  sortOrder: Maybe<Scalars['Float']['output']>;
  /**   The sport for the event. This could typically be identifiers like 'FOOTBALL', 'BOXING', 'BASKET'. */
  sport: Maybe<Scalars['String']['output']>;
  /**   Event start date and time. See Data Formats for the formatting. */
  start: Maybe<Scalars['String']['output']>;
  /**   The state of the event. */
  state: Maybe<EventState>;
  /**   Event statistics. The structure is different, depending if the event is set based or match based. Tennis is set based and football is match based. Optional, not set if statistics is disabled for the event. */
  statistics: Maybe<Statistics>;
  /**   List of tags for the event. */
  tags: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /**   Colors of the teams shirts */
  teamColors: Maybe<TeamColors>;
  /**   List of tickers for the event. */
  tickers: Maybe<Array<Maybe<Ticker>>>;
  /**   Extra information on timeform for racing events. Only available for offerings which have timeform enabled. */
  timeform: Maybe<Timeform>;
  /**   Type of track. For example 'Turf'. */
  trackType: Maybe<Scalars['String']['output']>;
};

export type EventListResponse = {
  __typename?: 'EventListResponse';
  betOfferCategories: Maybe<Array<Maybe<BetOfferCategory>>>;
  categoryGroups: Maybe<Array<Maybe<CategoryGroup>>>;
};

/**   The response returned by the event query. */
export type EventResponse = {
  __typename?: 'EventResponse';
  events: Maybe<Array<Maybe<Event>>>;
};

/**   The state of an event. */
export enum EventState {
  Finished = 'FINISHED',
  NotStarted = 'NOT_STARTED',
  Started = 'STARTED',
  Unknown = 'UNKNOWN'
}

/**   Football statistics for both teams partaking in a football match. */
export type FootballStatistics = {
  __typename?: 'FootballStatistics';
  away: Maybe<FootballTeamStatistics>;
  home: Maybe<FootballTeamStatistics>;
};

/**   Football statistics for a team. */
export type FootballTeamStatistics = {
  __typename?: 'FootballTeamStatistics';
  /**   Number of corners. */
  corners: Maybe<Scalars['Int']['output']>;
  /**   Number of red cards. */
  redCards: Maybe<Scalars['Int']['output']>;
  /**   Number of yellow cards. */
  yellowCards: Maybe<Scalars['Int']['output']>;
};

/**   Hierarchy of event groups starting from root node. Maximum depth of root node(s) is specified as request parameter depth. If any group has subgroups and subgroups are not to be included in the response (depth parameter limitation) then reference to subgroups (URI) is generated. If group has neither subgroups nor reference to subgroups then it contains events only. A list of events for a particular group can be retrieved via event resource. Only groups with at least one active prematch bet offer are included. That service is supposed to have the highest performance and it is recommended to retrieve the entire tree in one call and cache it locally. Group labels are localized according to submitted request parameter lang. */
export type Group = {
  __typename?: 'Group';
  /**   The short version of the group name. */
  abbreviation: Maybe<Scalars['String']['output']>;
  /**   The number of active bet offers in the event group */
  boCount: Maybe<Scalars['Float']['output']>;
  /**   The country code of the country where the events that make up the group take place. */
  countryCode: Maybe<Scalars['String']['output']>;
  /**   The name of the group in English. */
  englishName: Maybe<Scalars['String']['output']>;
  /**   The number of events in the event group */
  eventCount: Maybe<Scalars['Float']['output']>;
  /**   List of groups */
  groups: Maybe<Array<Maybe<Group>>>;
  /**   The unique id of the group. */
  id: Maybe<Scalars['Float']['output']>;
  /**   The level of the group, meaning sport = level 1, region = level 2 and league = level 3. */
  level: Maybe<Scalars['Float']['output']>;
  /**   The name of the group. */
  name: Maybe<Scalars['String']['output']>;
  /**   The group that owns this group. */
  parentId: Maybe<Scalars['Float']['output']>;
  /**   Sortorder of the group that owns this group. */
  parentSortOrder: Maybe<Scalars['String']['output']>;
  /**   The route of the group, for example football/england/premiere_league. */
  path: Maybe<Scalars['String']['output']>;
  /**   Event groups in response are already sorted. Regardless of that, a sort order is included. Sort order is useful when new event group is added by push message. It allows to insert pushed event group into right place in tree. Sort order value is a number that contains event group sort order priority. Algorithm for sorting event groups is following: Sort event groups by sortOrder number. If event groups have same sort order value, sort them alphabetically by name. If no sort order is presented, sort them alphabetically by name and put after sorted event groups */
  sortOrder: Maybe<Scalars['String']['output']>;
  /**   The sport that the group represents. */
  sport: Maybe<Scalars['String']['output']>;
  /**   Normalized name of the group */
  termKey: Maybe<Scalars['String']['output']>;
};

/**   The response returned by the groups query. */
export type GroupsResponse = {
  __typename?: 'GroupsResponse';
  /**   List of groups. */
  groups: Maybe<Array<Maybe<Group>>>;
  /**   List of top leagues. */
  topLeagues: Maybe<Array<Maybe<Group>>>;
};

/**   Represents one visualization occurrence within an event, e.g. CARDS_YELLOW_HOME. This entity may also be pushed (mt=27). Optional, not set if there are no visualization occurrences available. */
export type LatestVisualization = {
  __typename?: 'LatestVisualization';
  /**   Identifier of the event the message applies to */
  eventId: Maybe<Scalars['Int']['output']>;
  /**   Identifier of the occurrence. Ascending order */
  id: Maybe<Scalars['Int']['output']>;
  /**   ACES_WON_HOME, ACES_WON_AWAY * DOUBLE_FAULTS_HOME, DOUBLE_FAULTS_AWAY * FIRST_SERVE_WINNING_HOME, FIRST_SERVICE_WINNING_AWAY * BREAK_POINT_CONVERSION_HOME, BREAK_POINT_CONVERSION_AWAY */
  occurrenceTypeId: Maybe<Scalars['String']['output']>;
  /**   Text representing time period, e.g. SECOND_HALF */
  periodId: Maybe<Scalars['String']['output']>;
  visualization: Maybe<Position>;
};

/**   The duration of the bet offer. */
export enum LifeTime {
  FullTime = 'FULL_TIME',
  FullTimeExtraTime = 'FULL_TIME_EXTRA_TIME',
  FullTimeOvertime = 'FULL_TIME_OVERTIME',
  Untyped = 'UNTYPED'
}

/**   Represents a live occurrence within an event, e.g. TT_SET when set is over. This entity may also be pushed (mt=19) Example of TT_MSG are 'Match delayed due to rain' */
export type LiveFeedUpdate = {
  __typename?: 'LiveFeedUpdate';
  score: Maybe<Score>;
  ticker: Maybe<Ticker>;
  type: Maybe<Scalars['String']['output']>;
};

export type LiveStatistic = {
  __typename?: 'LiveStatistic';
  /**   Number of times the occurrence has occurred in the match. */
  count: Maybe<Scalars['Int']['output']>;
  /**   Attacks (ATTACK_HOME, ATTACK_AWAY) * Dangerous Attacks (DANGEROUS_ATTACK_HOME, DANGEROUS_ATTACK_AWAY) * Shots on Target (SHOTS_ON_TARGET_HOME, SHOTS_ON_TARGET_AWAY) * Shots off Target (SHOTS_OFF_TARGET_HOME, SHOTS_OFF_TARGET_AWAY) * Goals (GOALS_HOME,GOALS_AWAY) * Yellow Cards (CARDS_YELLOW_HOME, CARDS_YELLOW_AWAY) * Red Cards (CARDS_RED_HOME, CARDS_RED_AWAY) * Corners (CORNERS_HOME, CORNERS_AWAY) */
  occurrenceTypeId: Maybe<Scalars['String']['output']>;
};

/**   Match clock of the current event. Optional, not set if match clock is not available. */
export type MatchClock = {
  __typename?: 'MatchClock';
  /**   If value is true, then the event clock is disabled for this event. Otherwise the clock is enabled. */
  disabled: Maybe<Scalars['Boolean']['output']>;
  /**   Current minutes on the event clock. */
  minute: Maybe<Scalars['Int']['output']>;
  /**   Minutes remaining in the period. (Only available for sports supporting reverse match clock.) */
  minutesLeftInPeriod: Maybe<Scalars['Int']['output']>;
  /**   Current event period. Value is a localized string label or a number in string format. */
  period: Maybe<Scalars['String']['output']>;
  /**   If value is true, then the event clock is running. Otherwise the clock has been stopped. The API user should tick up the clock (or down if showing reverse time) each second until the match clock is updated or event is removed. */
  running: Maybe<Scalars['Boolean']['output']>;
  /**   Current seconds on the event clock. */
  second: Maybe<Scalars['Int']['output']>;
  /**   Seconds remaining in the minute. (Only available for sports supporting reverse match clock.) */
  secondsLeftInMinute: Maybe<Scalars['Int']['output']>;
};

/**   Represents a live occurrence within an event, e.g. CARDS_YELLOW_HOME. This entity may also be pushed (mt=25) */
export type MatchOccurence = {
  __typename?: 'MatchOccurence';
  /**   Message indicating of occurrence happened, corrected or removed */
  action: Maybe<OccurenceAction>;
  /**   Zero, one or multiple non-translated strings with some additional info. */
  additionalProperties: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /**   Identifier of the event the message applies to. */
  eventId: Maybe<Scalars['Int']['output']>;
  /**   Identifier of the occurrence within the event. Ascending order */
  id: Maybe<Scalars['Int']['output']>;
  index: Maybe<Scalars['Int']['output']>;
  /**   ACES_WON_HOME, ACES_WON_AWAY * DOUBLE_FAULTS_HOME, DOUBLE_FAULTS_AWAY * FIRST_SERVE_WINNING_HOME, FIRST_SERVICE_WINNING_AWAY * BREAK_POINT_CONVERSION_HOME, BREAK_POINT_CONVERSION_AWAY */
  occurrenceTypeId: Maybe<Scalars['String']['output']>;
  /**   Text representation of period, e.g. SECOND_HALF */
  periodId: Maybe<Scalars['String']['output']>;
  /**   Numeric representation of period, e.g. 0 (which is FIRST_HALF in football) */
  periodIndex: Maybe<Scalars['Int']['output']>;
  /**   The name of the player being substituted out. */
  player: Maybe<PlayerOut>;
  /**   The name of the player being substituted out. */
  playerOut: Maybe<PlayerOut>;
  /**   Time of occurrence within match. Optional. */
  secondInMatch: Maybe<Scalars['Int']['output']>;
  /**   Time of occurrence within current period (every period restarts at 0). Optional. */
  secondInPeriod: Maybe<Scalars['Int']['output']>;
  /**   Time of occurrence within over time. Optional. */
  secondInPeriodAddedTime: Maybe<Scalars['Int']['output']>;
};

/**   The response returned by the meta query. */
export type MetaResponse = {
  __typename?: 'MetaResponse';
  /**   The base of the REST API url. */
  baseUrl: Scalars['String']['output'];
  /**   The region in which the AppSync instance being queried is located. */
  region: Scalars['String']['output'];
  /**   The version number of the GraphQL API. */
  versionNr: Scalars['String']['output'];
  /**   The host of the web socket connection. */
  webSocketHost: Scalars['String']['output'];
  /**   The web socket URL. */
  webSocketUrl: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  publish: Maybe<SubscriptionEvent>;
  publishEventBetOffers: Maybe<SubscriptionEvent>;
  publishEventLocalized: Maybe<SubscriptionEvent>;
  publishEventStatistics: Maybe<SubscriptionEvent>;
  publishLocalized: Maybe<SubscriptionEvent>;
};


export type MutationPublishArgs = {
  offering: Scalars['String']['input'];
  response: InputMaybe<Scalars['String']['input']>;
};


export type MutationPublishEventBetOffersArgs = {
  eventId: Scalars['Float']['input'];
  offering: Scalars['String']['input'];
  response: InputMaybe<Scalars['String']['input']>;
};


export type MutationPublishEventLocalizedArgs = {
  eventId: Scalars['Float']['input'];
  locale: Scalars['String']['input'];
  offering: Scalars['String']['input'];
  response: InputMaybe<Scalars['String']['input']>;
};


export type MutationPublishEventStatisticsArgs = {
  eventId: Scalars['Float']['input'];
  response: InputMaybe<Scalars['String']['input']>;
};


export type MutationPublishLocalizedArgs = {
  locale: Scalars['String']['input'];
  offering: Scalars['String']['input'];
  response: InputMaybe<Scalars['String']['input']>;
};

/**   The type of the occurence action. */
export enum OccurenceAction {
  Added = 'ADDED',
  Deleted = 'DELETED',
  Modified = 'MODIFIED'
}

/**   The type of an occurence. */
export enum OccurenceType {
  BallPunted = 'BALL_PUNTED',
  Cards = 'CARDS',
  DriveEnd = 'DRIVE_END',
  DriveEndHalfTime = 'DRIVE_END_HALF_TIME',
  DriveResult = 'DRIVE_RESULT',
  DriveStart = 'DRIVE_START',
  FieldGoal = 'FIELD_GOAL',
  FieldGoals = 'FIELD_GOALS',
  FieldGoalAttempts = 'FIELD_GOAL_ATTEMPTS',
  Goals = 'GOALS',
  Interceptions = 'INTERCEPTIONS',
  PassingYards = 'PASSING_YARDS',
  Penalties = 'PENALTIES',
  Points = 'POINTS',
  ReceivingYards = 'RECEIVING_YARDS',
  Rouges = 'ROUGES',
  RushingYards = 'RUSHING_YARDS',
  Safeties = 'SAFETIES',
  Safety = 'SAFETY',
  ScoringPlay = 'SCORING_PLAY',
  ScoringPlays = 'SCORING_PLAYS',
  Timeout = 'TIMEOUT',
  Touchdowns = 'TOUCHDOWNS',
  Turnovers = 'TURNOVERS',
  TwoPointSafeties = 'TWO_POINT_SAFETIES',
  Untyped = 'UNTYPED'
}

/**   The odds for the combination of the selected outcomes. */
export type Odds = {
  __typename?: 'Odds';
  /**   Odds in American format. */
  american: Maybe<Scalars['String']['output']>;
  /**   Odds. See general documentation for format. */
  decimal: Maybe<Scalars['Float']['output']>;
  /**   Odds in Fractional format. */
  fractional: Maybe<Scalars['String']['output']>;
};

/**   Statistics for the favorite outcome, the outcome with lowest odds within this betoffer */
export type OddsStats = {
  __typename?: 'OddsStats';
  /**   The outcome ID of the specific outcome */
  outcomeId: Maybe<Scalars['Float']['output']>;
  /**   Starting odds for the specific outcome. Format is described in Data formats. */
  startingOdds: Maybe<Scalars['Float']['output']>;
  /**   Starting odds for the specific outcome, in American format. */
  startingOddsAmerican: Maybe<Scalars['String']['output']>;
  /**   Starting odds for the specific outcome, in Fractional format. */
  startingOddsFractional: Maybe<Scalars['String']['output']>;
  /**   If value is true, the odds for the favorite has increased with at least 50% compared to starting odds */
  unexpectedOddsTrend: Maybe<Scalars['Boolean']['output']>;
};

/**   The response returned by the on demand pricing query. */
export type OnDemandPricingResponse = {
  __typename?: 'OnDemandPricingResponse';
  prices: Maybe<Array<Maybe<Price>>>;
};

/**   Represents an outcome belonging to a bet offer */
export type Outcome = {
  __typename?: 'Outcome';
  /**   Away score, applicable for score betoffers. Value is most commonly a numeric value, but could as contain 'W' as in win. (use for tennis) */
  awayScore: Maybe<Scalars['String']['output']>;
  /**   Unique ID of the bet offer that this outcome belongs to. */
  betOfferId: Maybe<Scalars['Float']['output']>;
  cashOutStatus: Maybe<CashOutStatus>;
  /**   Last change date and time of this outcomes' odds. See Data Formats for the formatting. */
  changedDate: Maybe<Scalars['String']['output']>;
  criterion: Maybe<OutcomeCriterion>;
  /**   Distance for the runner. Applies to Outright bet offer only. */
  distance: Maybe<Scalars['String']['output']>;
  /**   English language label for the outcome. */
  englishLabel: Maybe<Scalars['String']['output']>;
  /**   Participant ID, applicable for the different types of player occurrence bet offers. Represents the ID of the event participant this player is a member of */
  eventParticipantId: Maybe<Scalars['Float']['output']>;
  /**   Home score, applicable for score betoffers. Value is most commonly a numeric value, but could as contain 'W' as in win. (use for tennis) */
  homeScore: Maybe<Scalars['String']['output']>;
  /**   Boolean, applies to outcomes within Scorer bet offers, and for outcomes with type OT_ANY_PARTICIPANT within PlayerLastOccurrence/PlayerOccurrenceNumber bet offers */
  homeTeamMember: Maybe<Scalars['Boolean']['output']>;
  /**   Unique ID of the outcome. */
  id: Maybe<Scalars['Float']['output']>;
  /**
   *   home score - away score
   *  away score - home score (look for AMERICAN_DISPLAY_FORMAT in betoffer tags)
   */
  label: Maybe<Scalars['String']['output']>;
  /**   Line for Handicap and Over/Under bet offer. */
  line: Maybe<Scalars['Float']['output']>;
  /**   Lower limit, applicable for winning margin bet offers. Value is the lower limit depending on the outcome type. Will be omitted if not applicable for type */
  lowerLimit: Maybe<Scalars['Float']['output']>;
  occurrence: Maybe<OutcomeOccurence>;
  /**   Odds for the outcome. See general documentation for format. */
  odds: Maybe<Scalars['Float']['output']>;
  /**   Odds for the outcome, in American format. */
  oddsAmerican: Maybe<Scalars['String']['output']>;
  /**   Odds for the outcome, in Fractional format. */
  oddsFractional: Maybe<Scalars['String']['output']>;
  /**   Participant name. Applies to Head-to-head and Yes/No bet offers only. */
  participant: Maybe<Scalars['String']['output']>;
  /**   The unique id of the outcome’s participant */
  participantId: Maybe<Scalars['Float']['output']>;
  /**   If value is true, then the outcome is one of the most popular outcomes. */
  popular: Maybe<Scalars['Boolean']['output']>;
  /**   Odds history for the participant. Applies to Outright bet offer only. Value is a list of the previous values of the odds in reverse chronological order. */
  prevOdds: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  /**   Value true means that the participant will not take part of the race. Applies to Outright bet offer only. */
  scratched: Maybe<Scalars['Boolean']['output']>;
  /**   Starting number of the participant. Applies to Outright bet offer only. The value may be empty even for Outright bet offers. */
  startNr: Maybe<Scalars['Float']['output']>;
  status: Maybe<OutcomeStatus>;
  tags: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /**   Outcome type identifier. See general documentation for possible values. */
  type: Maybe<Scalars['String']['output']>;
  /**   Upper limit, applicable for winning margin bet offers. Value is the upper limit depending on the outcome type. Will be omitted if not applicable for type */
  upperLimit: Maybe<Scalars['Float']['output']>;
};

/**   The criteria of a goal scorer outcome */
export type OutcomeCriterion = {
  __typename?: 'OutcomeCriterion';
  /**   The name of the criterion */
  name: Maybe<Scalars['String']['output']>;
  /**   The type of the criterion: 4,8:First Goal 5,9:Last Goal 6,10:To Score 7,11:Not to score */
  type: Maybe<Scalars['Float']['output']>;
};

/**   Represents occurrence information for an outcome within a OCCURRENCE_METHOD bet offer */
export type OutcomeOccurence = {
  __typename?: 'OutcomeOccurence';
  /**   Occurrence type outcome, what kind of occurrence this outcome represents. The possible values might be extended. */
  occurrenceType: Maybe<OccurenceType>;
  /**   Occurrence type outcome, localized text of the occurrenceType. */
  occurrenceTypeLabel: Maybe<Scalars['String']['output']>;
};

/**   The current status of an Outcome */
export enum OutcomeStatus {
  Closed = 'CLOSED',
  Open = 'OPEN',
  Settled = 'SETTLED',
  Suspended = 'SUSPENDED'
}

/**   The response returned by the outcomes query. */
export type OutcomesResponse = {
  __typename?: 'OutcomesResponse';
  outcomes: Maybe<Array<Maybe<Outcome>>>;
};

/**   Term-key parents for the event groups of this event */
export type Path = {
  __typename?: 'Path';
  /**   English name of this event group */
  englishName: Maybe<Scalars['String']['output']>;
  /**   Event group ID */
  id: Maybe<Scalars['Float']['output']>;
  /**   Translated name of this event group */
  name: Maybe<Scalars['String']['output']>;
  /**   Term key of this event group */
  termKey: Maybe<Scalars['String']['output']>;
};

/**   Optional if this occurrence is a substitution, then this indicates which player left the field. */
export type PlayerOut = {
  __typename?: 'PlayerOut';
  /**   Unique identifier for the player (not participant id). */
  id: Maybe<Scalars['Int']['output']>;
  /**   Localized name of the player. */
  name: Maybe<Scalars['String']['output']>;
};

/**   The position where an occurence took place. */
export type Position = {
  __typename?: 'Position';
  /**   X position on the field of the occurrence */
  x: Maybe<Scalars['Int']['output']>;
  /**   Y position on the field of the occurrences */
  y: Maybe<Scalars['Int']['output']>;
  /**
   *   SAFE_HOME (Home team controls the ball far from opponents goal)
   *  SAFE_AWAY (Away team controls the ball far from opponents goal)
   *  ATTACK_HOME (Home team controls the ball on opponents half)
   *  ATTACK_AWAY (Away team controls the ball on opponents half)
   *  DANGEROUS_ATTACK_HOME (Home team controls the ball close to the penalty area)
   *  DANGEROUS_ATTACK_AWAY (Away team controls the ball close to the penalty area)
   */
  zone: Maybe<Scalars['String']['output']>;
};

/**   Represents a selection belonging to a pre pack. */
export type PrePack = {
  __typename?: 'PrePack';
  /**   Unique identifier of the pre pack. */
  id: Maybe<Scalars['Float']['output']>;
  /**   Localized label for the pre pack selection. */
  label: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /**   The odds for the combination of the selected outcomes. */
  odds: Maybe<Odds>;
  /**   List of pre pack outcome ids. */
  outcomeIds: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  /**   Unique ID of the pre pack. */
  selectionId: Maybe<Scalars['Float']['output']>;
  /**   The current status of a pre pack selection. */
  status: Maybe<Scalars['String']['output']>;
};

export type Price = {
  __typename?: 'Price';
  /**   BetOffers that have at least one Outcome that are or can be a part on the on-demand-pricing bet. */
  betOffers: Maybe<Array<Maybe<BetOffer>>>;
  /**   Outcome identifiers that can be added to the existing selection of outcomes. If no outcomes are selected then this is the list of combinable outcomes. */
  combinableOutcomeIds: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  /**   Unique identifier of the event. */
  eventId: Maybe<Scalars['String']['output']>;
  /**   The odds for the combination of the selected outcomes. */
  selectedOdds: Maybe<Odds>;
  /**   Outcome identifiers that have been selected and is part of the pricing. Will be empty when there is no selection. */
  selectedOutcomeIds: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
};

export type PublishEvent = {
  __typename?: 'PublishEvent';
  event: Event;
  eventId: Scalars['Float']['output'];
  type: Scalars['String']['output'];
  typeId: Maybe<Scalars['Float']['output']>;
};

export type Query = {
  __typename?: 'Query';
  /**   Coupons query returns events and groups that belong to a coupon defined in the Kambi application Coupon Tool. */
  coupons: Maybe<CouponResponse>;
  /**   Returns a list of one or more events, including, optionally, bet offers, live data and prepacks. */
  event: Maybe<EventResponse>;
  eventList: Maybe<EventListResponse>;
  /**   Groups query will return a list of groups and also top leagues (based on what is set in Presentation Manager). */
  groups: Maybe<GroupsResponse>;
  meta: Maybe<MetaResponse>;
  onDemandPricing: Maybe<OnDemandPricingResponse>;
  outcomes: Maybe<OutcomesResponse>;
};


export type QueryCouponsArgs = {
  categoryGroup: InputMaybe<Scalars['String']['input']>;
  channelId: InputMaybe<Scalars['Int']['input']>;
  couponId: Scalars['String']['input'];
  endTime: Scalars['String']['input'];
  lang: InputMaybe<Scalars['String']['input']>;
  mainBetOffer: InputMaybe<Scalars['Boolean']['input']>;
  market: Scalars['String']['input'];
  maxNumberOfEvents: InputMaybe<Scalars['Int']['input']>;
  offering: Scalars['String']['input'];
  startTime: Scalars['String']['input'];
};


export type QueryEventArgs = {
  channelId: InputMaybe<Scalars['Int']['input']>;
  eventIds: InputMaybe<Scalars['String']['input']>;
  groupId: InputMaybe<Scalars['Int']['input']>;
  includeParticipants: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Scalars['String']['input']>;
  market: Scalars['String']['input'];
  ncid: InputMaybe<Scalars['Int']['input']>;
  offering: Scalars['String']['input'];
  onlyMain: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryEventListArgs = {
  betOfferCategoryFilter: InputMaybe<BetOfferCategoryFilter>;
  channelId: InputMaybe<Scalars['Int']['input']>;
  lang: InputMaybe<Scalars['String']['input']>;
  league: InputMaybe<Scalars['String']['input']>;
  market: Scalars['String']['input'];
  offering: Scalars['String']['input'];
  region: Scalars['String']['input'];
  sport: Scalars['String']['input'];
  time: InputMaybe<TimeParams>;
};


export type QueryGroupsArgs = {
  addAllSubGroupsToTopLeagues: InputMaybe<Scalars['Boolean']['input']>;
  channelId: InputMaybe<Scalars['Int']['input']>;
  groupInternationalGroups: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Scalars['String']['input']>;
  market: Scalars['String']['input'];
  offering: Scalars['String']['input'];
  sport: InputMaybe<Scalars['String']['input']>;
  termKey: InputMaybe<Scalars['String']['input']>;
  time: InputMaybe<TimeParams>;
};


export type QueryOnDemandPricingArgs = {
  eventIds: Scalars['String']['input'];
  language: InputMaybe<Scalars['String']['input']>;
  market: Scalars['String']['input'];
  offering: Scalars['String']['input'];
  outcomeIds: InputMaybe<Scalars['String']['input']>;
};


export type QueryOutcomesArgs = {
  language: InputMaybe<Scalars['String']['input']>;
  market: Scalars['String']['input'];
  offering: Scalars['String']['input'];
  outcomeIds: Scalars['String']['input'];
};

/**   Current event score */
export type Score = {
  __typename?: 'Score';
  /**   Away team or player score, formatted as a string. */
  away: Maybe<Scalars['String']['output']>;
  /**   Home team or player score, formatted as a string. */
  home: Maybe<Scalars['String']['output']>;
  /**   Free-form text describing the score. */
  info: Maybe<Scalars['String']['output']>;
  /**   Who scored/won the point. Values 'HOME', 'AWAY' or 'UNKNOWN' for initial */
  who: Maybe<Scalars['String']['output']>;
};

/**   Statistics for a set based sport. */
export type SetStatistics = {
  __typename?: 'SetStatistics';
  /**   Array of away team scores in the playing order. Non-played sets have value -1. */
  away: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /**   Array of away team scores in the playing order. Non-played sets have value -1. */
  home: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /**   If value is true, then the home team is serving. Only used for tennis and volleyball events. The homeServe may not be set. */
  homeServe: Maybe<Scalars['Boolean']['output']>;
};

/**   Event statistics. The structure is different, depending if the event is set based or match based. Tennis is set based and football is match based. Optional, not set if statistics is disabled for the event. */
export type Statistics = {
  __typename?: 'Statistics';
  football: Maybe<FootballStatistics>;
  sets: Maybe<SetStatistics>;
};

export type Subscription = {
  __typename?: 'Subscription';
  subscribe: Maybe<SubscriptionEvent>;
  subscribeEventBetOffers: Maybe<SubscriptionEvent>;
  subscribeEventLocalized: Maybe<SubscriptionEvent>;
  subscribeEventStatistics: Maybe<SubscriptionEvent>;
  subscribeLocalized: Maybe<SubscriptionEvent>;
};


export type SubscriptionSubscribeArgs = {
  offering: Scalars['String']['input'];
};


export type SubscriptionSubscribeEventBetOffersArgs = {
  eventId: Scalars['Float']['input'];
  offering: Scalars['String']['input'];
};


export type SubscriptionSubscribeEventLocalizedArgs = {
  eventId: Scalars['Float']['input'];
  locale: Scalars['String']['input'];
  offering: Scalars['String']['input'];
};


export type SubscriptionSubscribeEventStatisticsArgs = {
  eventId: Scalars['Float']['input'];
};


export type SubscriptionSubscribeLocalizedArgs = {
  locale: Scalars['String']['input'];
  offering: Scalars['String']['input'];
};

export type SubscriptionEvent = {
  __typename?: 'SubscriptionEvent';
  eventId: Maybe<Scalars['Float']['output']>;
  locale: Maybe<Scalars['String']['output']>;
  offering: Scalars['String']['output'];
  response: PublishEvent;
};

/**   Colors of one teams shirt, colors are represented in HEX, i.e. (#668863) */
export type TeamColor = {
  __typename?: 'TeamColor';
  /**   'Half' of the team's shirt color */
  shirtColor1: Maybe<Scalars['String']['output']>;
  /**   'Other half' of the team's shirt color */
  shirtColor2: Maybe<Scalars['String']['output']>;
};

/**   Colors of the teams shirts */
export type TeamColors = {
  __typename?: 'TeamColors';
  away: Maybe<TeamColor>;
  home: Maybe<TeamColor>;
};

/**   Represents a live occurrence within an event, e.g. TT_SET when set is over. This entity may also be pushed (mt=19) Example of TT_MSG are 'Match delayed due to rain' */
export type Ticker = {
  __typename?: 'Ticker';
  /**   The event this ticker message applies to. */
  eventId: Maybe<Scalars['Int']['output']>;
  /**   Ticker order identifier. Ascending. If message with an already received ticker identifier, should replace old ticker message. */
  id: Maybe<Scalars['Int']['output']>;
  /**   Localized message of the occurrence */
  message: Maybe<Scalars['String']['output']>;
  /**   Minute of the occurrence, number of minutes since the start of the event */
  minute: Maybe<Scalars['Int']['output']>;
  /**
   *   TT_MSG Custom message
   *  TT_GAME Game was won
   *  TT_SET Set was won
   *  TT_MATCH Match was won
   *  TT_SUBST Substitution was made
   *  TT_MATCH_STARTED The match started
   *  TT_FH_END First half finished
   *  TT_SH_START First half finished
   *  TT_SH_END First half finished
   */
  type: Maybe<Scalars['String']['output']>;
};

/**   Time params object can optionally, be passed to the groups query to filter the events that are either live or will be starting soon. */
export type TimeParams = {
  /**   To fetch the group of events that are live */
  live: InputMaybe<Scalars['Boolean']['input']>;
  /**   To fetch the group of event that will be starting soon */
  soon: InputMaybe<Scalars['String']['input']>;
};

/**   Extra information on timeform for racing events. Only available for offerings which have timeform enabled. */
export type Timeform = {
  __typename?: 'Timeform';
  /**   The Timeform Analyst prerace predictions. */
  analystVerdict: Maybe<Scalars['String']['output']>;
  /**   The comment for flat racing which is based on analysis of previous races at this course over the same distance. */
  drawComment: Maybe<Scalars['String']['output']>;
};
