import {omitBy} from "lodash";
import {
    type Event,
    type Serving,
    type KambiEventInfoScoreGoalBased,
    type KambiEventInfoScoreSetBased,
    type ScoreSetStatus,
    type GameSetItem,
    type KambiEventInfoBasic,
    EVENT_SCORE_TYPE_MATCH,
    EVENT_SCORE_TYPE_SET,
    EVENT_SCORE_TYPE_GAME,
    SCORE_SET_STATUS_PENDING,
    SCORE_SET_STATUS_STARTED,
    SCORE_SET_STATUS_DONE,
    EVENT_SERVING_HOME,
    EVENT_SERVING_AWAY,
} from "@atg-sport-shared/sportsbook-types-event";
import {
    type EventActions,
    type SetBig9Tag,
    SET_BIG9_TAG,
    SET_EVENTS,
    SET_LIVE_EVENTS,
    SET_EVENT_INFO,
    SET_SCORE_GOAL_BASED,
    SET_SCORE_SET_BASED,
    SET_SCORE_GAME_BASED,
} from "./event.actions";

type State = {
    [key: string]: Event;
};

const addGoalBasedScore = (state: State, data: KambiEventInfoScoreGoalBased): State => {
    const {id, sport: sportName} = data;
    const {home, away, info} = data.score;

    if (!(sportName && home && away)) {
        return state;
    }

    const score = {type: EVENT_SCORE_TYPE_MATCH, home, away, info};

    return {
        ...state,
        [id]: {...state[`${id}`], sportName, score},
    };
};

const getServing = ({score}: KambiEventInfoScoreSetBased): Serving | undefined => {
    if (score.home.serving) return EVENT_SERVING_HOME;
    if (score.away.serving) return EVENT_SERVING_AWAY;
    return undefined;
};

const addSetBasedScore = (state: State, data: KambiEventInfoScoreSetBased): State => {
    const {id, sport: sportName} = data;
    const {home, away, sets, remainingSets, info} = data.score;

    if (!(sportName && home && away && sets && remainingSets)) {
        return state;
    }

    const setBasedSets = [];

    sets.forEach((set) => {
        setBasedSets.push({
            status: SCORE_SET_STATUS_DONE,
            home: set.home.value,
            away: set.away.value,
        });
    });

    setBasedSets.push({
        status: SCORE_SET_STATUS_STARTED,
        home: home.points,
        away: away.points,
    });

    remainingSets.pop();

    remainingSets.forEach(() => {
        setBasedSets.push({
            status: SCORE_SET_STATUS_PENDING,
            home: "0",
            away: "0",
        });
    });

    const score = {
        type: EVENT_SCORE_TYPE_SET,
        sets: setBasedSets,
        info,
    };

    return {
        ...state,
        [id]: {...state[`${id}`], sportName, score, serving: getServing(data)},
    };
};

const addGameBasedScore = (state: State, data: KambiEventInfoScoreSetBased): State => {
    const {id, sport: sportName} = data;
    const {home, away, sets, remainingSets, info} = data.score;

    if (!(sportName && home && away && sets && remainingSets)) {
        return state;
    }

    const gameBasedSets: {
        status: ScoreSetStatus;
        home: GameSetItem;
        away: GameSetItem;
    }[] = [];

    sets.forEach((set) => {
        const isDone = typeof set.home.won === "boolean";

        gameBasedSets.push({
            status: isDone ? SCORE_SET_STATUS_DONE : SCORE_SET_STATUS_STARTED,
            home: {game: set.home.value, point: isDone ? "" : home.points},
            away: {game: set.away.value, point: isDone ? "" : away.points},
        });
    });

    remainingSets.forEach(() => {
        gameBasedSets.push({
            status: SCORE_SET_STATUS_PENDING,
            home: {game: "0", point: ""},
            away: {game: "0", point: ""},
        });
    });

    const score = {
        type: EVENT_SCORE_TYPE_GAME,
        sets: gameBasedSets,
        info,
    };

    return {
        ...state,
        [id]: {...state[`${id}`], sportName, score, serving: getServing(data)},
    };
};

const setEventInfo = (state: State, payload: KambiEventInfoBasic) => {
    const {id, name, startTimestamp, path} = payload;
    const pathIds = path.map((p) => p.id);

    let params: Event = {
        id,
        name,
        startTimestamp,
        pathIds,
    };

    const [homeName, awayName] = name.split(" - ");

    if (homeName && awayName) {
        params = {...params, homeName, awayName};
    }

    return {
        ...state,
        [id]: {
            ...state[`${id}`],
            ...params,
        },
    };
};

const setEvents = (state: State, payload: Event[]) =>
    payload.reduce(
        (acc, event) => ({
            ...acc,
            [event.id]: {
                ...acc[`${event.id}`],
                ...event,
            },
        }),
        state,
    );

const setLiveEvents = (state: State, payload: {events: Event[]; now: number}) => {
    const liveEventIds = payload.events.map((e) => e.id);

    const updatedState = omitBy(state, (event) => {
        const {id, startTimestamp} = event;
        const isLive = startTimestamp && startTimestamp <= payload.now;

        return isLive && !liveEventIds.includes(id);
    });

    return setEvents(updatedState, payload.events);
};

const addBig9Tag = (state: State, payload: SetBig9Tag["payload"]) =>
    payload.reduce((acc, curr) => {
        if (state[curr]) {
            return {
                ...acc,
                [state[curr].id]: {
                    ...state[curr],
                    tags: [...(state[curr].tags ?? []), "BIG_9"],
                },
            };
        }
        return {...acc, ...state[curr]};
    }, state);

export const eventReducer = (state: State = {}, action: EventActions): State => {
    switch (action.type) {
        case SET_EVENT_INFO:
            return setEventInfo(state, action.payload);

        case SET_SCORE_GOAL_BASED:
            return addGoalBasedScore(state, action.payload);

        case SET_SCORE_SET_BASED:
            return addSetBasedScore(state, action.payload);

        case SET_SCORE_GAME_BASED:
            return addGameBasedScore(state, action.payload);

        case SET_EVENTS:
            return setEvents(state, action.payload);

        case SET_LIVE_EVENTS:
            return setLiveEvents(state, action.payload);

        case SET_BIG9_TAG:
            return addBig9Tag(state, action.payload);

        default:
            return state;
    }
};
